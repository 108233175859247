import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TabList, TabPanel, TabContext } from '@mui/lab';
import { Box, Tab, Stack, Button, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import { BoxFormStyle } from './style';
import FormPreview from '../../FormPreview';
import FormBody from '../../formComposer/FormBody';
import { FormProvider } from '../../../../hooks/useForm';

export default function FormTabs({
  value,
  handleChange,
  formId,
  handleClose,
  handlePopoverOpen,
  initialData,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  return (
    <FormProvider initialData={initialData}>
      <TabContext value={value}>
        <Box sx={BoxFormStyle}>
          {/* Tab List */}
          <TabList onChange={handleChange} aria-label="tablist">
            <Tab
              sx={{ fontSize: isSmallScreen ? '10px' : {} }}
              label={t('pages.formPage.formComposer')}
              value="0"
            />
            <Tab
              sx={{ fontSize: isSmallScreen ? '10px' : {} }}
              label={t('pages.formPage.previewForm')}
              value="1"
            />
          </TabList>

          {/* Button to open popover */}
          <Button
            onClick={handlePopoverOpen}
            sx={{ mr: isSmallScreen ? '10px' : '20px', color: 'primary' }}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontSize: isSmallScreen ? '10px' : {} }}
            >
              {t('buttons.send')}
            </Typography>
            <Iconify
              icon="mdi:send"
              width={isSmallScreen ? 13 : 17}
              height={17}
              ml={isSmallScreen ? 0.5 : 1}
            />
          </Button>
        </Box>

        {/* Tab Panels */}
        <TabPanel value="0">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={isSmallScreen ? 2 : 5}
          >
            <Typography variant="h4" sx={{ fontSize: isSmallScreen ? '0.675rem' : {} }}>
              {t('buttons.updateForm')}
            </Typography>
          </Stack>
          <FormBody updateMode formId={formId} handleClose={handleClose} />
        </TabPanel>

        <TabPanel value="1">
          <FormPreview disabled />
        </TabPanel>
      </TabContext>
    </FormProvider>
  );
}

FormTabs.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  formId: PropTypes.number,
  handleClose: PropTypes.func,
  handlePopoverOpen: PropTypes.func,
  initialData: PropTypes.object,
};
