import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';

import { Box, Card, Stack, Button, Avatar, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import TicketTimeLine from './ticketTimeLine';
import Iconify from '../../../components/iconify';
import AssignTicketModal from '../modals/AssignTicketModal';
import {
  appStyle,
  cardStyle,
  thirdGridStyle,
  thirdTypographyStyle,
  forthTypographyStyle,
  secondTypographyStyle,
  appOrderTimelineStyle,
} from '../styles';

export default function Info({
  currentStatus,
  ticketData,
  assignTicket,
  ticketId,
  statusesList,
  departmentUsers,
  agentData,
  customerData,
  fetchUser,
  commentsData,
  departmentData,
  hasWritePermission,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showModal, hideModal } = useModal();
  const { id: currentUserId } = useAuth();
  const [dataInCSV, setDataInCSV] = useState('');
  const [downloadCSVDebouncer, setDownloadCSVDebouncer] = useState(false);

  useMemo(() => {
    const csvData = [];
    const questionsheaders = [];
    const questionAnswers = [];
    (ticketData?.data || [])
      .flatMap((section) => section.questions || [])
      .forEach(({ text, inputValue }) => {
        questionsheaders.push(text);
        questionAnswers.push(inputValue);
      });
    csvData.push(questionsheaders);
    csvData.push(questionAnswers);
    setDataInCSV(Papa.unparse(csvData));
  }, [ticketData]);

  const handleDownloadClick = () => {
    if (!downloadCSVDebouncer) {
      setDownloadCSVDebouncer(true);
      setTimeout(() => {
        setDownloadCSVDebouncer(false);
      }, 1500);
    }
  };

  // Define the function outside of the component
  const handleShowModal = () => {
    showModal({
      title: t('pages.assignTicket.assignTicketTo'),
      Component: AssignTicketModal,
      props: {
        hideModal,
        assignTicket,
        usersList: departmentUsers.filter((user) => user.id !== ticketData.createdBy),
        department: ticketData.department,
        statusesList: statusesList.filter(
          (status) => status.systemMappedStatus === 'OPEN'
        ),
      },
    });
  };

  return (
    <>
      <Box sx={thirdGridStyle} justifyContent="center">
        {currentStatus && currentStatus.systemMappedStatus === 'UNASSIGNED' ? (
          <>
            <Typography
              sx={{
                ...secondTypographyStyle,
                fontSize: isSmallScreen ? '0.85rem' : '1rem',
              }}
              variant="h6"
            >
              {t('pages.ticketDetails.agentInfo')}
            </Typography>
            <Card sx={cardStyle}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Iconify
                  icon="gravity-ui:triangle-exclamation"
                  sx={{ color: '#FF4842', width: 25, height: 25 }}
                />
                <Typography
                  sx={{
                    ...thirdTypographyStyle,
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  }}
                >
                  {t('pages.ticketDetails.notAssigned')}
                </Typography>
              </Stack>
              {hasWritePermission && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    size={isSmallScreen ? 'small' : 'medium'}
                    sx={{
                      mt: 3,
                      fontSize: isSmallScreen ? '0.7rem' : '1rem',
                    }}
                    onClick={handleShowModal}
                    startIcon={<Iconify icon="fluent-mdl2:assign" />}
                  >
                    {t('buttons.assignManually')}
                  </Button>
                </Box>
              )}
            </Card>
          </>
        ) : (
          <Stack sapcing={1}>
            <Typography
              sx={{
                ...secondTypographyStyle,
                fontSize: isSmallScreen ? '0.85rem' : '1rem',
              }}
              variant="h6"
            >
              {ticketData && currentUserId === ticketData.assignedTo
                ? t('pages.ticketDetails.userInfo')
                : t('pages.ticketDetails.agentInfo')}
            </Typography>
            <Card sx={cardStyle}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  sx={{
                    width: isSmallScreen ? '30px' : '40px',
                    height: isSmallScreen ? '30px' : '40px',
                  }}
                  src={
                    ticketData && currentUserId === ticketData.assignedTo
                      ? customerData?.avatar
                      : agentData?.avatar
                  }
                  alt=""
                />
                <Typography
                  sx={{
                    ...thirdTypographyStyle,
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  }}
                  variant="body1"
                >
                  {ticketData && currentUserId === ticketData.assignedTo ? (
                    <b>{customerData.username}</b>
                  ) : (
                    <b>{agentData.username}</b>
                  )}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }} spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      ...thirdTypographyStyle,
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                  >
                    <b>{t('attributes.email')} : </b>
                  </Typography>
                  <Typography
                    sx={{
                      ...forthTypographyStyle,
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                  >
                    {ticketData && currentUserId === ticketData.assignedTo
                      ? customerData.email
                      : agentData.email}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      ...thirdTypographyStyle,
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                  >
                    <b>{t('attributes.phoneNumber')} : </b>
                  </Typography>
                  <Typography
                    sx={{
                      ...forthTypographyStyle,
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                  >
                    {ticketData && currentUserId === ticketData.assignedTo
                      ? customerData.phoneNumber
                      : agentData.phoneNumber || t('pages.ticketDetails.noPhone')}
                  </Typography>
                </Stack>
                {ticketData && currentUserId !== ticketData.assignedTo && (
                  <Stack direction="row" spacing={2}>
                    <Typography
                      sx={{
                        ...thirdTypographyStyle,
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                    >
                      <b>{t('attributes.department')}:</b>
                    </Typography>
                    <Typography
                      sx={{
                        ...forthTypographyStyle,
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                    >
                      {departmentData.name}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Card>
          </Stack>
        )}
        <TicketTimeLine
          sx={appOrderTimelineStyle}
          title={t('pages.ticketDetails.interactions')}
          list={commentsData.filter((message) => message.messageType === 'UPDATE')}
          statusesList={statusesList}
          fetchUser={fetchUser}
          ticketData={ticketData}
          departmentUsers={departmentUsers}
        />
      </Box>
      <span style={appStyle}>
        <Button
          href={`data:text/csv;charset=utf-8,${dataInCSV}`}
          download={`ticket-${ticketId}-Answers.csv`}
          disabled={!hasWritePermission || downloadCSVDebouncer}
          onClick={handleDownloadClick}
          variant="contained"
          startIcon={<Iconify icon="material-symbols:download" />}
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{
            fontSize: isSmallScreen ? '0.675rem' : '1rem',
          }}
        >
          {t('buttons.downloadAnswers')}
        </Button>
      </span>
    </>
  );
}

Info.propTypes = {
  currentStatus: PropTypes.object,
  ticketData: PropTypes.object,
  assignTicket: PropTypes.func,
  ticketId: PropTypes.string,
  statusesList: PropTypes.array,
  departmentUsers: PropTypes.array,
  agentData: PropTypes.object,
  customerData: PropTypes.object,
  commentsData: PropTypes.array,
  fetchUser: PropTypes.func,
  departmentData: PropTypes.object,
  hasWritePermission: PropTypes.bool,
};
