export const carouselStyles = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50px',
};

export const containerStyles = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const logoStyles = { mt: '6px', width: '170px', height: 'auto' };

export const topContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  m: 1,
};

export const boxContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  p: 1,
};

export const childrenStyles = { width: '100%', maxWidth: '370px' };
