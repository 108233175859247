import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// @mui
import { Grid, Paper, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Loader from 'src/components/loaders/Loader';

import FormCard from '../formCard/FormCard';

// ----------------------------------------------------------------------

FormList.propTypes = {
  forms: PropTypes.array,
  removeForm: PropTypes.func,
  setSelectedForm: PropTypes.func,
  setOpenDetailedView: PropTypes.func,
  isLoading: PropTypes.bool,
  isNotFound: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
};

export default function FormList({
  forms,
  removeForm,
  setSelectedForm,
  setOpenDetailedView,
  isLoading,
  isNotFound,
  searchIsNotFound,
  filterName,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Grid container spacing={isSmallScreen ? 3 : 6}>
      {isLoading ? (
        <Paper
          sx={{
            width: '100%',
            py: isSmallScreen ? 3 : 5,
            my: isSmallScreen ? 3 : 5,
            textAlign: 'center',
          }}
        >
          <Loader size={isSmallScreen ? 100 : 150} />
        </Paper>
      ) : (
        <>
          {forms?.map((form, index) => (
            <Grid key={index} item xs={6} sm={4} md={3}>
              <FormCard
                form={form}
                removeForm={removeForm}
                setSelectedForm={setSelectedForm}
                setOpenDetailedView={setOpenDetailedView}
              />
            </Grid>
          ))}
          {isNotFound && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Paper
                sx={{
                  width: isSmallScreen ? '80%' : '90%',
                  py: isSmallScreen ? 3 : 5,
                  my: isSmallScreen ? 3 : 6,
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  paragraph
                  sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
                >
                  {t('filters.notFound')}
                </Typography>
                {searchIsNotFound && (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                  >
                    {t('filters.noResultsFound', { filterName })}
                  </Typography>
                )}
              </Paper>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
