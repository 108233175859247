import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Stack, Avatar, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Label from 'src/components/label/Label';

import { styles } from '../styles';

AvatarSection.propTypes = {
  setDialogOpen: PropTypes.func.isRequired,
};

export default function AvatarSection({ setDialogOpen }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { user } = useAuth();

  return (
    <Box sx={styles.infoBox}>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Avatar src={user.avatar} alt={user.username} sx={styles.avatar} />

        <Stack alignItems="flex-start" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Typography
              variant="h6"
              sx={{
                fontSize: isSmallScreen && '0.9rem',
              }}
            >
              {user.username}
            </Typography>

            <Typography>
              {user.status ? (
                <Label
                  color="success"
                  sx={{
                    fontSize: isSmallScreen && '0.6rem',
                  }}
                >
                  {t('userStatus.active')}
                </Label>
              ) : (
                <Label
                  color="error"
                  sx={{
                    fontSize: isSmallScreen && '0.6rem',
                  }}
                >
                  {t('userStatus.inactive')}
                </Label>
              )}
            </Typography>
          </Stack>
          <LoadingButton
            id="update-profile-pic"
            variant="outlined"
            size="small"
            onClick={() => setDialogOpen(true)}
            sx={{ ...styles.styleButton, fontSize: isSmallScreen && '0.6rem' }}
          >
            {t('buttons.updateProfilePic')}
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
}
