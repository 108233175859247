import React from 'react';
import PropTypes from 'prop-types';

import { Box, Stack, Select, MenuItem } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

const languages = [
  { code: 'en', label: 'English', icon: '/assets/icons/ic_flag_en.svg' },
  { code: 'it', label: 'Italiano', icon: '/assets/icons/ic_flag_it.webp' },
  { code: 'ar', label: 'العربية', icon: '/assets/icons/ic_flag_tn.webp' },
];

const LanguageSelector = ({ selectedLanguage, handleLanguageChange }) => {
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ maxHeight: isSmallScreen ? 28 : {} }}
    >
      <Select
        value={selectedLanguage}
        onChange={(e) => handleLanguageChange(e.target.value)}
        displayEmpty
        sx={{
          '.MuiSelect-select': { display: 'flex', alignItems: 'center' },
          '.MuiOutlinedInput-notchedOutline': { border: 'none' },
        }}
      >
        {languages.map((lang) => (
          <MenuItem key={lang.code} value={lang.code}>
            <Box
              component="img"
              src={lang.icon}
              alt={lang.label}
              sx={{ width: isSmallScreen ? 22 : 28, height: isSmallScreen ? 17 : 20 }}
            />
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

LanguageSelector.propTypes = {
  selectedLanguage: PropTypes.string,
  handleLanguageChange: PropTypes.func,
};

export default LanguageSelector;
