import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';

// @mui
import { Box, List, ListItemText } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
          maxHeight: isSmallScreen && 40,
        },
      }}
    >
      <StyledNavItemIcon
        sx={{
          width: isSmallScreen ? 20 : 22,
          height: isSmallScreen ? 20 : 22,
        }}
      >
        {icon && icon}
      </StyledNavItemIcon>

      <ListItemText
        disableTypography
        primary={t(`nav.${title}`)}
        sx={{
          fontSize: isSmallScreen ? '0.725rem' : '0.875rem',
        }}
      />

      {info && info}
    </StyledNavItem>
  );
}
