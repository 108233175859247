import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Stack, Tooltip, Typography, CardHeader, CardContent } from '@mui/material';
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
} from '@mui/lab';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { convertLocalTime } from 'src/utils/convertTime';
import generateUpdateMessage from 'src/utils/generateUpdateMessage';

import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

TicketTimeLine.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  statusesList: PropTypes.array,
  fetchUser: PropTypes.func,
  departmentUsers: PropTypes.array,
  ticketData: PropTypes.object,
};

export default function TicketTimeLine({
  title,
  ticketData,
  subheader,
  statusesList,
  departmentUsers,
  fetchUser,
  list,
  ...other
}) {
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Stack>
      <Stack>
        <CardHeader
          sx={{
            pl: '10px',
            '& .MuiCardHeader-title': {
              fontSize: isSmallScreen ? '0.85rem' : '1.1rem',
            },
            '& .MuiCardHeader-subheader': {
              fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
            },
          }}
          title={title}
          subheader={subheader}
        />
      </Stack>
      <Card {...other}>
        <CardContent
          sx={{
            padding: 0,
            '& .MuiTimelineItem-missingOppositeContent:before': {
              display: 'none',
            },
          }}
        >
          <Timeline>
            {list
              .slice()
              .reverse()
              .map((item, index) => (
                <OrderItem
                  fetchUser={fetchUser}
                  key={item.id}
                  item={item}
                  statusesList={statusesList}
                  departmentUsers={departmentUsers}
                />
              ))}
            <OrderItem
              fetchUser={fetchUser}
              departmentUsers={departmentUsers}
              statusesList={statusesList}
              isLast
              item={{
                sentAt: ticketData?.createdAt || '',
                sender: ticketData.createdBy,
                receiver: ticketData.assignedTo || -1,
                message: JSON.stringify({
                  Creation: { newValue: '', previousValue: '' },
                }),
              }}
            />
          </Timeline>
        </CardContent>
      </Card>
    </Stack>
  );
}

// ----------------------------------------------------------------------

function OrderItem({ item, isLast, statusesList, fetchUser, departmentUsers }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [updates, setUpdates] = useState([]);
  const { sentAt: time, message } = item;
  const [user, setUser] = useState({
    id: '',
    username: '',
    appUserRole: '',
  });

  const iconMap = {
    Status: <Iconify icon="pajamas:status" />,
    Title: <Iconify icon="ic:twotone-title" />,
    Priority: <Iconify icon="material-symbols:priority-high" />,
    Description: <Iconify icon="material-symbols:description-outline" />,
    Creation: <Iconify icon="gridicons:create" />,
    'Assigned To': <Iconify icon="icon-park-outline:change" />,
  };

  useEffect(() => {
    if (item) {
      fetchUser(item?.sender, setUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (message) {
      const updatesList = generateUpdateMessage({
        message,
        statusesList,
        username: user?.username || '',
        t,
        usersList: departmentUsers,
      });
      setUpdates(updatesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList, user, t]);

  let dotIcon;
  const updateTypes = Object.keys(JSON.parse(message));
  const uniqueUpdateTypes = [...new Set(updateTypes)];
  if (uniqueUpdateTypes.length === 1) {
    dotIcon = iconMap[uniqueUpdateTypes[0]];
  } else if (uniqueUpdateTypes.length > 1) {
    dotIcon = <Iconify icon="prime:list" />;
  }
  const tooltipText = uniqueUpdateTypes
    .map((type, index) => {
      const attribute = t(`attributes.${type.toLowerCase()}`);
      return index === 0 ? `${t('update')} ${attribute}` : attribute;
    })
    .join(', ');

  return (
    <TimelineItem sx={{ mb: 1 }}>
      <TimelineOppositeContent
        sx={{ flex: 0.2, textAlign: 'right', paddingRight: '16px' }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          }}
        >
          {format(convertLocalTime(time), 'HH:mm:ss')}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          }}
        >
          {format(convertLocalTime(time), 'dd/MM/yyyy')}
        </Typography>
      </TimelineOppositeContent>

      <TimelineSeparator>
        <Tooltip
          title={
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen ? '0.6rem' : '1rem',
              }}
            >
              {tooltipText}
            </Typography>
          }
        >
          <TimelineDot
            sx={{
              width: 23,
              height: 23,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '&:hover': {
                bgcolor: '#9cc8f7',
              },
            }}
          >
            {dotIcon && (
              <Iconify
                icon={dotIcon.props.icon}
                sx={{
                  width: '15px',
                  height: '15px',
                  color: '#3247ec',
                  position: 'absolute',
                }}
              />
            )}
          </TimelineDot>
        </Tooltip>
        {!isLast && <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent sx={{ flex: 1 }}>
        {updates.map((updateMessage, index) => (
          <Typography
            key={index}
            variant="body2"
            sx={{
              display: 'block',
              marginBottom: 0.5,
              fontSize: isSmallScreen ? '0.8rem' : '1rem',
            }}
          >
            {updateMessage}
          </Typography>
        ))}
      </TimelineContent>
    </TimelineItem>
  );
}

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    sentAt: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    sender: PropTypes.number,
    user: PropTypes.object,
  }).isRequired,
  statusesList: PropTypes.array,
  fetchUser: PropTypes.func,
  departmentUsers: PropTypes.array,
};
