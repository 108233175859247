import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { Box } from '@mui/system';
import {
  Card,
  Grid,
  Table,
  Paper,
  Stack,
  Popper,
  Popover,
  MenuItem,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import TableToolbar from 'src/components/ToolBars/TableToolbar';
import TableHeaders from 'src/components/tableHeaders/TableHeaders';
import ActionButton from 'src/components/action-button/action-button';

import AddShareableForm from 'src/sections/shareableForm/AddShareableForm';
import ShareableFormDetails from 'src/sections/shareableForm/ShareableFormDetails';
import TableBodyShareableForm from 'src/sections/shareableForm/TableBodyShareableForm';

import { LANGS } from './ShareableFormLang';
import { useAuth } from '../../hooks/useAuth';
import Iconify from '../../components/iconify';
import { useToast } from '../../hooks/useToast';
import { useModal, useConfirmModal } from '../../hooks/useModal';
import { TABLE_HEAD_FORMS_SHAREABLE } from './ShareableFormHeaders';
import { titleHeadingStyle, titleHeadingStyleSmole } from '../style';
import { getComparator, applySortFilter } from '../../utils/filterData';
import {
  deleteFormLink,
  activateFormLink,
  fetchAllFormsSharable,
  deleteMultipleFormLinks,
  fetchFormsSharableByGeneratedBy,
} from '../../services/form.link.service';

function ShareableFormsPage() {
  const [formsShareable, setFormsShareable] = useState([]);
  const [selectedFormsShareable, setSelectedFormsShareable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPageFormsShareable, setCurrentPageFormsShareable] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [filterBy, setFilterBy] = useState('id');
  const [searchBy, setSearchBy] = useState('byId');
  const [selectedFilter, setSelectedFilter] = useState('id');
  const [filterPopover, setFilterPopover] = useState(null);
  const [subFilterPopover, setSubFilterPopover] = useState({
    status: null,
    isMultiple: null,
    language: null,
    isAnonymous: null,
  });
  const [openShareableFormDetails, setOpenShareableFormDetails] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false);
  const { showToast, hideToast } = useToast();
  const { showModal, hideModal } = useModal();
  const { id, role } = useAuth();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const emptyRows = useMemo(
    () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formsShareable.length) : 0),
    [page, rowsPerPage, formsShareable.length]
  );
  const isSelected = (id) => selectedFormsShareable.indexOf(id) !== -1;

  const handleFetchFormsSharableByGeneratedBy = async () => {
    const data =
      role?.authority === 'ROLE_ADMIN'
        ? await fetchAllFormsSharable()
        : await fetchFormsSharableByGeneratedBy(id);
    setFormsShareable(data);
  };

  useEffect(() => {
    handleFetchFormsSharableByGeneratedBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenMenu = (event, row) => {
    setPopoverAnchorEl(event.currentTarget);
    setSelectedItem(row);
  };

  const handleCloseMenu = () => {
    setPopoverAnchorEl(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = currentPageFormsShareable.map((form) => form.id);
      setSelectedFormsShareable(newSelected);
    } else {
      setSelectedFormsShareable([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedFormsShareable.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedFormsShareable, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedFormsShareable.slice(1));
    } else if (selectedIndex === selectedFormsShareable.length - 1) {
      newSelected = newSelected.concat(selectedFormsShareable.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedFormsShareable.slice(0, selectedIndex),
        selectedFormsShareable.slice(selectedIndex + 1)
      );
    }

    setSelectedFormsShareable(newSelected);
  };

  const filteredFormsShareable = useMemo(() => {
    const filteredData = applySortFilter(
      formsShareable,
      getComparator(order, orderBy),
      filterName,
      filterBy
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsNotFound(!filteredData.length && !!filterName);

    return filteredData;
  }, [filterName, order, orderBy, filterBy, formsShareable]);

  useEffect(() => {
    // Update current page forms shareable when page, rowsPerPage, or formsShareable changes
    const startIdx = page * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    setCurrentPageFormsShareable(filteredFormsShareable.slice(startIdx, endIdx));
  }, [page, rowsPerPage, filteredFormsShareable]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 whenever rows per page changes
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Adds error handling and toast on success/error
  const genericApiToaster = (
    apiCall,
    args,
    successMessage,
    errorMessage,
    successCallback,
    errorCallback
  ) => {
    const apiArguments = Array.isArray(args) ? args : [args]; // Convert single argument to an array

    apiCall(...apiArguments)
      .then((res) => {
        showToast({ message: successMessage, severity: 'success', props: { hideToast } });
        if (successCallback) {
          successCallback(res);
        }
      })
      .catch((e) => {
        showToast({ message: errorMessage, severity: 'error', props: { hideToast } });
        if (errorCallback) {
          errorCallback(e);
        }
      });
  };

  const RemoveFormShareable = async (id) => {
    genericApiToaster(
      deleteFormLink,
      id,
      t('successMessages.deleteShareableFormSuccess'),
      t('errorMessages.deleteShareableFormError'),
      () => {
        handleFetchFormsSharableByGeneratedBy();
        setSelectedFormsShareable([]);
      },
      () => {}
    );
  };

  const multipleFormShareableDelete = async (selected) => {
    try {
      const res = await deleteMultipleFormLinks(selected);
      showToast({
        message: t('successMessages.deleteMultipleShareableFormSuccess', {
          formLinkDeleted: res.deletedCount,
          totalFormLinks: selected.length,
        }),
        severity: 'success',
        props: { hideToast },
      });
      handleFetchFormsSharableByGeneratedBy();
      setSelectedFormsShareable([]);
    } catch (error) {
      showToast({
        message: t('errorMessages.deleteShareableFormError'),
        severity: 'error',
      });
    }
  };

  const { showConfirmModal: showDeleteConfirmModal } = useConfirmModal(
    t('pages.shareableFormsPage.confirmDelete'),
    `${t('pages.shareableFormsPage.areYouSure')} ${selectedItem?.id}`,
    async () => RemoveFormShareable(selectedItem.id)
  );

  const ActiveInactiveFormShareable = async (id, isActive) => {
    genericApiToaster(
      activateFormLink,
      id,
      !isActive
        ? t('successMessages.activateShareableFormSuccess')
        : t('successMessages.deactivateShareableFormSuccess'),
      !isActive
        ? t('errorMessages.activateShareableFormError')
        : t('errorMessages.deactivateShareableFormError'),
      () => {
        handleFetchFormsSharableByGeneratedBy();
        setSelectedFormsShareable([]);
      },
      () => {}
    );
  };

  const { showConfirmModal: showToggleActiveConfirmModal } = useConfirmModal(
    selectedItem?.isActive
      ? t('pages.shareableFormsPage.confirmDeactivate')
      : t('pages.shareableFormsPage.confirmActivate'),
    `${
      selectedItem?.isActive
        ? t('pages.shareableFormsPage.areYouSureToDeactivate')
        : t('pages.shareableFormsPage.areYouSureToActivate')
    } ${selectedItem?.id}`,
    async () => ActiveInactiveFormShareable(selectedItem.id, selectedItem.isActive)
  );

  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setFilterPopover(null);
  };

  const handleCloseSubFilter = () => {
    setSubFilterPopover({
      status: null,
      isMultiple: null,
      language: null,
      isAnonymous: null,
    });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleRemoveSelected = () => {
    setSelectedFormsShareable([]);
  };

  const handleOpenSubFilter = (event, category) => {
    if (category === 'status') {
      setSubFilterPopover({ status: event.currentTarget });
    } else if (category === 'isMultipleSubmission') {
      setSubFilterPopover({ isMultiple: event.currentTarget });
    } else if (category === 'language') {
      setSubFilterPopover({ language: event.currentTarget });
    } else if (category === 'isAnonymous') {
      setSubFilterPopover({ isAnonymous: event.currentTarget });
    }
  };

  const handleOpenShareableFormDetails = (row) => {
    setSelectedItem(row);
    setOpenShareableFormDetails(true);
  };

  useEffect(() => {
    const newSelectdsId = currentPageFormsShareable.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedFormsShareable.includes(id))) {
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormsShareable]);

  return (
    <>
      <Helmet>
        <title> {t('nav.ShareableForms')} </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={isSmallScreen ? 2 : 5}
        >
          <Typography
            style={isSmallScreen ? titleHeadingStyleSmole : titleHeadingStyle}
            mx={1}
          >
            {t('nav.ShareableForms')}
          </Typography>
          <Grid>
            <ActionButton
              id="add-user-btn"
              onClick={() =>
                showModal({
                  title: t('pages.shareableFormsPage.addNewShareableForm'),
                  Component: AddShareableForm,
                  props: {
                    hideModal,
                    handleFetchFormsSharableByGeneratedBy,
                  },
                })
              }
              icon={isSmallScreen ? 'octicon:plus-circle-16' : 'eva:plus-fill'}
              text={t('buttons.addNewShareableForm')}
              title={t('buttons.addNewShareableForm')}
            />
          </Grid>
        </Stack>
        <Card>
          <TableToolbar
            numSelected={selectedFormsShareable.length}
            filterName={filterName}
            filterBy={searchBy}
            handleCloseFilter={handleCloseFilter}
            handleOpenFilter={handleOpenFilter}
            onFilterName={handleFilterByName}
            selected={selectedFormsShareable}
            handleRemoveSelected={handleRemoveSelected}
            deleteFunc={multipleFormShareableDelete}
            model={t(`nav.ShareableForms`)}
            setFilterName={setFilterName}
          />

          <ShareableFormDetails
            open={openShareableFormDetails}
            setOpen={setOpenShareableFormDetails}
            selectedRow={selectedItem}
          />

          <Box
            sx={{
              overflowX: 'auto',
              maxWidth: '100%',
            }}
          >
            <TableContainer component={Paper}>
              <Table size={isSmallScreen ? 'small' : 'medium'}>
                <TableHeaders
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_FORMS_SHAREABLE}
                  rowCount={filteredFormsShareable.length}
                  numSelected={selectedFormsShareable.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  showCheckBox
                  indeterminate={indeterminateCheckbox}
                />

                <TableBodyShareableForm
                  currentPageFormsShareable={currentPageFormsShareable}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  handleOpenMenu={handleOpenMenu}
                  emptyRows={emptyRows}
                  isNotFound={isNotFound}
                  filterName={filterName}
                  onRowClick={handleOpenShareableFormDetails}
                />
              </Table>
            </TableContainer>
          </Box>

          {/* Table Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredFormsShareable.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('filters.rowsPerPage')}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: isSmallScreen && '0.65rem',
                    },
                    '& .MuiList-root': {
                      padding: isSmallScreen && 0,
                    },
                  },
                },
              },
            }}
            sx={{
              '& .MuiTablePagination-toolbar': {
                fontSize: isSmallScreen && '0.65rem',
                minHeight: isSmallScreen ? '32px' : '50px',
              },
              '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                fontSize: isSmallScreen && '0.65rem',
              },
              '& .MuiTablePagination-select': {
                fontSize: isSmallScreen && '0.65rem',
              },
              '& .MuiTablePagination-actions': {
                '& button': {
                  '& .MuiSvgIcon-root': {
                    fontSize: isSmallScreen && '1rem',
                  },
                },
              },
            }}
          />
        </Card>

        {/* menu popover */}
        <Popover
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setOpenShareableFormDetails(true);
              handleCloseMenu();
            }}
          >
            <Iconify
              icon="eva:plus-circle-outline"
              sx={{
                mr: isSmallScreen ? 1.5 : 2,
                width: isSmallScreen ? 16 : 20,
                height: isSmallScreen ? 16 : 20,
              }}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('popover.moreDetails')}
            </Typography>
          </MenuItem>

          <MenuItem
            sx={{ color: selectedItem?.isActive ? 'error.dark' : 'success.main' }}
            onClick={() => {
              showToggleActiveConfirmModal();
              handleCloseMenu();
            }}
          >
            <Iconify
              icon={selectedItem?.isActive ? 'eva:lock-outline' : 'eva:unlock-outline'}
              sx={{
                mr: isSmallScreen ? 1.5 : 2,
                width: isSmallScreen ? 16 : 20,
                height: isSmallScreen ? 16 : 20,
              }}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {selectedItem?.isActive ? t('popover.deactivate') : t('popover.activate')}
            </Typography>
          </MenuItem>

          <MenuItem
            onClick={() => {
              showModal({
                title: t('pages.shareableFormsPage.addNewShareableForm'),
                Component: AddShareableForm,
                props: {
                  hideModal,
                  handleFetchFormsSharableByGeneratedBy,
                  row: selectedItem,
                },
              });
              handleCloseMenu();
            }}
          >
            <Iconify
              icon="eva:edit-fill"
              sx={{
                mr: isSmallScreen ? 1.5 : 2,
                width: isSmallScreen ? 16 : 20,
                height: isSmallScreen ? 16 : 20,
              }}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('popover.edit')}
            </Typography>
          </MenuItem>

          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={() => {
              showDeleteConfirmModal();
              handleCloseMenu();
            }}
          >
            <Iconify
              icon="eva:trash-2-outline"
              sx={{
                mr: isSmallScreen ? 1.5 : 2,
                width: isSmallScreen ? 16 : 20,
                height: isSmallScreen ? 16 : 20,
              }}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('popover.delete')}
            </Typography>
          </MenuItem>
        </Popover>

        {/* main popper */}
        <Popper
          open={Boolean(filterPopover)}
          anchorEl={filterPopover}
          placement="left-start"
          onClose={handleCloseFilter}
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {TABLE_HEAD_FORMS_SHAREABLE.filter(
              ({ name }) =>
                !['token', 'generatedOn', 'expiresOn', 'actions'].includes(name)
            ) // Exclude unwanted filter names
              .map(({ id, name }) =>
                name === 'isActive' ? (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === 'status'}
                    onMouseEnter={(e) => handleOpenSubFilter(e, 'status')}
                    onTouchStart={(e) => handleOpenSubFilter(e, 'status')}
                    onClick={(e) => handleOpenSubFilter(e, 'status')}
                  >
                    <Iconify
                      icon="eva:arrow-ios-back-outline"
                      sx={{
                        mr: 1,
                        width: isSmallScreen ? 16 : 20,
                        height: isSmallScreen ? 16 : 20,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isSmallScreen && '0.7rem',
                      }}
                    >
                      {t('filters.byStatus')}
                    </Typography>
                  </MenuItem>
                ) : name === 'isMultipleSubmission' ? (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === 'isMultipleSubmission'}
                    onMouseEnter={(e) => handleOpenSubFilter(e, 'isMultipleSubmission')}
                    onTouchStart={(e) => handleOpenSubFilter(e, 'isMultipleSubmission')}
                    onClick={(e) => handleOpenSubFilter(e, 'isMultipleSubmission')}
                  >
                    <Iconify
                      icon="eva:arrow-ios-back-outline"
                      sx={{
                        mr: 1,
                        width: isSmallScreen ? 16 : 20,
                        height: isSmallScreen ? 16 : 20,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isSmallScreen && '0.7rem',
                      }}
                    >
                      {t('filters.byMultipleSubmission')}
                    </Typography>
                  </MenuItem>
                ) : name === 'language' ? (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === 'language'}
                    onMouseEnter={(e) => handleOpenSubFilter(e, 'language')}
                    onTouchStart={(e) => handleOpenSubFilter(e, 'language')}
                    onClick={(e) => handleOpenSubFilter(e, 'language')}
                  >
                    <Iconify
                      icon="eva:arrow-ios-back-outline"
                      sx={{
                        mr: 1,
                        width: isSmallScreen ? 16 : 20,
                        height: isSmallScreen ? 16 : 20,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isSmallScreen && '0.7rem',
                      }}
                    >
                      {t('filters.byLanguage')}
                    </Typography>
                  </MenuItem>
                ) : name === 'isAnonymous' ? (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === 'isAnonymous'}
                    onMouseEnter={(e) => handleOpenSubFilter(e, 'isAnonymous')}
                    onTouchStart={(e) => handleOpenSubFilter(e, 'isAnonymous')}
                    onClick={(e) => handleOpenSubFilter(e, 'isAnonymous')}
                  >
                    <Iconify
                      icon="eva:arrow-ios-back-outline"
                      sx={{
                        mr: 1,
                        width: isSmallScreen ? 16 : 20,
                        height: isSmallScreen ? 16 : 20,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isSmallScreen && '0.7rem',
                      }}
                    >
                      {t('filters.byIsAnonymous')}
                    </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={id}
                    selected={selectedFilter === name}
                    onMouseEnter={handleCloseSubFilter}
                    onClick={() => {
                      setFilterName('');
                      setFilterBy(
                        name === 'form'
                          ? 'form.title'
                          : name === 'generatedBy'
                          ? 'generatedBy.username'
                          : name
                      );
                      setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                      setSelectedFilter(name);
                      handleCloseFilter();
                      handleCloseSubFilter();
                      setPage(0);
                    }}
                    onTouchStart={() => {
                      setFilterName('');
                      setFilterBy(
                        name === 'form'
                          ? 'form.title'
                          : name === 'generatedBy'
                          ? 'generatedBy.username'
                          : name
                      );
                      setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                      setSelectedFilter(name);
                      handleCloseFilter();
                      handleCloseSubFilter();
                      setPage(0);
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isSmallScreen && '0.7rem',
                      }}
                    >
                      {name &&
                        t(`filters.by${name.charAt(0).toUpperCase() + name.slice(1)}`)}
                    </Typography>
                  </MenuItem>
                )
              )}
          </Card>
        </Popper>

        {/* sub popper */}
        <Popper
          open={Boolean(subFilterPopover.status)}
          anchorEl={subFilterPopover.status}
          onClose={handleCloseSubFilter}
          placement="left-start"
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {['true', 'false'].map((isActive, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('isActive');
                  setSearchBy('byStatus');
                  setSelectedFilter('isActive');
                  setFilterName(isActive);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterBy('isActive');
                  setSearchBy('byStatus');
                  setSelectedFilter('isActive');
                  setFilterName(isActive);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                <Typography
                  sx={{
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {isActive === 'true'
                    ? t('userStatus.active')
                    : t('userStatus.inactive')}
                </Typography>
              </MenuItem>
            ))}
          </Card>
        </Popper>

        {/* sub popper */}
        <Popper
          open={Boolean(subFilterPopover.isMultiple)}
          anchorEl={subFilterPopover.isMultiple}
          onClose={handleCloseSubFilter}
          placement="left-start"
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {['true', 'false'].map((isMultipleSubmission, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('isMultipleSubmission');
                  setSearchBy('byMultipleSubmission');
                  setSelectedFilter('isMultipleSubmission');
                  setFilterName(isMultipleSubmission);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterBy('isMultipleSubmission');
                  setSearchBy('byMultipleSubmission');
                  setSelectedFilter('isMultipleSubmission');
                  setFilterName(isMultipleSubmission);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                <Typography
                  sx={{
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {isMultipleSubmission === 'true'
                    ? t('isMultipleSubmission.yes')
                    : t('isMultipleSubmission.no')}
                </Typography>
              </MenuItem>
            ))}
          </Card>
        </Popper>

        {/* language sub popover */}
        <Popper
          open={Boolean(subFilterPopover.language)}
          anchorEl={subFilterPopover.language}
          onClose={handleCloseSubFilter}
          placement="left-start"
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {LANGS.map((lang, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('language');
                  setSearchBy('byLanguage');
                  setSelectedFilter('language');
                  setFilterName(lang.value);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterBy('language');
                  setSearchBy('byLanguage');
                  setSelectedFilter('language');
                  setFilterName(lang.value);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                <Stack direction="row" spacing={1}>
                  <img
                    src={lang.icon}
                    alt={lang.value}
                    width={isSmallScreen ? 20 : 28}
                    height={isSmallScreen ? 16 : 20}
                  />
                </Stack>
              </MenuItem>
            ))}
          </Card>
        </Popper>

        {/* sub popper is anonymous */}
        <Popper
          open={Boolean(subFilterPopover.isAnonymous)}
          anchorEl={subFilterPopover.isAnonymous}
          onClose={handleCloseSubFilter}
          placement="left-start"
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            {['true', 'false'].map((isAnonymous, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('isAnonymous');
                  setSearchBy('byIsAnonymous');
                  setSelectedFilter('isAnonymous');
                  setFilterName(isAnonymous);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterBy('isAnonymous');
                  setSearchBy('byIsAnonymous');
                  setSelectedFilter('isAnonymous');
                  setFilterName(isAnonymous);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                <Typography
                  sx={{
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {isAnonymous === 'true'
                    ? t('isMultipleSubmission.yes')
                    : t('isMultipleSubmission.no')}
                </Typography>
              </MenuItem>
            ))}
          </Card>
        </Popper>
      </Container>
    </>
  );
}

export default ShareableFormsPage;
