import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Draggable } from 'react-beautiful-dnd';

import { Box } from '@mui/system';
import {
  Card,
  Switch,
  Button,
  Tooltip,
  IconButton,
  CardActions,
  CardContent,
  FormControlLabel,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Question from './Question';
import Iconify from '../../../../components/iconify';
import { useFormContext } from '../../../../hooks/useForm';
import PopOverMenu from '../../../../components/popovermenu/PopOverMenu';

QuestionCard.propTypes = {
  question: PropTypes.object,
  sectionId: PropTypes.string,
  index: PropTypes.number,
};

export default function QuestionCard({ question, sectionId, index }) {
  const {
    deleteQuestion: removeQuestion,
    addQuestion: handleAddQuestion,
    modifyQuestion,
  } = useFormContext();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const options = [t('pages.formPage.goToSectionBaseOn')];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(
    question.redirectOnAnswer ? 0 : null
  );
  const [isRequired, setIsRequired] = useState(question.isRequired ?? isRequired);
  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleRequired = () => {
    setIsRequired(!isRequired);
    modifyQuestion(question.id, { ...question, isRequired: !isRequired });
  };

  const handleSelect = (value) => {
    setSelectedIndex(value);
    if (value === 0) {
      modifyQuestion(question.id, { ...question, redirectOnAnswer: true });
    }
  };
  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card
            sx={{
              minWidth: !isSmallScreen && 275,
              maxWidth: isSmallScreen ? 250 : {},
              mt: 3,
              mb: 3,
              boxShadow: 14,
            }}
            raised
          >
            <CardContent>
              <Question
                question={question}
                sectionId={sectionId}
                selectedType={options[selectedIndex]}
              />
            </CardContent>
            <CardActions
              sx={{
                flexWrap: isSmallScreen ? 'wrap' : 'nowrap',
                justifyContent: isSmallScreen ? 'space-between' : 'flex-start',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isSmallScreen ? 'column' : 'row',
                  alignItems: 'flex-start',
                  gap: 1,
                  p: 1,
                }}
              >
                <Button
                  size={isSmallScreen ? 'small' : 'medium'}
                  onClick={() => handleAddQuestion(sectionId)}
                  sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                >
                  {t('pages.formPage.addQuestion')}
                </Button>
                <FormControlLabel
                  label={t('pages.formPage.required')}
                  control={
                    <Switch
                      name="toggleRequired"
                      label={t('pages.formPage.required')}
                      checked={isRequired}
                      onClick={handleToggleRequired}
                      size={isSmallScreen ? 'small' : 'medium'}
                      sx={{ ml: 1 }}
                    />
                  }
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: isSmallScreen ? '0.7rem' : '1rem',
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isSmallScreen ? 'column' : 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  ml: 'auto',
                  mt: isSmallScreen ? 2 : 0,
                }}
              >
                <Tooltip title={t('buttons.delete')}>
                  <IconButton
                    onClick={() => removeQuestion(sectionId, question.id)}
                    onTouchStart={() => removeQuestion(sectionId, question.id)}
                  >
                    <Iconify
                      icon="eva:trash-2-fill"
                      sx={{
                        width: isSmallScreen ? 16 : 20,
                        height: isSmallScreen ? 16 : 20,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                {(question.type === 'SelectInput' || question.type === 'RadioInput') && (
                  <>
                    <IconButton
                      size="large"
                      color="inherit"
                      onClick={handleMenuToggle}
                      onTouchStart={handleMenuToggle}
                    >
                      <Iconify
                        icon="eva:more-vertical-fill"
                        sx={{
                          width: isSmallScreen ? 16 : 20,
                          height: isSmallScreen ? 16 : 20,
                        }}
                      />
                    </IconButton>
                    <PopOverMenu
                      options={options}
                      setAnchorEl={setAnchorEl}
                      anchorEl={anchorEl}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={handleSelect}
                    />
                  </>
                )}
              </Box>
            </CardActions>
          </Card>
        </div>
      )}
    </Draggable>
  );
}
