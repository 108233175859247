import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Paper, TableRow, TableBody, TableCell, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

EmptyTable.propTypes = {
  filterName: PropTypes.string,
  searchIsNotFound: PropTypes.bool,
  colSpan: PropTypes.number,
};

export default function EmptyTable({ filterName, searchIsNotFound, colSpan }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={colSpan} sx={{ py: isSmallScreen ? 2 : 3 }}>
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h6"
              paragraph
              sx={{
                color: (theme) => theme.palette.grey[500],
                fontSize: isSmallScreen && '0.8rem',
              }}
            >
              {t('filters.notFound')}
            </Typography>

            {searchIsNotFound && (
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  fontSize: isSmallScreen && '0.65rem',
                }}
              >
                {t('filters.noResultsFound', { filterName })}
              </Typography>
            )}
          </Paper>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
