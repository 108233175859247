import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Stack,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { phoneNumberSplit } from 'src/utils/phoneNumberSplit';
import { EMAIL_REGEX, PHONENUMBER_REGEX } from 'src/utils/regex';

import Iconify from 'src/components/iconify';

import { scrollbarStyle } from 'src/sections/style';

import { styles } from '../styles';
import countries from '../../../locale/phoneCode';

export default function UserInfoForm({ modifyUser }) {
  const { i18n, t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { user } = useAuth();
  const { showToast, hideToast } = useToast();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: user.id,
      givenName: user.givenName,
      familyName: user.familyName,
      username: user.username,
      countryCode: phoneNumberSplit(user?.phoneNumber).partOne,
      phoneNumber: phoneNumberSplit(user?.phoneNumber).partTwo,
      email: user.email,
      avatar: user.avatar,
    },
  });

  useMemo(() => {
    const defaultValues = {
      id: user.id,
      givenName: user.givenName,
      familyName: user.familyName,
      username: user.username,
      countryCode: phoneNumberSplit(user?.phoneNumber).partOne,
      phoneNumber: phoneNumberSplit(user?.phoneNumber).partTwo,
      email: user.email,
      avatar: user.avatar,
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    countries.sort((a, b) => a[i18n.language].localeCompare(b[i18n.language]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelChanges = () => {
    reset();
  };

  const onSubmit = async (data) => {
    data.phoneNumber = `(${data.countryCode}) ${data.phoneNumber}`;

    setLoading(true);
    try {
      await modifyUser(data);
      showToast({
        message: t('successMessages.successUpdate'),
        severity: 'success',
        props: { hideToast },
      });
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntUpdateInfo'),
        severity: 'error',
        props: { hideToast },
      });
    } finally {
      setLoading(false);
      reset();
    }
  };

  return (
    <Box sx={{ ...styles.infoBox, p: isSmallScreen ? 1 : 2 }}>
      <Box>
        <Stack spacing={1}>
          <Stack sx={styles.iconBox}>
            <Iconify
              icon="eva:edit-2-outline"
              sx={{
                mr: isSmallScreen ? 0.5 : 1,
                width: isSmallScreen ? 16 : 20,
                height: isSmallScreen ? 16 : 20,
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontSize: isSmallScreen && '0.8rem',
              }}
            >
              {t('pages.profilePage.editInfoHere')}:
            </Typography>
          </Stack>
          <Card sx={styles.formCard}>
            <form onSubmit={handleSubmit(onSubmit)} key={user.id}>
              <Stack mb={3} direction="row" spacing={3}>
                <TextField
                  fullWidth
                  name="givenName"
                  label={t('attributes.firstName')}
                  defaultValue={user.givenName}
                  error={!!errors.givenName}
                  helperText={errors.givenName?.message || ''}
                  {...register('givenName', {
                    required: t('formControl.enterName'),
                    minLength: {
                      value: 3,
                      message: t('formControl.minName'),
                    },
                  })}
                  size={isSmallScreen ? 'small' : 'medium'}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: isSmallScreen ? '0.775rem' : '0.8rem',
                    },
                  }}
                />
                <TextField
                  fullWidth
                  name="familyName"
                  label={t('attributes.lastName')}
                  autoComplete="family-name"
                  error={!!errors.familyName}
                  helperText={errors.familyName?.message || ''}
                  {...register('familyName', {
                    required: t('formControl.enterLastName'),
                    minLength: {
                      value: 3,
                      message: t('formControl.minLastName'),
                    },
                  })}
                  size={isSmallScreen ? 'small' : 'medium'}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: isSmallScreen ? '0.775rem' : '0.8rem',
                    },
                  }}
                />
              </Stack>
              <Stack spacing={3}>
                <TextField
                  name="username"
                  label={t('attributes.userName')}
                  autoComplete="username"
                  error={!!errors.username}
                  helperText={errors.username?.message || ''}
                  {...register('username', {
                    required: t('formControl.enterName'),
                    minLength: {
                      value: 3,
                      message: t('formControl.minName'),
                    },
                  })}
                  size={isSmallScreen ? 'small' : 'medium'}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: isSmallScreen ? '0.775rem' : '0.8rem',
                    },
                  }}
                />
                <Stack direction="row" spacing={1}>
                  <FormControl sx={{ width: isSmallScreen ? '50%' : '20%' }}>
                    <InputLabel
                      id="country-code-label"
                      sx={{
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                        mt: { xs: -0.5, sm: 0 },
                      }}
                    >
                      {t('attributes.country')}
                    </InputLabel>
                    <Select
                      labelId="country-code-label"
                      label={t('attributes.country')}
                      id="countryCode"
                      name="countryCode"
                      defaultValue={phoneNumberSplit(user?.phoneNumber).partOne}
                      value={watch('countryCode') || '+39'} // controlled Select
                      renderValue={(countryCode) => `(${countryCode})`}
                      {...register('countryCode', {
                        required: t('formControl.selectCountryCode'),
                      })}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            ...scrollbarStyle,
                          },
                        },
                      }}
                      sx={{
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                      size={isSmallScreen ? 'small' : 'medium'}
                    >
                      {countries?.length > 0 ? (
                        countries.map((country, index) => (
                          <MenuItem
                            key={index}
                            value={country.code}
                            sx={{
                              fontSize: isSmallScreen ? '0.7rem' : '1rem',
                            }}
                          >
                            {`${country[i18n.language]}  (${country.code})`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled value="">
                          {t('infoMessages.noOptions')}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    name="phoneNumber"
                    type="number"
                    label={t('attributes.phoneNumber')}
                    autoComplete="tel-local"
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message || ''}
                    {...register('phoneNumber', {
                      required: t('formControl.enterPhoneNumber'),
                      pattern: {
                        value: PHONENUMBER_REGEX,
                        message: t('formControl.invalidPhoneNumber'),
                      },
                    })}
                    size={isSmallScreen ? 'small' : 'medium'}
                    sx={{
                      width: '80%',
                      '& .MuiInputBase-root': {
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      },
                      '& .MuiFormHelperText-root': {
                        fontSize: isSmallScreen ? '0.675rem' : '1rem',
                      },
                    }}
                  />
                </Stack>
                <TextField
                  disabled
                  name="email"
                  label={t('pages.usersPage.form.emailAdress')}
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={errors.email?.message || ''}
                  {...register('email', {
                    required: t('formControl.enterEmail'),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('formControl.enterEmail'),
                    },
                  })}
                  size={isSmallScreen ? 'small' : 'medium'}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: isSmallScreen ? '0.675rem' : '1rem',
                    },
                  }}
                />
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    sx={{
                      backgroundColor: '#d21426',
                      color: '#fff',
                      fontSize: isSmallScreen && '0.6rem',
                    }}
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={handleCancelChanges}
                    disabled={!isDirty}
                    size={isSmallScreen ? 'small' : 'medium'}
                  >
                    {t('buttons.cancelChanges')}
                  </LoadingButton>
                  <LoadingButton
                    sx={{
                      backgroundColor: 'success.dark',
                      color: '#fff',
                      ':hover': {
                        backgroundColor: 'success.darker',
                      },
                      fontSize: isSmallScreen && '0.6rem',
                    }}
                    fullWidth
                    loading={loading}
                    type="submit"
                    id="update-info"
                    variant="contained"
                    disabled={!isValid || !isDirty}
                    size={isSmallScreen ? 'small' : 'medium'}
                  >
                    {t('buttons.update')}
                  </LoadingButton>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Stack>
      </Box>
    </Box>
  );
}

UserInfoForm.propTypes = {
  modifyUser: PropTypes.func.isRequired,
};
