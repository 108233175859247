import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Popover, MenuItem, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

MenuPopover.propTypes = {
  open: PropTypes.object || PropTypes.bool,
  handleCloseMenu: PropTypes.func,
  setOpenModifyDepartment: PropTypes.func,
  handleDeleteClick: PropTypes.func,
};
export default function MenuPopover({
  open,
  handleCloseMenu,
  setOpenModifyDepartment,
  handleDeleteClick,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: isSmallScreen ? 110 : 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          setOpenModifyDepartment(true);
        }}
      >
        <Iconify
          icon="eva:edit-fill"
          sx={{
            mr: isSmallScreen ? 1 : 2,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {t('popover.edit')}
        </Typography>
      </MenuItem>

      <MenuItem
        sx={{ color: 'error.main' }}
        onClick={() => {
          handleDeleteClick();
          handleCloseMenu();
        }}
      >
        <Iconify
          icon="eva:trash-2-outline"
          sx={{
            mr: isSmallScreen ? 1 : 2,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {t('popover.delete')}
        </Typography>
      </MenuItem>
    </Popover>
  );
}
