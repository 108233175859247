import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  List,
  Card,
  Stack,
  Select,
  Avatar,
  Button,
  ListItem,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  ListItemAvatar,
  InputAdornment,
} from '@mui/material';

import { useConfirmModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import { scrollbarStyle } from 'src/sections/style';
import {
  cardTicketModalStyle,
  listTicketModalStyle,
  listItemTicketPermissionModalStyle,
} from 'src/sections/ticketDetails/styles';

AddUsersPermissionsModal.propTypes = {
  usersList: PropTypes.array,
  addPermissions: PropTypes.func,
  ticketId: PropTypes.number,
  ticketUsersIds: PropTypes.array,
};
export default function AddUsersPermissionsModal({
  usersList,
  addPermissions,
  ticketId,
  ticketUsersIds,
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState('read');
  const [filterName, setFilterName] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = useMemo(() => {
    const lowerCaseFilterName = filterName.toLowerCase();
    const availableUsers = usersList.filter((user) => !ticketUsersIds.includes(user.id));
    const filteredData = availableUsers.filter((user) =>
      user.username.toLowerCase().includes(lowerCaseFilterName)
    );
    setIsNotFound(!filteredData.length && !!filterName);
    return filteredData;
  }, [ticketUsersIds, usersList, filterName]);

  const { showConfirmModal } = useConfirmModal(
    t('pages.ticketDetails.confirmPermission'),
    t('pages.ticketDetails.areYouSureToAdd'),
    async () => {
      // Add permissions for all users in parallel
      await Promise.all(
        selectedUsers.map((user) =>
          addPermissions(
            ticketId,
            user.id,
            selectedPermission === 'read',
            selectedPermission === 'write'
          )
        )
      );
    }
  );

  const handlePermissionChange = (event) => {
    setSelectedPermission(event.target.value);
  };

  const handleUserSelect = (user) => {
    setSelectedUsers((prev) => {
      const isSelected = prev.some((item) => item.id === user.id);
      if (isSelected) {
        return prev.filter((item) => item.id !== user.id);
      }

      return [...prev, user];
    });
  };

  return (
    <Stack px={2} spacing={2} my={2}>
      <TextField
        value={filterName}
        onChange={handleFilterByName}
        placeholder={`${t('filters.search')}`}
        size={isSmallScreen ? 'small' : 'medium'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{
                  color: 'text.disabled',
                  width: isSmallScreen ? 16 : 20,
                  height: isSmallScreen ? 16 : 20,
                }}
              />
            </InputAdornment>
          ),
        }}
        sx={{
          '& input': {
            fontSize: isSmallScreen ? '0.775rem' : '1rem',
          },
        }}
      />
      <Card
        sx={{
          ...cardTicketModalStyle,
          height: 'auto',
          minHeight: isSmallScreen ? '50px' : '100px',
          maxHeight: '350px',
        }}
      >
        <List sx={listTicketModalStyle}>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => {
              const { id, username, email, avatar } = user;

              return (
                <ListItem
                  divider
                  key={id}
                  onClick={() => handleUserSelect(user)}
                  sx={listItemTicketPermissionModalStyle(selectedUsers, user)}
                  alignItems="flex-start"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Stack direction="row">
                      <ListItemAvatar>
                        <Avatar
                          alt=""
                          src={avatar}
                          sx={{
                            width: isSmallScreen ? 30 : 40,
                            height: isSmallScreen ? 30 : 40,
                          }}
                        />
                      </ListItemAvatar>

                      <Stack spacing={0.5}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: isSmallScreen ? '0.775rem' : '1rem',
                          }}
                        >
                          <b>{username}</b>
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: isSmallScreen ? '0.675rem' : '1rem',
                          }}
                        >
                          <b>{t('attributes.email')}: </b>
                          {email}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </ListItem>
              );
            })
          ) : (
            <Typography
              textAlign="center"
              variant="subtitle1"
              sx={{
                fontSize: isSmallScreen ? '0.675rem' : '1rem',
              }}
            >
              {isNotFound
                ? t('pages.ticketDetails.noUsersFound')
                : t('pages.ticketDetails.noUserAvailable')}
            </Typography>
          )}
        </List>
      </Card>
      <FormControl>
        <InputLabel
          id="ticket-status-select-label"
          sx={{
            fontSize: isSmallScreen ? '0.675rem' : '1rem',
          }}
        >
          {t('pages.ticketDetails.permission')}
        </InputLabel>
        <Select
          labelId="ticket-status-select-label"
          id="status-select"
          name="status_id"
          label="Permission"
          defaultValue={selectedPermission}
          onChange={handlePermissionChange}
          size={isSmallScreen ? 'small' : 'medium'}
          MenuProps={{
            PaperProps: {
              sx: {
                ...scrollbarStyle,
              },
            },
          }}
        >
          <MenuItem
            value="read"
            sx={{
              fontSize: isSmallScreen ? '0.675rem' : '1rem',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('pages.ticketDetails.read')}
            </Typography>
          </MenuItem>
          <MenuItem
            value="write"
            sx={{
              fontSize: isSmallScreen ? '0.675rem' : '1rem',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('pages.ticketDetails.modify')}
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        onClick={selectedUsers.length !== 0 ? showConfirmModal : null}
        disabled={selectedUsers.length === 0}
        sx={{
          fontSize: isSmallScreen ? '0.675rem' : '1rem',
        }}
      >
        {t('pages.ticketDetails.addUser')}
      </Button>
    </Stack>
  );
}
