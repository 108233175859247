import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, MenuItem } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { scrollbarStyle } from 'src/sections/style';

DepartmentSelect.propTypes = {
  options: PropTypes.array,
  setSelectedDepId: PropTypes.func,
};

export default function DepartmentSelect({ options, setSelectedDepId }) {
  const [department, setDepartment] = useState('');
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  useEffect(() => {
    setDepartment(options[0]?.name || '');
  }, [options]);

  const handleChange = (event) => {
    setDepartment(event.target.value);
    setSelectedDepId(
      options.find((department) => department.name === event.target.value).id
    );
  };

  return (
    <Select
      id="dashboard-department-select"
      value={department}
      onChange={handleChange}
      sx={{
        maxWidth: '25vh',
        minWidth: isSmallScreen ? '22vh' : '20vh',
        fontSize: isSmallScreen ? '0.8rem' : '1rem',
      }}
      size={isSmallScreen ? 'small' : 'medium'}
      MenuProps={{
        PaperProps: {
          sx: {
            ...scrollbarStyle,
          },
        },
      }}
    >
      {options.length > 0 ? (
        options?.map((department) => (
          <MenuItem
            key={`department-${department.id}`}
            value={department.name}
            sx={{
              fontSize: isSmallScreen ? '0.8rem' : '1rem',
            }}
          >
            {department.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem
          disabled
          value=""
          sx={{
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          }}
        >
          {t('infoMessages.noOptions')}
        </MenuItem>
      )}
    </Select>
  );
}
