import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, List, Button, Typography } from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import { useModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import { fetchUsers } from 'src/services/user.service';
import {
  addUserPermissions,
  deleteUserPermission,
  fetchUserPermissions,
  fetchUsersWithPermissions,
} from 'src/services/permissions.service';

import UserListPermission from './UserListPermission';
import AddUsersPermissionsModal from '../modals/AddUsersPermissionsModal';
import { thirdGridStyle, cardTicketModalStyle, listTicketModalStyle } from '../styles';

Permissions.propTypes = {
  ticketId: PropTypes.string,
  usersList: PropTypes.array,
  hasReadPermission: PropTypes.bool,
  hasWritePermission: PropTypes.bool,
  ticketData: PropTypes.object,
};

export default function Permissions({
  ticketId,
  usersList,
  ticketData,
  hasReadPermission,
  hasWritePermission,
}) {
  const { isSmallScreen } = useResponsiveScreen();
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();
  const [ticketUsersIds, setTicketUsersIds] = useState([]);
  const [users, setUsers] = useState(usersList);
  const { showModal, hideModal } = useModal();

  const handleShowModal = () => {
    showModal({
      title: t('pages.ticketDetails.addPermission'),
      Component: AddUsersPermissionsModal,
      props: {
        hideModal,
        ticketId,
        usersList,
        addPermissions,
        ticketUsersIds,
        setTicketUsersIds,
      },
    });
  };

  const deletePermission = async (ticketId, userId) => {
    genericApiToaster(
      await deleteUserPermission,
      { ticketId, userId },
      t('successMessages.successUserPermissiondelete'),
      t('errorMessages.errorDeletingUserPermission'),
      showToast,
      hideToast,
      () => displayUsers()
    );
  };

  const addPermissions = async (
    ticketId,
    userId,
    isReadPermission,
    isWritePermission
  ) => {
    if (isWritePermission) {
      isReadPermission = true;
    }

    genericApiToaster(
      await addUserPermissions,
      {
        ticketId,
        userId,
        isReadPermission,
        isWritePermission,
      },
      t('successMessages.successAddingPermission'),
      t('errorMessages.errorAddingPermission'),
      showToast,
      hideToast,
      () => displayUsers()
    );
  };

  const displayUsers = async () => {
    try {
      const usersIds = await fetchUsersWithPermissions(ticketId);
      // migration
      if (usersIds.length === 0) {
        const updatedUsers = [];
        const allUsers = await fetchUsers();
        await Promise.all(
          allUsers.map(async (user) => {
            if (
              user.appUserRole === 'ROLE_ADMIN' ||
              ticketData.createdBy === user.id ||
              ticketData.assignedTo === user.id ||
              user.departmentId === ticketData.departmentId
            ) {
              updatedUsers.push(user);
            }
          })
        );

        await Promise.all(
          updatedUsers.map(async (user) => {
            await addPermissions(ticketId, user.id, true, true);
          })
        );
      }
      setTicketUsersIds(usersIds);

      const updatedUsers = [...users];
      await Promise.all(
        usersIds.map(async (id) => {
          const userPermission = await fetchUserPermissions(ticketId, id);
          updatedUsers.forEach((user) => {
            if (user.id === id) {
              Object.assign(user, { permissions: userPermission });
            }
          });
        })
      );
      setUsers(updatedUsers);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadUser'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  useEffect(() => {
    displayUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={thirdGridStyle} justifyContent="center">
      <Typography variant="h6">{t('pages.ticketDetails.users')}</Typography>
      <Card
        sx={{
          ...cardTicketModalStyle,
          height: 'auto',
          minHeight: isSmallScreen ? '50px' : '100px',
          maxHeight: '350px',
        }}
      >
        {(hasReadPermission || hasWritePermission) && (
          <List sx={listTicketModalStyle}>
            {users.length > 0 ? (
              users
                .filter(
                  (user) =>
                    Array.isArray(ticketUsersIds) &&
                    ticketUsersIds.includes(user.id) &&
                    user.appUserRole !== 'ROLE_ADMIN' &&
                    ticketData.createdBy !== user.id
                )
                .map((user) => (
                  <UserListPermission
                    hasReadPermission={hasReadPermission}
                    hasWritePermission={hasWritePermission}
                    key={user.id}
                    user={user}
                    deletePermission={deletePermission}
                    ticketId={ticketId}
                    displayUsers={displayUsers}
                  />
                ))
            ) : (
              <Typography
                textAlign="center"
                variant="subtitle1"
                sx={{
                  fontSize: isSmallScreen ? '0.675rem' : '1rem',
                }}
              >
                {t('pages.ticketDetails.noUserFound')}
              </Typography>
            )}
          </List>
        )}
      </Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{
            mt: 3,
            fontSize: isSmallScreen ? '0.7rem' : '1rem',
          }}
          disabled={!hasWritePermission}
          onClick={handleShowModal}
          style={{ width: '100%' }}
        >
          {t('pages.ticketDetails.addUser')}
        </Button>
      </Box>
    </Box>
  );
}
