export const TABLE_HEAD_FORMS_SHAREABLE = [
  { id: 'id', name: 'id', label: 'formShareable.id', alignRight: false },
  { id: 'form.title', name: 'form', label: 'formShareable.form', alignRight: false },
  {
    id: 'language',
    name: 'language',
    label: 'formShareable.language',
    alignRight: false,
  },
  {
    id: 'isAnonymous',
    name: 'isAnonymous',
    label: 'formShareable.isAnonymous',
    alignRight: false,
  },
  { id: 'isActive', name: 'isActive', label: 'formShareable.status', alignRight: false },
  {
    id: 'generatedBy.username',
    name: 'generatedBy',
    label: 'formShareable.generatedBy',
    alignRight: false,
  },
  {
    id: 'isMultipleSubmission',
    name: 'isMultipleSubmission',
    label: 'formShareable.isMultipleSubmission',
    alignRight: false,
  },
  {
    id: 'submissions',
    name: 'submissions',
    label: 'formShareable.submissions',
    alignRight: false,
  },
  { id: 'token', name: 'token', label: 'formShareable.token', alignRight: false },
  { id: 'iframe', name: 'token', label: 'formShareable.iframe', alignRight: false },
  {
    id: 'generatedOn',
    name: 'generatedOn',
    label: 'formShareable.generatedOn',
    alignRight: false,
  },
  {
    id: 'expiresOn',
    name: 'expiresOn',
    label: 'formShareable.expiresOn',
    alignRight: false,
  },
  { id: 'actions', name: 'actions', label: 'formShareable.actions', sticky: true },
];
