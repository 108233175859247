import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Popover, MenuItem, Typography } from '@mui/material';

import { useConfirmModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

OptionsPopover.propTypes = {
  removeTicket: PropTypes.func,
  selectedItem: PropTypes.object,
  popoverAnchorEl: PropTypes.object,
  handleCloseMenu: PropTypes.func,
  setOpenModifyTicket: PropTypes.func,
  showDelete: PropTypes.bool,
};
export default function OptionsPopover({
  removeTicket,
  selectedItem,
  popoverAnchorEl,
  handleCloseMenu,
  setOpenModifyTicket,
  showDelete,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showConfirmModal } = useConfirmModal(
    t('pages.ticketDetails.confirmDelete'),
    `${t('pages.ticketDetails.areYouSure')} ${selectedItem?.title}`,
    async () => removeTicket(selectedItem.id)
  );
  return (
    <Popover
      open={Boolean(popoverAnchorEl)}
      anchorEl={popoverAnchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          minwidth: isSmallScreen ? 110 : 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          setOpenModifyTicket(true);
          handleCloseMenu();
        }}
      >
        <Iconify
          icon="eva:edit-fill"
          sx={{
            mr: isSmallScreen ? 1 : 2,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.7rem',
          }}
        >
          {t('popover.edit')}
        </Typography>
      </MenuItem>
      {showDelete && (
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            showConfirmModal();
            handleCloseMenu();
          }}
        >
          <Iconify
            icon="eva:trash-2-outline"
            sx={{
              mr: isSmallScreen ? 1 : 2,
              width: isSmallScreen ? 16 : 20,
              height: isSmallScreen ? 16 : 20,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('popover.delete')}
          </Typography>
        </MenuItem>
      )}
    </Popover>
  );
}
