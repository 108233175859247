import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Stack, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';
import ChangePassword from 'src/components/changePassword/ChangePassword';

import { styles } from '../styles';

export default function ChangePasswordSection() {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  return (
    <Stack mt={1} spacing={1}>
      <Stack sx={styles.iconBox}>
        <Iconify
          icon="eva:refresh-outline"
          sx={{
            mr: isSmallScreen ? 0.5 : 1,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontSize: isSmallScreen && '0.8rem',
          }}
        >
          {t('pages.profilePage.changePassword')}:
        </Typography>
      </Stack>
      <Card sx={styles.formCard}>
        <ChangePassword />
      </Card>
    </Stack>
  );
}
