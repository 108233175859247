export const boxContentStyle = {
  flexDirection: 'column',
  height: '100vh',
};

export const gridStyle = {
  flexGrow: 1,
  height: '100%',
};

export const loadingButtonStyle = {
  bgcolor: '#229A16',
  boxShadow: '0.5px 0.5px 2 px',
};

export const typographyContentStyle = {
  marginBottom: '15px',
  textAlign: 'center',
};

export const secondButtonStyle = {
  bgcolor: '#229A16',
  boxShadow: '0.5px 0.5px 2 px',
  display: 'block',
  margin: 'auto',
  marginTop: 2,
};

export const secondGridStyle = {
  bgcolor: '#F5F5F5',
};

export const thirdGridStyle = {
  bgcolor: '#F5F5F5',

  pr: 2,

  marginTop: 2,
};

export const secondBoxtStyle = {
  height: '10%',
  '@media (max-height: 620px)': {
    height: '7%',
  },
};

export const iconifyStyle = {
  height: 30,
  width: 30,
  color: 'grey',

  '&:hover': {
    color: '#B72136',
  },
  '@media (max-height: 620px)': {
    height: 30,
    width: 30,
  },
};

export const cardStyle = {
  boxShadow: '0.5px 0.5px 2px',
  px: 2,
  py: 2,
  bgcolor: '#F9F9F9',
};

export const secondTypographyStyle = {
  mb: '5px',
  ml: '10px',
};

export const thirdTypographyStyle = {
  '@media (max-height: 620px)': {
    fontSize: '0.75rem',
  },
};

export const boxStyle = {
  height: '69vh',
  p: 1,
  overflow: 'auto',
  flexDirection: 'column-reverse',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
};

export const dialogContentStyle = {
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  padding: 0,
};

export const avatarStyle = {
  '@media (max-height: 620px)': {
    height: '00px',
    width: '30px',
  },
};

export const forthTypographyStyle = {
  wordBreak: 'break-word',
  '@media (max-height: 620px)': {
    fontSize: '0.75rem',
  },
};

export const appOrderTimelineStyle = {
  mt: 1,
  boxShadow: '0.5px 0.5px 2px',
  bgcolor: '#F9F9F9',
  overflowY: 'scroll',
  maxHeight: '35vh',

  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
};

export const appStyle = {
  justifyContent: 'center',
  display: 'flex',
  marginTop: '3vh',
};

export const cardTicketModalStyle = {
  boxShadow: '0.5px 0.5px 2px',

  overflowY: 'scroll',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
};

export const listTicketModalStyle = { width: '100%', bgcolor: 'background.paper' };

export const listItemTicketModalStyle = (selectedAgent, user) => ({
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
  backgroundColor: selectedAgent === user ? '#b4d5fe' : 'transparent',
  cursor: 'pointer',
});

export const listItemTicketPermissionModalStyle = (selectedUsers, user) => ({
  '&:hover': {
    backgroundColor: selectedUsers.some((item) => item.id === user.id)
      ? '#b4d5fe'
      : '#F5F5F5',
  },
  backgroundColor: selectedUsers.some((item) => item.id === user.id)
    ? '#b4d5fe'
    : 'transparent',
  cursor: 'pointer',
});

export const secondStackDetailsContentStyle = (copied) => ({
  backgroundColor: copied ? '#229A16' : 'transparent',
  transition: 'background-color 0.3s',
  border: copied ? 0 : 1.5,
});

export const labelDetailsContentStyle = {
  px: 4,
  '@media (max-height: 620px)': {
    height: 18,
    fontSize: '0.5rem',
  },
};

export const iconifyDetailsContentStyle = {
  '@media (max-height: 620px)': {
    height: 17,
    width: 17,
  },
};

export const paperDetailsContentStyle = {
  height: '98%',
  boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: 0,
  bgcolor: '#F9F9F9',
  width: '100%',
  alignSelf: 'center',
};

export const thirdStackDetailsContentStyle = {
  '@media (max-height: 670px)': {
    mb: 1.25,
  },
  mb: 2,
  mt: 2,
};

export const cardDetailsContentStyle = {
  height: '10vh',
  width: '100%',
  alignSelf: 'center',
  backgroundColor: '#F9F9F9',
  p: 1.5,
  boxShadow: '0.5px 0.5px 2px',
};

export const secondIconifyDetailsContentStyle = { mr: 1, mt: 0.5 };

export const secondCardTicketModalStyle = {
  width: '100%',
  alignSelf: 'center',
  p: 2,
  boxShadow: '0.5px 0.5px 2px',
  backgroundColor: '#F9F9F9',
  overflowY: 'scroll',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
};

export const avatarMessageStyle = { width: 30, height: 30 };

export const secondBoxChatStyle = {
  p: 1.5,
  mt: 1.3,
  height: '96vh',

  backgroundImage: 'url(/assets/images/covers/chatImage1.jpg)',
  boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: 3,
  backgroundSize: 'cover',
};

export const thirdBoxChatStyle = { py: 0, height: '10%' };

export const iconifyChatStyle = {
  '@media (max-height: 600px)': {
    width: 25,
  },
};

export const forthBoxChatStyle = {
  height: '75.5%',
  p: 1,
  overflow: 'auto',
  flexDirection: 'column-reverse',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
};

export const fifthBoxChatStyle = {
  height: '21.5%',
  position: 'relative',
  borderRadius: '15px',
  width: '100%',
};

export const BoxTicketFormStyle = { position: 'relative', display: 'inline-flex' };

export const secondBoxTicketFormStyle = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
