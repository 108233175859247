import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState, useEffect } from 'react';

import { Stack, Button, TextField, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import FormCard from './FormCard';
import FormSection from './FormSection';
import { useAuth } from '../../../hooks/useAuth';
import Iconify from '../../../components/iconify';
import { useToast } from '../../../hooks/useToast';
import { useFormContext } from '../../../hooks/useForm';
import SelectInput from '../../../components/FormInputs/SelectInput';
import { fetchDepartments } from '../../../services/department.service';
import { createForm, modifyForm } from '../../../services/form.service';

FormBody.propTypes = {
  updateMode: PropTypes.bool,
  formId: PropTypes.number,
  handleClose: PropTypes.func,
};

export default function FormBody({ updateMode, formId, handleClose }) {
  const {
    sections,
    addSection,
    updateFormData,
    getFormData,
    formData,
    removeQuestionAnswers,
  } = useFormContext();
  const { id, role, departmentId } = useAuth();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();
  const [focusedSection, setFocusedSection] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(
    role.authority === 'ROLE_DEPARTMENTMANAGER'
      ? departmentId
      : formData.department || null
  );
  const [sectionsArray, setSectionsArray] = useState([]);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      formName: formData.title || 'Untitled',
      formDescription: formData.description || '',
    },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const departmentList = useMemo(() => {
    const deps = departments?.map((dep) => ({ text: dep.name, value: dep.id }));
    return deps;
  }, [departments]);

  useEffect(() => {
    const sectionArray = Object.values(sections);
    setSectionsArray(sectionArray);
  }, [sections]);

  useEffect(() => {
    const fetchData = () => {
      fetchDepartments().then((res) => {
        setDepartments(res);
      });
    };
    fetchData();
  }, []);

  const handleDepartmentSelection = (value) => {
    setSelectedDepartment(value);
  };
  const onSubmit = async (data) => {
    if (!isDebouncing) {
      setIsDebouncing(true);
      removeQuestionAnswers();
      await updateFormData({
        title: data.formName,
        department: selectedDepartment,
        description: data.formDescription,
      });
      const formData = getFormData();
      if (updateMode) {
        modifyForm({
          id: formId,
          title: data.formName,
          departmentId: selectedDepartment,
          description: data.formDescription,
          formData: {
            ...formData,
            formData: {
              ...formData.formData,
              title: data.formName,
              department: selectedDepartment,
              description: data.formDescription,
            },
          },
          createdBy: id,
        })
          .then(() => {
            showToast({
              message: 'Form updated successfully',
              severity: 'success',
              props: { hideToast },
            });

            handleClose();
          })
          .catch(() => {
            showToast({
              message: 'Error updating form',
              severity: 'error',
              props: { hideToast },
            });
          });
      } else {
        createForm({
          title: data.formName,
          departmentId: selectedDepartment,
          description: data.formDescription,
          formData: {
            ...formData,
            formData: {
              ...formData.formData,
              title: data.formName,
              department: selectedDepartment,
              description: data.formDescription,
            },
          },
          createdBy: id,
        })
          .then(() => {
            showToast({
              message: 'Form created successfully',
              severity: 'success',
              props: { hideToast },
            });
            navigate('/dashboard/forms');
          })
          .catch(() => {
            showToast({
              message: 'Error creating form',
              severity: 'error',
              props: { hideToast },
            });
          });
      }
      setTimeout(() => {
        setIsDebouncing(false);
      }, 300);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} key="formCard">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mb={isSmallScreen ? 2 : 5}
        >
          <Button
            variant="contained"
            startIcon={
              <Iconify
                sx={{
                  width: isSmallScreen ? '14px' : {},
                }}
                icon={updateMode ? 'radix-icons:update' : 'eva:plus-fill'}
              />
            }
            type="submit"
            size={isSmallScreen ? 'small' : 'medium'}
            disabled={
              !isValid ||
              !selectedDepartment ||
              Object.keys(sections).length === 0 ||
              isDebouncing
            }
            sx={{
              fontSize: isSmallScreen ? '0.575rem' : '0.875rem',
            }}
          >
            {updateMode ? t('buttons.updateForm') : t('buttons.createForm')}
          </Button>
        </Stack>
        <FormCard isFormHeader>
          <TextField
            id="form-title"
            name={t('pages.formPage.formTitle')}
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('pages.formPage.formTitle')}
              </Typography>
            }
            variant="standard"
            fullWidth
            size={isSmallScreen ? 'small' : 'medium'}
            color="info"
            mt={2}
            error={!!errors.formName}
            helperText={
              errors.formName?.message && (
                <Typography sx={{ fontSize: isSmallScreen ? '0.6rem' : '0.8rem' }}>
                  {errors.formName?.message || ''}
                </Typography>
              )
            }
            {...register('formName', {
              required: t('formControl.enterName'),
            })}
            inputProps={{
              style: {
                fontSize: isSmallScreen ? '0.675rem' : '1rem',
              },
            }}
          />

          <TextField
            id="form-description"
            name="Form description"
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('pages.formPage.formDescription')}
              </Typography>
            }
            variant="standard"
            size={isSmallScreen ? 'small' : 'medium'}
            fullWidth
            error={!!errors.formDescription}
            helperText={
              errors.formDescription?.message && (
                <Typography sx={{ fontSize: isSmallScreen ? '0.6rem' : '0.8rem' }}>
                  {errors.formDescription?.message || ''}
                </Typography>
              )
            }
            {...register('formDescription', {})}
            inputProps={{
              style: {
                fontSize: isSmallScreen ? '0.675rem' : '1rem',
              },
            }}
            sx={{ marginTop: 1.5, marginBottom: 2 }}
          />

          {departmentList && (
            <SelectInput
              labelValue={t('attributes.department')}
              name="select department"
              defaultValue={formData.department || selectedDepartment}
              fullWidth
              size={isSmallScreen ? 'small' : 'medium'}
              fontSize="10px"
              disabled={role.authority === 'ROLE_DEPARTMENTMANAGER'}
              options={departmentList || []}
              setSelectedInput={handleDepartmentSelection}
              sx={{ marginTop: '2vh' }}
            />
          )}
        </FormCard>
        <Button
          id="add-section-btn"
          onClick={() => {
            if (!isDebouncing) {
              setIsDebouncing(true);

              addSection({ name: sectionsArray.length + 1, questions: [] });

              setTimeout(() => {
                setIsDebouncing(false);
              }, 300);
            }
          }}
          disabled={isDebouncing}
          sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
        >
          {t('pages.formPage.addSection')}
        </Button>

        {sectionsArray.map((section, index) => (
          <FormSection
            key={section.id}
            section={section}
            index={index + 1}
            sectionLength={sectionsArray.length}
            setFocusedSection={setFocusedSection}
            focusedSection={focusedSection}
            selectedDepartment={selectedDepartment}
          />
        ))}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mb={isSmallScreen ? 2 : 5}
          mt={isSmallScreen ? 3 : 5}
        >
          <Button
            variant="contained"
            startIcon={
              <Iconify
                sx={{
                  width: isSmallScreen ? '14px' : {},
                }}
                icon={updateMode ? 'radix-icons:update' : 'eva:plus-fill'}
              />
            }
            type="submit"
            size={isSmallScreen ? 'small' : 'medium'}
            disabled={
              !isValid ||
              !selectedDepartment ||
              Object.keys(sections).length === 0 ||
              isDebouncing
            }
            sx={{
              fontSize: isSmallScreen ? '0.575rem' : '0.875rem',
            }}
          >
            {updateMode ? t('buttons.updateForm') : t('buttons.createForm')}
          </Button>
        </Stack>
      </form>
    </div>
  );
}
