import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  Select,
  MenuItem,
  Backdrop,
  Container,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
  CircularProgress,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import FormFooter from './FormFooter';
import { scrollbarStyle } from '../style';
import FormPreview from '../form/FormPreview';
import { useAuth } from '../../hooks/useAuth';
import { FormProvider } from '../../hooks/useForm';
import { cardStyle, boxStyleProgress } from './style';
import { fetchFromLinkById } from '../../services/form.link.service';
import { createTicket, createTicketAnonymously } from '../../services/ticket.service';
import { fetchFormByIdAnyOne, fetchFormsByDepartment } from '../../services/form.service';

export default function TicketForm({ departments, favoriteForm }) {
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    getValues,
    setError,
  } = useForm({
    mode: 'all',
    defaultValues: {
      title: '',
      email: '',
    },
  });
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [forms, setForms] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [progress, setProgress] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [useFavorite, setUseFavorite] = useState(true);
  const [searchParams] = useSearchParams();
  const formToken = searchParams.get('form');
  const { isAuthenticated, email: authenticatedEmail } = useAuth();
  const { t, i18n } = useTranslation();
  const [formSharableSettings, setFormSharableSettings] = useState({});
  const { isSmallScreen } = useResponsiveScreen();

  const handleDepartmentSelection = (e) => {
    setSelectedForm();
    setUseFavorite(false);
    getFormsByDepartment(e.target.value);
    setSelectedDepartment(e.target.value);
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const addTicket = async (
    userId,
    data,
    priority,
    duration,
    category,
    files,
    description,
    department,
    agents,
    autoAssign
  ) => {
    const title = getValues('title');
    const email = getValues('email');
    const ticketRequest = {
      title,
      description,
      status: 'UNASSIGNED',
      status_id: 1,
      priority: priority || 'LOW',
      estimatedDuration: duration,
      category,
      createdBy: userId || -1,
      department_id: department,
      data,
      agents,
      autoAssign,
      form_id: selectedForm.id,
    };
    const formData = files;
    const ticketData = new Blob([JSON.stringify(ticketRequest, null, 2)], {
      type: 'application/json',
    });
    formData.append('ticketRequest', ticketData);
    const onUploadProgress = (progressEvent) => {
      const uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      // Update your UI or state with the progress value
      setProgress(uploadProgress);
    };
    if (isAuthenticated && !formToken) {
      return createTicket(formData, onUploadProgress);
    }
    return createTicketAnonymously(
      formData,
      onUploadProgress,
      formToken,
      isAuthenticated ? authenticatedEmail : email
    );
  };

  const getFormsByDepartment = async (deptId) => {
    const fromsData = await fetchFormsByDepartment(deptId);
    setForms(fromsData);
  };

  const handleNext = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    if (showForm) setShowForm(false);
    else navigate('/dashboard/tickets');
  };

  const handleFormSelection = (e) => {
    setSelectedForm(forms.find((form) => form.id === e.target.value));
  };

  useEffect(() => {
    setError('title', {
      type: 'manual',
      message: t('formControl.enterTitle'),
    });
    setError('email', {
      type: 'manual',
      message: t('formControl.enterEmail'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (favoriteForm) {
      getFormsByDepartment(favoriteForm.departmentId);
      setSelectedDepartment(favoriteForm.departmentId);
      setShowForm(true);
    }
  }, [favoriteForm]);

  useEffect(() => {
    if (favoriteForm && selectedDepartment && useFavorite) {
      setSelectedForm(favoriteForm);
    }
  }, [favoriteForm, selectedDepartment, useFavorite]);

  const handleGetForm = async (id) => {
    const data = await fetchFormByIdAnyOne(id);
    setSelectedForm(data);
    setShowForm(true);
  };

  const fetchFormLink = async () => {
    if (formToken) {
      try {
        const formDecoded = jwtDecode(formToken);

        const formLink = await fetchFromLinkById(formDecoded.formLinkId, formToken);
        setFormSharableSettings(formLink);
        // Change the language in i18n
        i18n.changeLanguage(formLink.language);

        const now = new Date();
        const expirationDate = new Date(formLink.expiresOn);

        if (formLink.isActive && now < expirationDate) {
          handleGetForm(formDecoded.formId);
        } else {
          navigate('/form-unavailable');
        }
      } catch (error) {
        navigate('/form-unavailable');
      }
    }
  };

  useEffect(() => {
    fetchFormLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formToken]);

  return (
    <Box
      sx={{
        backgroundImage: formToken ? 'url("/assets/images/covers/image.png")' : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: formToken ? (isSmallScreen ? '30px' : '60px') : '0px',
      }}
    >
      <Container sx={formToken ? cardStyle : {}}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={progress} />
            <Box sx={boxStyleProgress}>
              <Typography variant="caption" component="div" color="white">
                {`${progress}%`}
              </Typography>
            </Box>
          </Box>
        </Backdrop>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={formToken ? 3 : 1}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: isSmallScreen && '0.875rem',
            }}
          >
            {t('pages.ticketDetails.createTicket')}
          </Typography>
        </Stack>

        <form onSubmit={handleSubmit(() => {})}>
          <Stack spacing={isSmallScreen ? 2 : 3}>
            <Box />
            {!formToken && (
              <FormControl
                fullWidth
                error={!!errors.department}
                size={isSmallScreen ? 'small' : 'medium'}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                >
                  {t('attributes.department')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="department-select"
                  disabled={showForm}
                  label={t('attributes.department')}
                  defaultValue=""
                  onChange={handleDepartmentSelection}
                  value={selectedDepartment || ''}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        ...scrollbarStyle,
                      },
                    },
                  }}
                >
                  {departments?.length > 0 ? (
                    departments.map((department) => {
                      const { id, name } = department;
                      return (
                        <MenuItem key={id} value={id}>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                          >
                            {name}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled value="">
                      <Typography
                        variant="body2"
                        sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                      >
                        {t('infoMessages.noOptions')}
                      </Typography>
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText
                  sx={{
                    fontSize: isSmallScreen ? '0.775rem' : '0.8rem',
                  }}
                >
                  {errors.department?.message}
                </FormHelperText>
              </FormControl>
            )}
            {showForm ? (
              <>
                <FormControl fullWidth size={isSmallScreen ? 'small' : 'medium'}>
                  <InputLabel
                    id="formselect-label"
                    sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                  >
                    {t('nav.forms')}
                  </InputLabel>

                  <Select
                    labelId="form-label"
                    id="form-select"
                    label={t('nav.forms')}
                    value={selectedForm?.id || ''}
                    onChange={handleFormSelection}
                    disabled={formToken}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          ...scrollbarStyle,
                        },
                      },
                    }}
                  >
                    {forms && forms.length > 0
                      ? forms.map((form) => {
                          const { id, title } = form;

                          return (
                            <MenuItem key={id} value={id}>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                              >
                                {title}
                              </Typography>
                            </MenuItem>
                          );
                        })
                      : (formToken && (
                          <MenuItem key={selectedForm?.id} value={selectedForm?.id}>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                            >
                              {selectedForm?.title}
                            </Typography>
                          </MenuItem>
                        )) || (
                          <MenuItem disabled value="">
                            <Typography
                              variant="body2"
                              sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
                            >
                              {t('pages.ticketDetails.noOptions')}
                            </Typography>
                          </MenuItem>
                        )}
                  </Select>
                </FormControl>

                {selectedForm?.formData && (
                  <>
                    <TextField
                      id="title"
                      name="title"
                      label={t('attributes.title')}
                      error={!!errors.title}
                      helperText={errors.title?.message || ''}
                      {...register('title', {
                        required: t('formControl.enterTitle'),
                        maxLength: {
                          value: 50,
                          message: 'the title must have at most 50 characters',
                        },
                      })}
                      size={isSmallScreen ? 'small' : 'medium'}
                      sx={{
                        '& .MuiInputBase-root': {
                          fontSize: isSmallScreen ? '0.8rem' : '1rem',
                        },
                        '& .MuiFormLabel-root': {
                          fontSize: isSmallScreen ? '0.8rem' : '1rem',
                        },
                        '& .MuiFormHelperText-root': {
                          fontSize: isSmallScreen ? '0.775rem' : '0.8rem',
                        },
                      }}
                    />
                    {/* Email Input Field */}
                    {formToken && !isAuthenticated && (
                      <TextField
                        id="email"
                        name="email"
                        label={t('attributes.email')}
                        error={!!errors.email}
                        helperText={errors.email?.message || ''}
                        {...register('email', {
                          required: t('formControl.enterEmail'),
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: t('formControl.invalidEmail'),
                          },
                        })}
                        size={isSmallScreen ? 'small' : 'medium'}
                        sx={{
                          '& .MuiInputBase-root': {
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          },
                          '& .MuiFormLabel-root': {
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          },
                          '& .MuiFormHelperText-root': {
                            fontSize: isSmallScreen ? '0.775rem' : '0.8rem',
                          },
                        }}
                      />
                    )}
                    <FormProvider
                      key={selectedForm.id}
                      initialData={selectedForm.formData}
                    >
                      <FormPreview
                        disabled={!isValid}
                        addTicket={addTicket}
                        handleBackdropClose={handleBackdropClose}
                        handleBackdropOpen={handleBackdropOpen}
                        formSharableSettings={formSharableSettings}
                      />
                    </FormProvider>
                    <br />
                  </>
                )}
              </>
            ) : null}
            {departments?.length > 0 && !formToken && (
              <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid>
                  <LoadingButton
                    id="next-btn"
                    variant="contained"
                    onClick={handleNext}
                    disabled={!selectedDepartment || showForm}
                    size={isSmallScreen ? 'small' : 'medium'}
                    sx={{
                      fontSize: isSmallScreen ? '0.775rem' : '0.9rem',
                    }}
                  >
                    {t('buttons.next')}
                  </LoadingButton>
                  <LoadingButton
                    id="cancel-btn"
                    sx={{
                      my: 2,
                      bgcolor: '#b90e0a',
                      marginLeft: '1vh',
                      fontSize: isSmallScreen ? '0.775rem' : '0.9rem',
                    }}
                    variant="contained"
                    onClick={handleCancel}
                    size={isSmallScreen ? 'small' : 'medium'}
                  >
                    {t('buttons.cancel')}
                  </LoadingButton>
                </Grid>
              </Stack>
            )}
            {formToken && <FormFooter show />}
          </Stack>
        </form>
      </Container>
    </Box>
  );
}

TicketForm.propTypes = {
  departments: PropTypes.array,
  favoriteForm: PropTypes.object,
};
