import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/system';
// @mui
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  ClickAwayListener,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { StyledSearch, toolbarStyle } from 'src/components/ToolBars/style';

// component
import Iconify from '../iconify';

const handleDelete = () => {};
UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  setFilterName: PropTypes.func,
  onFilterName: PropTypes.func,
  model: PropTypes.string,
  filterBy: PropTypes.string,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  setFilterName,
  onFilterName,
  model,
  filterBy,
  handleOpenFilter,
  handleCloseFilter,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const theme = useTheme();

  const handleClearSearch = () => {
    setFilterName('');
    onFilterName({ target: { value: '' } });
  };

  const isDisabled = useMemo(
    () => filterBy === 'byDepartment' || filterBy === 'byRelatedSystemStatus',
    [filterBy]
  );
  return (
    <Toolbar sx={toolbarStyle(theme, numSelected)}>
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
        {numSelected > 0 ? (
          <Typography
            component="div"
            variant="subtitle1"
            sx={{
              fontSize: isSmallScreen ? '0.8rem' : '1rem',
            }}
          >
            {numSelected} selected
          </Typography>
        ) : (
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder={`search ${model}...`}
            disabled={isDisabled}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: 'text.disabled',
                    width: isSmallScreen ? 16 : 20,
                    height: isSmallScreen ? 16 : 20,
                  }}
                />
              </InputAdornment>
            }
            endAdornment={
              filterName && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <Iconify
                      icon="eva:close-fill"
                      sx={{
                        color: 'text.disabled',
                        width: isSmallScreen ? 16 : 20,
                        height: isSmallScreen ? 16 : 20,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }
            sx={{
              width: filterName ? '320px' : '240px',
              fontSize: isSmallScreen ? '0.75rem' : '1rem',
              mt: [0, 0.5],
              '&.Mui-focused': {
                width: isSmallScreen ? 290 : 320,
              },
            }}
            size={isSmallScreen ? 'small' : 'medium'}
          />
        )}
      </Stack>

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete} sx={{ p: isSmallScreen ? 0.5 : 1 }}>
            <Iconify
              icon="eva:trash-2-fill"
              sx={{
                width: isSmallScreen ? 16 : 20,
                height: isSmallScreen ? 16 : 20,
              }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Stack direction="row" alignItems="center" spacing={2} mt={1.5}>
          {filterBy && (
            <Typography
              sx={{
                fontSize: isSmallScreen ? '0.7rem' : '1rem',
              }}
            >
              {t('filters.search')} {t(`filters.${filterBy}`)}
            </Typography>
          )}

          <ClickAwayListener
            onClickAway={() => {
              if (handleCloseFilter) handleCloseFilter();
            }}
          >
            <div>
              <Tooltip title="Filter list">
                <IconButton
                  onClick={(e) => {
                    if (handleOpenFilter) handleOpenFilter(e);
                  }}
                >
                  <Iconify
                    icon="ic:round-filter-list"
                    sx={{
                      width: isSmallScreen ? 18 : 20,
                      height: isSmallScreen ? 18 : 20,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Stack>
      )}
    </Toolbar>
  );
}
