import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Duration } from '@js-joda/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import {
  Box,
  Fab,
  Card,
  Stack,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { statusColorMapper } from 'src/utils/statusColorMapper';

import { updateTicket } from 'src/services/ticket.service';

import { scrollbarStyle } from 'src/sections/style';
import {
  boxStyle,
  thirdTypographyStyle,
  secondTypographyStyle,
  cardDetailsContentStyle,
  secondCardTicketModalStyle,
  thirdStackDetailsContentStyle,
  secondStackDetailsContentStyle,
} from 'src/sections/ticketDetails/styles';

import Iconify from '../../../components/iconify';
import { useToast } from '../../../hooks/useToast';
import { genericApiToaster } from '../../../services/utils';
import { convertLocalTime } from '../../../utils/convertTime';

DetailsContent.propTypes = {
  row: PropTypes.object,
  openEdit: PropTypes.func,
  status: PropTypes.object,
  statusesList: PropTypes.array,
  departmentUsers: PropTypes.array,
  fetchTicketData: PropTypes.func,
  hasReadPermission: PropTypes.bool,
  hasWritePermission: PropTypes.bool,
};

export default function DetailsContent({
  row,
  status,
  statusesList,
  departmentUsers,
  fetchTicketData,
  hasReadPermission,
  hasWritePermission,
}) {
  const [updateDate, setUpdateDate] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const { id: currentUserId, role: currentUserRole } = useAuth();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [copied, setCopied] = useState(false);
  const [showAgents, setShowAgents] = useState(false);

  const [initialStatusObject, setInitialStatusObject] = useState({});
  const [selectedStatusObject, setSelectedStatusObject] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,

    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: row?.id || 0,
      title: row?.title || '',
      description: row?.description || '',
      department: row?.department || '',
      priority: row?.priority || '',
      status: row?.status || '',
      createdAt: row?.createdAt || new Date(),
      department_id: row?.departmentId || 0,
      assignedTo: row?.assignedTo || -1,
      createdBy: row?.createdBy || -1,
      status_id: statusesList?.some((status) => status.statusId === row?.status_id)
        ? row?.status_id
        : '',
    },
  });

  const selectedStatusId = watch('status_id');
  const { showToast, hideToast } = useToast();

  useEffect(() => {
    const defaultValues = {
      id: row?.id || 0,
      title: row?.title || '',
      description: row?.description || '',
      department: row?.department || '',
      priority: row?.priority || '',
      status: row?.status || '',
      createdAt: row?.createdAt || new Date(),
      department_id: row?.departmentId || 0,
      assignedTo: row?.assignedTo || -1,
      createdBy: row?.createdBy || -1,
      status_id: statusesList?.some((status) => status.statusId === row?.status_id)
        ? row?.status_id
        : '',
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, statusesList]);

  useEffect(() => {
    if (row?.createdAt) {
      const creatDate = format(
        convertLocalTime(new Date(row.createdAt)),
        'dd/MM/yyyy HH:mm:ss'
      );
      setCreatedDate(creatDate);
    }
    if (row?.updatedAt) {
      const upDate = format(
        convertLocalTime(new Date(row.updatedAt)),
        'dd/MM/yyyy HH:mm:ss'
      );
      setUpdateDate(upDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const statusDescription = useMemo(
    () =>
      status
        ? (status?.statusId < 6 && t(`statusDescription.${status?.name}`)) ||
          status?.description
        : '',
    [status, t]
  );
  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const modifyTicket = async (newTicketData) => {
    genericApiToaster(
      updateTicket,
      newTicketData,
      t('successMessages.successTicketUpdate'),
      t('errorMessages.errorUpdatingTicket'),
      showToast,
      hideToast,
      () => {
        fetchTicketData(row.id);
      }
    );
  };

  const onSubmit = (data) => {
    if (selectedStatusObject.systemMappedStatus === 'UNASSIGNED') {
      data.assignedTo = -1;
    }
    modifyTicket(data);
  };

  useEffect(() => {
    const selectedObject = statusesList.find(
      (status) => status.statusId === selectedStatusId
    );
    setSelectedStatusObject(selectedObject);
    if (
      initialStatusObject?.systemMappedStatus === 'UNASSIGNED' &&
      selectedObject?.systemMappedStatus !== 'UNASSIGNED'
    ) {
      if (currentUserRole.authority === 'ROLE_AGENT') {
        setShowAgents(false);
        setValue('assignedTo', currentUserId);
      } else {
        setShowAgents(true);
      }
    } else {
      setShowAgents(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusId, statusesList]);

  useEffect(() => {
    setInitialStatusObject(
      statusesList.find((status) => status.statusId === row?.status_id)
    );
  }, [row, statusesList]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        mt={2}
        mb={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          sx={secondStackDetailsContentStyle(copied)}
          mt={1}
          borderRadius={2.5}
          py={0.3}
          pl={2}
          ml={0.3}
          width="50%"
          direction="row"
        >
          {copied ? (
            <Typography
              color="white"
              variant="body2"
              sx={{
                fontSize: isSmallScreen && '0.6rem',
              }}
            >
              {t('pages.ticketDetails.idCopied')}
            </Typography>
          ) : (
            <Typography
              variant="button"
              sx={{
                fontSize: isSmallScreen && '0.75rem',
              }}
            >
              {t('pages.ticketDetails.ticketId')} : #{row?.id || ' No ID'}
            </Typography>
          )}

          <IconButton
            onClick={() => {
              copyToClipboard(`${row.id}`);
            }}
          >
            {copied ? (
              <Iconify color="white" icon="material-symbols:file-copy-outline-rounded" />
            ) : (
              <Iconify icon="material-symbols:file-copy-outline-rounded" />
            )}
          </IconButton>
        </Stack>

        {hasWritePermission && (
          <Stack
            direction="row"
            spacing={1}
            mt={1}
            alignItems="center"
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
          >
            <Tooltip title={t('buttons.confirmChanges')}>
              <span>
                <Fab size="small" disabled={!isDirty || !isValid} type="submit">
                  <Iconify
                    width="100"
                    color={isValid && isDirty ? 'green' : ''}
                    icon="heroicons-outline:save"
                    style={{ fontSize: isSmallScreen ? 20 : 25 }}
                  />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title={t('buttons.cancelChanges')}>
              <span>
                <Fab size="small" disabled={!isDirty} onClick={() => reset()}>
                  <Iconify
                    width="60"
                    color={isDirty ? 'red' : ''}
                    icon="ep:close-bold"
                    style={{ fontSize: isSmallScreen ? 20 : 27 }}
                  />
                </Fab>
              </span>
            </Tooltip>
          </Stack>
        )}
      </Stack>

      <Box sx={boxStyle}>
        <Box>
          <Stack spacing={3} sx={{ pt: isSmallScreen ? 1.5 : 3.5 }}>
            <Stack>
              <Stack ml={0.5} spacing={2}>
                <TextField
                  variant="standard"
                  name="title"
                  disabled={!hasWritePermission}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size={isSmallScreen ? 'small' : 'medium'}
                  sx={{
                    '& .MuiInput-underline': {
                      width: isSmallScreen ? 'auto' : '460px',
                    },
                    '& .MuiInputBase-root': {
                      fontSize: isSmallScreen ? '0.85rem' : '1rem',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: isSmallScreen ? '0.6rem' : '0.8rem',
                    },
                  }}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: isSmallScreen ? '0.85rem' : '1rem',
                      }}
                    >
                      {t('attributes.title')}
                    </Typography>
                  }
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  {...register('title', {
                    required: t('formControl.enterTitle'),
                    maxLength: {
                      value: 50,
                      message: t('formControl.maxTitle'),
                    },
                  })}
                />
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              {currentUserRole.authority !== 'ROLE_CUSTOMER' &&
                (currentUserRole.authority !== 'ROLE_AGENT' ||
                  row?.createdBy !== currentUserId) && (
                  <FormControl sx={{ width: '50%' }} error={!!errors.status_id}>
                    <InputLabel id="ticket-status-select-label">
                      <Typography
                        variant="body2"
                        sx={{ fontSize: isSmallScreen ? '0.85rem' : '1rem' }}
                      >
                        {t('attributes.status')}
                      </Typography>
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="ticket-status-select-label"
                      id="status-select"
                      name="status_id"
                      size="small"
                      disabled={!hasWritePermission}
                      value={selectedStatusId}
                      label={
                        <Typography
                          variant="body2"
                          sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                        >
                          {t('attributes.status')}
                        </Typography>
                      }
                      {...register('status_id')}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            ...scrollbarStyle,
                          },
                        },
                      }}
                    >
                      {statusesList?.length > 0 ? (
                        statusesList.map((status) => {
                          const { statusId, name } = status;
                          let tName = name;
                          if (statusId <= 5) tName = t(`status.${name}`);
                          return (
                            <MenuItem key={statusId} value={statusId}>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                              >
                                {tName}
                              </Typography>
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem disabled value="">
                          <Typography
                            variant="body2"
                            sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                          >
                            {t('infoMessages.noOptions')}
                          </Typography>
                        </MenuItem>
                      )}
                    </Select>

                    <FormHelperText
                      sx={{ fontSize: isSmallScreen ? '0.6rem' : '0.8rem' }}
                    >
                      {errors.status?.message}
                    </FormHelperText>
                  </FormControl>
                )}

              <FormControl sx={{ width: '50%' }} error={!!errors.priority}>
                <InputLabel id="demo-simple-select-label">
                  <Typography
                    variant="body2"
                    sx={{ fontSize: isSmallScreen ? '0.85rem' : '1rem' }}
                  >
                    {t('attributes.priority')}
                  </Typography>
                </InputLabel>

                <Select
                  fullWidth
                  disabled={!hasWritePermission}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="priority"
                  size="small"
                  value={watch('priority')}
                  defaultValue={row?.priority ?? ''}
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                    >
                      {t('attributes.priority')}
                    </Typography>
                  }
                  {...register('priority')}
                >
                  <MenuItem key={1} value="HIGH">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                    >
                      {t('priority.HIGH')}
                    </Typography>
                  </MenuItem>
                  <MenuItem key={2} value="MEDIUM">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                    >
                      {t('priority.MEDIUM')}
                    </Typography>
                  </MenuItem>
                  <MenuItem key={3} value="LOW">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                    >
                      {t('priority.LOW')}
                    </Typography>
                  </MenuItem>
                </Select>
                <FormHelperText sx={{ fontSize: isSmallScreen ? '0.6rem' : '0.8rem' }}>
                  {errors.priority?.message}
                </FormHelperText>
              </FormControl>
            </Stack>
            {showAgents && (
              <FormControl fullWidth error={!!errors.status_id}>
                <InputLabel id="ticket-assignedTo-select-label">
                  <Typography
                    variant="body2"
                    sx={{ fontSize: isSmallScreen ? '0.85rem' : '1rem' }}
                  >
                    {t('pages.ticketDetails.assignTo')}
                  </Typography>
                </InputLabel>
                <Select
                  labelId="ticket-assignedTo-select-label"
                  id="assignedTo-select"
                  name="status_id"
                  size="small"
                  defaultValue={row?.assignedTo ?? -1}
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                    >
                      {t('pages.ticketDetails.assignTo')}
                    </Typography>
                  }
                  {...register('assignedTo', {
                    required: t('formControl.selectTicketManager'),
                    validate: { assigned: (v) => parseInt(v, 10) !== -1 },
                  })}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        ...scrollbarStyle,
                      },
                    },
                  }}
                >
                  <MenuItem key={-1} value={-1}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                    >
                      {t('pages.ticketDetails.selectTicketManager')}
                    </Typography>
                  </MenuItem>

                  {departmentUsers?.length > 0 ? (
                    departmentUsers.map((user) => {
                      const { id, username } = user;
                      return (
                        <MenuItem key={id} value={id}>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                          >
                            {username}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled value="">
                      <Typography
                        variant="body2"
                        sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                      >
                        {t('infoMessages.noOptions')}
                      </Typography>
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText
                  sx={{
                    fontSize: isSmallScreen ? '0.6rem' : '0.8rem',
                  }}
                >
                  {errors.status?.message}
                </FormHelperText>
              </FormControl>
            )}
          </Stack>
        </Box>
        <TextField
          fullWidth
          multiline
          maxRows={3}
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{
            mt: 2.5,
            '& .MuiInput-underline': {
              width: isSmallScreen ? 'auto' : '460px',
            },
            '& .MuiInputBase-root': {
              fontSize: isSmallScreen ? '0.85rem' : '1rem',
            },
            '& .MuiFormHelperText-root': {
              fontSize: isSmallScreen ? '0.6rem' : '0.8rem',
            },
          }}
          name="description"
          disabled={!hasWritePermission}
          InputLabelProps={{
            shrink: true,
          }}
          label={
            <Typography
              variant="body1"
              sx={{
                fontSize: isSmallScreen ? '0.85rem' : '1rem',
              }}
            >
              Notes
            </Typography>
          }
          error={!!errors.description}
          helperText={errors.description?.message}
          {...register('description', {
            maxLength: {
              value: 255,
              message: t('pages.ticketDetails.notes'),
            },
          })}
        />
        <Box>
          <Box>
            <Box>
              <Stack spacing={0} sx={thirdStackDetailsContentStyle}>
                <Stack direction="row">
                  <Typography
                    sx={{
                      ...secondTypographyStyle,
                      fontSize: isSmallScreen ? '0.85rem' : '1rem',
                    }}
                    variant="h6"
                  >
                    {t('attributes.status')} :
                  </Typography>
                </Stack>
                <Card sx={cardDetailsContentStyle}>
                  <Typography
                    sx={{
                      ...thirdTypographyStyle,
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                    color={statusColorMapper(status)}
                  >
                    {statusDescription}
                  </Typography>
                </Card>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={isSmallScreen ? 0 : 1} height="auto">
                <Stack height="10%" direction="row">
                  <Typography
                    sx={{
                      ...secondTypographyStyle,
                      fontSize: isSmallScreen ? '0.85rem' : '1rem',
                    }}
                    variant="h6"
                  >
                    {t('pages.ticketDetails.ticketInfo')}:
                  </Typography>
                </Stack>
                <Card sx={secondCardTicketModalStyle}>
                  <Stack spacing={1}>
                    <Stack spacing={3} direction="row">
                      <Typography
                        variant="body1"
                        sx={{
                          ...thirdTypographyStyle,
                          fontSize: isSmallScreen ? '0.8rem' : '1rem',
                        }}
                      >
                        {t('pages.ticketDetails.postedBy')} :
                      </Typography>
                      <Stack spacing={1} direction="row">
                        <Iconify sx={{ mt: 0.4 }} icon="eva:person-outline" />
                        <Typography
                          sx={{
                            ...thirdTypographyStyle,
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          }}
                          variant="h6"
                        >
                          {row?.customerName !== 'none'
                            ? row?.customerName || 'customer'
                            : t('pages.ticketDetails.anonymous')}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack spacing={3} direction="row">
                      <Typography
                        sx={{
                          fontSize: isSmallScreen ? '0.8rem' : '1rem',
                        }}
                        variant="body1"
                      >
                        {t('pages.ticketDetails.creationDate')} :
                      </Typography>
                      {createdDate ? (
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="eva:clock-outline" />
                          <Typography
                            sx={{
                              ...thirdTypographyStyle,
                              fontSize: isSmallScreen ? '0.8rem' : '1rem',
                            }}
                            variant="h6"
                          >
                            {createdDate}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                    {updateDate ? (
                      <Stack spacing={3} direction="row">
                        <Typography
                          sx={{
                            ...thirdTypographyStyle,
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          }}
                        >
                          {t('pages.ticketDetails.lastUpdate')} :
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="eva:clock-outline" />
                          <Typography
                            sx={{
                              ...thirdTypographyStyle,
                              fontSize: isSmallScreen ? '0.8rem' : '1rem',
                            }}
                            variant="h6"
                          >
                            {updateDate}
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : null}

                    <Stack spacing={3} direction="row">
                      <Typography
                        sx={{
                          ...thirdTypographyStyle,
                          fontSize: isSmallScreen ? '0.8rem' : '1rem',
                        }}
                        variant="body 1"
                      >
                        {t('pages.ticketDetails.ticketDep')} :
                      </Typography>
                      <Stack spacing={1} direction="row">
                        <Iconify sx={{ mt: 0.4 }} icon="eva:pantone-outline" />
                        <Typography
                          sx={{
                            ...thirdTypographyStyle,
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          }}
                          variant="h6"
                        >
                          {row?.department || 'department'}
                        </Typography>
                      </Stack>
                    </Stack>

                    {row?.category && (
                      <Stack spacing={3} direction="row">
                        <Typography
                          sx={{
                            ...thirdTypographyStyle,
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          }}
                          variant="body 1"
                        >
                          {t('pages.ticketDetails.ticketCat')}:
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <Iconify
                            sx={{ mt: 0.4 }}
                            icon="material-symbols:category-outline-rounded"
                          />
                          <Typography
                            sx={{
                              ...thirdTypographyStyle,
                              fontSize: isSmallScreen ? '0.8rem' : '1rem',
                            }}
                            variant="h6"
                          >
                            {row?.category || 'category'}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}

                    {row && row.status !== 'RESOLVED' && row.status !== 'CLOSED' && (
                      <Stack spacing={3} direction="row">
                        <Typography
                          sx={{
                            ...thirdTypographyStyle,
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          }}
                          variant="body1"
                        >
                          {t('tableHeads.ticket.estimatedDuration')}
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="eva:clock-outline" />
                          {row.estimatedDuration ? (
                            <Typography
                              sx={{
                                ...thirdTypographyStyle,
                                fontSize: isSmallScreen ? '0.8rem' : '1rem',
                              }}
                              variant="h6"
                            >
                              {`${Duration.parse(row.estimatedDuration).toHours()} ${t(
                                'attributes.hours'
                              )}`}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                ...thirdTypographyStyle,
                                fontSize: isSmallScreen ? '0.8rem' : '1rem',
                              }}
                              variant="h6"
                            >
                              {t('pages.ticketDetails.nonDefined')}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    )}
                    {row?.status === 'OPEN' && (
                      <Stack spacing={2} direction="row">
                        <Typography
                          sx={{
                            ...thirdTypographyStyle,
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          }}
                          variant="body1"
                        >
                          {t('tableHeads.ticket.estimatedEnd')}
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="eva:clock-outline" />
                          <Typography
                            sx={{
                              ...thirdTypographyStyle,
                              fontSize: isSmallScreen ? '0.8rem' : '1rem',
                            }}
                            variant="h6"
                          >
                            {new Date(row.estimatedEndTime).toLocaleString()}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Card>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
