import PropTypes from 'prop-types';

import { Box, Divider, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from '../../../components/iconify';

InfoRow.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string || PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default function InfoRow({ icon, label, value }) {
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <>
      <Box direction="row" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Iconify icon={icon} style={{ color: '#4f4f4f', marginRight: '15px' }} />
          <Typography sx={{ fontSize: isSmallScreen && '0.8rem' }}>{label}</Typography>
        </Box>
        <Typography variant="subtitle1" sx={{ fontSize: isSmallScreen && '0.8rem' }}>
          {value}
        </Typography>
      </Box>
      <Divider />
    </>
  );
}
