import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Typography,
  FormControlLabel,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { useConfirmModal } from '../../../hooks/useModal';
import LanguageSelector from './sections/LanguageSelector';
import { TypographyStyleShowMoreOptions } from './sections/style';
import DurationSelector from '../../../components/durationSelecor/DurationSelector';
import {
  updateShareableForm,
  generateShareableForm,
} from '../../../services/form.link.service';

const LinkGenerationOptions = ({
  formId,
  token,
  setToken,
  isNewShareableForm = false,
  hideModal,
  fetchData,
  isUpdate,
  existingData,
}) => {
  const { t, i18n } = useTranslation();

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(23);
  const [minutes, setMinutes] = useState(59);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isMultipleSubmission, setIsMultipleSubmission] = useState(false);
  const [isExpirationSet, setIsExpirationSet] = useState(false);
  const [isLanguageSet, setIsLanguageSet] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const { showToast, hideToast } = useToast();
  let validityInMilliSeconds;
  let languageToUse = selectedLanguage;
  const { id } = useAuth();
  const { isSmallScreen } = useResponsiveScreen();

  // Initialize state if in update mode
  useEffect(() => {
    if (isUpdate && existingData) {
      setShowAdvancedOptions(true); // Show advanced options if updating

      // Calculate time difference
      const expirationDate = new Date(existingData.expiresOn);
      const currentDate = new Date();

      const differenceInMilliseconds = expirationDate - currentDate;
      const differenceInSeconds = Math.max(
        0,
        Math.floor(differenceInMilliseconds / 1000)
      ); // Ensure non-negative

      const secondsInMinute = 60;
      const secondsInHour = 60 * secondsInMinute;
      const secondsInDay = 24 * secondsInHour;

      setIsAnonymous(existingData.isAnonymous || false);
      setIsMultipleSubmission(existingData.isMultipleSubmission || false);
      if (Math.floor(differenceInSeconds / secondsInDay) > 999999) {
        setIsExpirationSet(false);
      } else {
        setIsExpirationSet(true);
        setDays(Math.floor(differenceInSeconds / secondsInDay));
        setHours(Math.floor((differenceInSeconds % secondsInDay) / secondsInHour));
        setMinutes(Math.floor((differenceInSeconds % secondsInHour) / secondsInMinute));
      }
      if (existingData.language !== i18n.language) {
        setIsLanguageSet(true);
        setSelectedLanguage(existingData.language || i18n.language);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, existingData]);

  const handleResetSettings = () => {
    setIsExpirationSet(false);
    setIsLanguageSet(false);
    setSelectedLanguage(i18n.language);
    setShowAdvancedOptions(false);
    setIsAnonymous(false);
    setIsMultipleSubmission(false);
  };

  const createShareableForm = async () => {
    const token = await generateShareableForm(
      id,
      formId,
      validityInMilliSeconds,
      languageToUse,
      isAnonymous,
      isMultipleSubmission,
      true // confirmCreation is true
    );
    if (isNewShareableForm) {
      hideModal();
      fetchData();
    } else {
      setToken(token);
    }
    handleShowToast();
  };

  const { showConfirmModal } = useConfirmModal(
    t('pages.shareableFormsPage.confirmCreation'),
    t('pages.shareableFormsPage.areYouSureToCreateAnotherLinkBecauseItAlreadyExists'),
    createShareableForm
  );

  const handleShowToast = () => {
    // Update success message based on form type
    const message = isNewShareableForm
      ? t('successMessages.newShareableFormAdded')
      : isUpdate
      ? t('successMessages.updatedShareableForm')
      : t('successMessages.linkGenerated');

    showToast({
      message,
      severity: 'success',
      props: { hideToast },
    });
  };

  const handleGenerateLink = async () => {
    try {
      if (isExpirationSet) {
        validityInMilliSeconds =
          (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60) * 1000;
      } else {
        validityInMilliSeconds = 1000 * 60 * 60 * 24 * 365 * 1000; // 1000 years
      }

      if (!isLanguageSet && selectedLanguage !== i18n.language) {
        languageToUse = i18n.language;
        setSelectedLanguage(i18n.language);
      }

      if (isUpdate && existingData) {
        await updateShareableForm(
          existingData.id,
          validityInMilliSeconds,
          languageToUse,
          isAnonymous,
          isMultipleSubmission
        );
        hideModal();
        fetchData();
        handleShowToast();
      } else {
        const res = await generateShareableForm(
          id,
          formId,
          validityInMilliSeconds,
          languageToUse,
          isAnonymous,
          isMultipleSubmission,
          false
        );
        if (res && res.startsWith('Form link already exists')) {
          // Extract the ID from the response
          const existingId = res.split('ID: ')[1];
          showConfirmModal(existingId);
        } else {
          if (isNewShareableForm) {
            hideModal();
            fetchData();
          } else {
            setToken(res);
          }
          handleShowToast();
        }
      }

      handleResetSettings();
    } catch (error) {
      showToast({
        message: t('errorMessages.cannotGenerateLink'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const toggleExpiration = () => {
    setIsExpirationSet(!isExpirationSet);
    if (isExpirationSet) {
      setDays(0);
      setHours(23);
      setMinutes(59);
    }
  };

  const toggleLanguageManagement = () => {
    setIsLanguageSet(!isLanguageSet);
  };

  const toggleAdvanced = () => setShowAdvancedOptions(!showAdvancedOptions);

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ fontSize: isSmallScreen ? 13 : {} }}>
        {t('pages.formPage.manageFormSharingSettings')}
      </Typography>

      <Typography
        variant="body1"
        sx={{ ...TypographyStyleShowMoreOptions, fontSize: isSmallScreen ? 12 : {} }}
        onClick={toggleAdvanced}
      >
        {showAdvancedOptions
          ? t('pages.formPage.hideMoreOptions')
          : t('pages.formPage.showMoreOptions')}
        <Iconify
          icon={
            showAdvancedOptions
              ? 'ic:round-keyboard-arrow-up'
              : 'ic:round-keyboard-arrow-down'
          }
          sx={{ ml: 1, color: 'primary.main' }}
        />
      </Typography>

      <Collapse in={showAdvancedOptions}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLanguageSet}
              onChange={toggleLanguageManagement}
              size={isSmallScreen ? 'small' : 'medium'}
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('pages.formPage.manageLanguageCheckbox')}
            </Typography>
          }
        />

        <Box>
          {isLanguageSet && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('pages.formPage.selectFormLanguage')}
              </Typography>
              <LanguageSelector
                selectedLanguage={selectedLanguage}
                handleLanguageChange={handleLanguageChange}
              />
            </>
          )}
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={isExpirationSet}
              onChange={toggleExpiration}
              size={isSmallScreen ? 'small' : 'medium'}
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('pages.formPage.setExpirationCheckbox')}
            </Typography>
          }
        />

        <Box>
          {isExpirationSet && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('pages.formPage.setExpiration')}
              </Typography>
              <DurationSelector
                days={days}
                setDays={setDays}
                hours={hours}
                setHours={setHours}
                minutes={minutes}
                setMinutes={setMinutes}
              />
            </>
          )}
        </Box>

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAnonymous}
                onChange={() => setIsAnonymous(!isAnonymous)}
                size={isSmallScreen ? 'small' : 'medium'}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('pages.formPage.notCreateAccountForTheUserAnonymous')}
              </Typography>
            }
          />
        </Box>

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={isMultipleSubmission}
                onChange={() => setIsMultipleSubmission(!isMultipleSubmission)}
                size={isSmallScreen ? 'small' : 'medium'}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('pages.formPage.allowMultipleSubmissionForTheForm')}
              </Typography>
            }
          />
        </Box>
      </Collapse>

      {/* Generate Link Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateLink}
        disabled={token !== null && !isNewShareableForm && !isUpdate}
        fullWidth
        sx={{ mt: 2, mb: 2, maxHeight: isSmallScreen ? 30 : {} }}
      >
        <Typography variant="subtitle2" sx={{ fontSize: isSmallScreen ? 12 : 15 }}>
          {isUpdate || isNewShareableForm
            ? t('buttons.confirm')
            : t('buttons.saveSettings')}
        </Typography>
      </Button>
    </Box>
  );
};

LinkGenerationOptions.propTypes = {
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  token: PropTypes.string,
  setToken: PropTypes.func,
  isNewShareableForm: PropTypes.bool,
  hideModal: PropTypes.func,
  fetchData: PropTypes.func,
  isUpdate: PropTypes.bool,
  existingData: PropTypes.object,
};

export default LinkGenerationOptions;
