import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextField, IconButton, InputAdornment } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from '../iconify';

PasswordTextInput.propTypes = {
  showPassword: PropTypes.bool,
  name: PropTypes.string,
  handleShowPassword: PropTypes.func,
  errors: PropTypes.object,
  register: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string,
  hidden: PropTypes.string,
};

export default function PasswordTextInput({
  showPassword,
  name = '',
  handleShowPassword,
  errors,
  register,
  validations,
  value,
  hidden,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  return (
    <TextField
      name={name}
      label={t(`pages.usersPage.form.${name}`)}
      autoComplete={name}
      sx={{
        visibility: hidden && 'hidden',
        width: {
          sm: '80%',
          md: '75vh',
        },
        maxWidth: '100%',
        '& .MuiInputBase-root': {
          fontSize: isSmallScreen ? '0.8rem' : '1rem',
        },
        '& .MuiFormLabel-root': {
          fontSize: isSmallScreen ? '0.8rem' : '1rem',
        },
        '& .MuiFormHelperText-root': {
          fontSize: isSmallScreen ? '0.675rem' : '0.8rem',
        },
      }}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => handleShowPassword(name)} edge="end">
              <Iconify
                icon={showPassword[name] ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                sx={{ width: isSmallScreen ? 16 : 20, height: isSmallScreen ? 16 : 20 }}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={!!errors[name]}
      helperText={errors[name]?.message || ''}
      value={value}
      {...register(name, validations)}
      size={isSmallScreen ? 'small' : 'medium'}
    />
  );
}
