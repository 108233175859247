import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';

// @mui
import {
  Card,
  Grid,
  Stack,
  Popper,
  MenuItem,
  Container,
  Typography,
  Pagination,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Scrollbar from 'src/components/scrollbar/Scrollbar';
import TableToolbar from 'src/components/ToolBars/TableToolbar';
import ActionButton from 'src/components/action-button/action-button';

import { useAuth } from '../../hooks/useAuth';
import Iconify from '../../components/iconify';
import { useToast } from '../../hooks/useToast';
import FormList from '../../sections/form/formList/FormList';
import { deleteForm, fetchForms } from '../../services/form.service';
import { fetchDepartments } from '../../services/department.service';
import { titleHeadingStyle, titleHeadingStyleSmole } from '../style';
import FormDetails from '../../sections/form/formDetails/FormDetails';
// ----------------------------------------------------------------------
import { getComparator, applySortFilter } from '../../utils/filterData';

const itemsPerPage = 8;
const order = 'asc';
const orderBy = 'name';

export default function FormsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showToast, hideToast } = useToast();
  const [page, setPage] = useState(0);
  const [openDetailedView, setOpenDetailedView] = useState(false);
  const [selectedForm, setSelectedForm] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [formData, setFormData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  // Popover states
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [filterBy, setFilterBy] = useState('title');
  const [searchBy, setSearchBy] = useState('byTitle');
  const [subFilterPopover, setSubFilterPopover] = useState({ department: null });

  // Filter methods
  const handleOpenFilter = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const { departmentId, role } = useAuth();
  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setPopoverAnchorEl(null);
  };
  const handleOpenSubFilter = (event, category) => {
    if (category === 'department') {
      setSubFilterPopover({ department: event.currentTarget });
    }
  };

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ department: null });
  };
  // Fetch forms
  const getForms = () => {
    setIsloading(true);
    fetchForms()
      .then((res) => {
        if (role.authority === 'ROLE_ADMIN') setFormData(res);
        else if (role.authority === 'ROLE_DEPARTMENTMANAGER')
          setFormData(res.filter((form) => form.departmentId === departmentId));
      })
      .catch(() =>
        showToast({
          message: t('errorMessages.couldntLoadForms'),
          severity: 'error',
          props: { hideToast },
        })
      )
      .finally(() => setIsloading(false));
  };

  // fetch departments
  const fetchDepartmentData = () => {
    fetchDepartments().then((res) => {
      setDepartmentData(res);
    });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    getForms();
    fetchDepartmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDetailedView]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const removeForm = (id) => {
    deleteForm(id)
      .then(() => {
        showToast({
          message: t('successMessages.successFormRemove'),
          severity: 'success',
          props: { hideToast },
        });
        const newFormList = formData.filter((form) => form.id !== id);
        setFormData(newFormList);
        setPage(0);
      })
      .catch(() => {
        showToast({
          message: t('errorMessages.couldntRemoveForm'),
          severity: 'error',
          props: { hideToast },
        });
      });
  };

  const filteredForms = useMemo(
    () => applySortFilter(formData, getComparator(order, orderBy), filterName, filterBy),
    [filterBy, filterName, formData]
  );

  const { totalPages, isNotFound, searchIsNotFound, paginatedForms } = useMemo(() => {
    const totalPages = Math.ceil(filteredForms.length / itemsPerPage);
    const isNotFound = !filteredForms.length;
    const searchIsNotFound = !filteredForms.length && !!filterName;
    const paginatedForms = filteredForms.slice(
      page * itemsPerPage,
      page * itemsPerPage + itemsPerPage
    );
    return { totalPages, isNotFound, searchIsNotFound, paginatedForms };
  }, [filteredForms, page, filterName]);

  return (
    <>
      <Helmet>
        <title> {t('nav.forms')}</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={isSmallScreen ? 2 : 5}
        >
          <Typography
            style={isSmallScreen ? titleHeadingStyleSmole : titleHeadingStyle}
            mx={1}
          >
            {t('nav.forms')}
          </Typography>
          <Grid>
            <ActionButton
              id="create-new-form-btn"
              onClick={() => navigate('/dashboard/NewForm')}
              icon={isSmallScreen ? 'octicon:plus-circle-16' : 'eva:plus-fill'}
              text={t('buttons.newForm')}
              title={t('buttons.newForm')}
            />
          </Grid>
        </Stack>
        <TableToolbar
          numSelected={0}
          filterName={filterName.toString()}
          setFilterName={setFilterName}
          filterBy={searchBy}
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          onFilterName={handleFilterByName}
          model={t('nav.forms')}
        />
        <FormDetails
          key={selectedForm.id}
          setOpen={setOpenDetailedView}
          open={openDetailedView}
          formId={selectedForm.id}
          initialData={selectedForm.formData}
          setSelectedForm={setSelectedForm}
        />

        <FormList
          forms={paginatedForms}
          removeForm={removeForm}
          setOpenDetailedView={setOpenDetailedView}
          setSelectedForm={setSelectedForm}
          isLoading={isLoading}
          isNotFound={isNotFound}
          searchIsNotFound={searchIsNotFound}
          filterName={filterName}
        />
        {/* main filter popover */}
        <Popper
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          placement="left-start"
          onClose={handleCloseFilter}
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            <MenuItem
              selected={filterBy === 'title'}
              onMouseEnter={handleCloseSubFilter}
              onClick={() => {
                setFilterName('');
                setFilterBy('title');
                setSearchBy('byTitle');
                handleCloseFilter();
                handleCloseSubFilter();
              }}
              onTouchStart={() => {
                setFilterName('');
                setFilterBy('title');
                setSearchBy('byTitle');
                handleCloseFilter();
                handleCloseSubFilter();
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: isSmallScreen && '0.7rem',
                }}
              >
                {t('filters.byTitle')}
              </Typography>
            </MenuItem>
            <MenuItem
              selected={filterBy === 'id'}
              onMouseEnter={handleCloseSubFilter}
              onClick={() => {
                setFilterName('');
                setFilterBy('id');
                setSearchBy('byId');
                handleCloseFilter();
                handleCloseSubFilter();
              }}
              onTouchStart={() => {
                setFilterName('');
                setFilterBy('id');
                setSearchBy('byId');
                handleCloseFilter();
                handleCloseSubFilter();
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: isSmallScreen && '0.7rem',
                }}
              >
                {t('filters.byId')}
              </Typography>
            </MenuItem>
            {role.authority !== 'ROLE_DEPARTMENTMANAGER' && (
              <MenuItem
                selected={filterBy === 'department'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'department')}
                onTouchStart={(e) => handleOpenSubFilter(e, 'department')}
                onClick={(e) => handleOpenSubFilter(e, 'department')}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify
                    icon="eva:arrow-ios-back-outline"
                    sx={{
                      mr: 1,
                      width: isSmallScreen ? 16 : 20,
                      height: isSmallScreen ? 16 : 20,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: isSmallScreen && '0.7rem',
                    }}
                  >
                    {t('filters.byDepartment')}
                  </Typography>
                </Stack>
              </MenuItem>
            )}
          </Card>
        </Popper>
        {/* sub filter popover */}

        <Popper
          open={Boolean(subFilterPopover.department)}
          anchorEl={subFilterPopover.department}
          placement="left-start"
          onClose={handleCloseSubFilter}
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            <Scrollbar sx={{ maxHeight: 200, overflowY: 'auto' }}>
              {departmentData.map((department) => (
                <MenuItem
                  key={department.id}
                  onClick={() => {
                    setFilterBy('departmentId');
                    setSearchBy('byDepartment');
                    setFilterName(department.id);
                    handleCloseFilter();
                    handleCloseSubFilter();
                    setPage(0);
                  }}
                  onTouchStart={() => {
                    setFilterBy('departmentId');
                    setSearchBy('byDepartment');
                    setFilterName(department.id);
                    handleCloseFilter();
                    handleCloseSubFilter();
                    setPage(0);
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: isSmallScreen && '0.7rem',
                    }}
                  >
                    {department.name}
                  </Typography>
                </MenuItem>
              ))}
            </Scrollbar>
          </Card>
        </Popper>
        {filteredForms.length > 0 && (
          <Stack alignItems="center" sx={{ mt: 5 }}>
            <Pagination
              component="div"
              color="primary"
              count={totalPages}
              size={isSmallScreen ? 'medium' : 'large'}
              page={page + 1}
              onChange={handleChangePage}
              sx={{
                '& .MuiPaginationItem-root': {
                  fontSize: isSmallScreen && '0.75rem',
                },
              }}
            />
          </Stack>
        )}
      </Container>
    </>
  );
}
