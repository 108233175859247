import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { Grid, Button, TextField, Typography, Autocomplete } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

export default function AdvancedFilter({ ticketsWithStatus, setFilterByData }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const [localFilters, setLocalFilters] = useState({
    department: null,
    priority: null,
    status: null,
    category: null,
    creator: null,
  });

  const [appliedFilters, setAppliedFilters] = useState({
    department: null,
    priority: null,
    status: null,
    category: null,
    creator: null,
  });

  // Memoizing Calculations
  const uniqueDepartments = useMemo(
    () => [...new Set(ticketsWithStatus.map((ticket) => ticket.department))],
    [ticketsWithStatus]
  );
  const uniquePriorities = useMemo(
    () => [...new Set(ticketsWithStatus.map((ticket) => ticket.priority))],
    [ticketsWithStatus]
  );
  const uniqueCategories = useMemo(
    () => [...new Set(ticketsWithStatus.map((ticket) => ticket.category || ''))],
    [ticketsWithStatus]
  );
  const uniqueCreators = useMemo(
    () => [...new Set(ticketsWithStatus.map((ticket) => ticket.customerName))],
    [ticketsWithStatus]
  );

  const filteredUniqueStats = useMemo(() => {
    const uniqueStats = new Set();
    const filteredStats = [];
    ticketsWithStatus.forEach((ticket) => {
      // Create Map of unique status values paired with their ids
      const label =
        ticket.status_id < 6
          ? t(`status.${ticket.statusObject?.name}`)
          : ticket.statusObject?.name;

      // Determine department based on status
      const department = ticket.status_id < 6 ? 'Global' : ticket.department;

      const value = ticket.status_id;

      // Combine status and department for label
      const combinedLabel = `${label} - ${department}`;

      // Remove duplicate status values
      if (!uniqueStats.has(combinedLabel)) {
        uniqueStats.add(combinedLabel);
        filteredStats.push({ label: combinedLabel, value });
      }
    });
    return filteredStats;
  }, [ticketsWithStatus, t]);

  const applyFilter = () => {
    setAppliedFilters(localFilters);
  };

  useEffect(() => {
    const { department, priority, status, category, creator } = appliedFilters;
    if (!department && !priority && !status && !category && !creator) {
      setFilterByData(ticketsWithStatus);
      return;
    }

    const filtered = ticketsWithStatus.filter(
      (ticket) =>
        !(
          (department && ticket.department !== department) ||
          (priority && ticket.priority !== priority) ||
          (status && ticket.status_id !== status.value) ||
          (category && category !== 'No Options' && ticket.category !== category) ||
          (creator && ticket.customerName !== creator)
        )
    );
    setFilterByData(filtered);
  }, [appliedFilters, ticketsWithStatus, setFilterByData]);

  const resetFilter = () => {
    setLocalFilters({
      department: null,
      priority: null,
      status: null,
      category: null,
      creator: null,
    });
    setAppliedFilters({
      department: null,
      priority: null,
      status: null,
      category: null,
      creator: null,
    });
    setFilterByData(ticketsWithStatus);
  };

  return (
    <div style={{ margin: '0 20px', marginBottom: '-30px' }}>
      <Typography
        variant="h5"
        sx={{
          my: 2,
          fontSize: isSmallScreen && '0.8rem',
        }}
      >
        {t('buttons.applyFilter')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle2"
            style={{ marginBottom: '12px', fontSize: isSmallScreen && '0.7rem' }}
          >
            {t('attributes.department')}
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-department"
            options={uniqueDepartments}
            value={localFilters.department}
            onChange={(event, newValue) =>
              setLocalFilters((prev) => ({ ...prev, department: newValue }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('filterFormControl.departmentFilter')}
                size="small"
                sx={{
                  fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  '& .MuiInputLabel-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiListItem-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                }}
              />
            )}
            ListboxProps={{
              style: {
                fontSize: isSmallScreen && '0.75rem',
                maxHeight: '200px',
                overflowY: 'auto',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle2"
            style={{ marginBottom: '12px', fontSize: isSmallScreen && '0.7rem' }}
          >
            {t('attributes.priority')}
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-priority"
            options={uniquePriorities}
            value={localFilters.priority}
            onChange={(event, newValue) =>
              setLocalFilters((prev) => ({ ...prev, priority: newValue }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('filterFormControl.priorityFilter')}
                size="small"
                sx={{
                  fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  '& .MuiInputLabel-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiListItem-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                }}
              />
            )}
            ListboxProps={{
              style: {
                fontSize: isSmallScreen && '0.75rem',
                maxHeight: '200px',
                overflowY: 'auto',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle2"
            style={{ marginBottom: '12px', fontSize: isSmallScreen && '0.7rem' }}
          >
            {t('attributes.status')}
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-status"
            options={filteredUniqueStats}
            value={localFilters.status}
            onChange={(event, newValue) =>
              setLocalFilters((prev) => ({ ...prev, status: newValue }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('filterFormControl.statusFilter')}
                size="small"
                sx={{
                  fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  '& .MuiInputLabel-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiListItem-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                }}
              />
            )}
            ListboxProps={{
              style: {
                fontSize: isSmallScreen && '0.75rem',
                maxHeight: '200px',
                overflowY: 'auto',
              },
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.label} // Display the combined label
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle2"
            style={{ marginBottom: '12px', fontSize: isSmallScreen && '0.7rem' }}
          >
            {t('attributes.category')}
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-category"
            options={uniqueCategories}
            value={localFilters.category}
            onChange={(event, newValue) =>
              setLocalFilters((prev) => ({ ...prev, category: newValue }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('filterFormControl.categoryFilter')}
                size="small"
                sx={{
                  fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  '& .MuiInputLabel-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiListItem-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                }}
              />
            )}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: isSmallScreen ? '0.75rem' : '1rem',
              },
            }}
            ListboxProps={{
              style: {
                fontSize: isSmallScreen && '0.75rem',
                maxHeight: '200px',
                overflowY: 'auto',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle2"
            style={{ marginBottom: '12px', fontSize: isSmallScreen && '0.7rem' }}
          >
            {t('attributes.createdBy')}
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-created-by"
            options={uniqueCreators}
            value={localFilters.creator}
            onChange={(event, newValue) =>
              setLocalFilters((prev) => ({ ...prev, creator: newValue }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('filterFormControl.creatorFilter')}
                size="small"
                sx={{
                  fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  '& .MuiInputLabel-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                  '& .MuiListItem-root': {
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem',
                  },
                }}
              />
            )}
            ListboxProps={{
              style: {
                fontSize: isSmallScreen && '0.75rem',
                maxHeight: '200px',
                overflowY: 'auto',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={applyFilter}
            size={isSmallScreen ? 'small' : 'medium'}
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            {t('buttons.applyFilter')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={resetFilter}
            size={isSmallScreen ? 'small' : 'medium'}
            sx={{
              fontSize: isSmallScreen && '0.7rem',
              marginLeft: '10px',
            }}
          >
            {t('buttons.resetFilter')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
AdvancedFilter.propTypes = {
  ticketsWithStatus: PropTypes.array.isRequired, // Ensure ticketsWithStatus is an array and required
  setFilterByData: PropTypes.func.isRequired, // Ensure setFilterByData is a function and required
};
