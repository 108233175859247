import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';

import {
  List,
  Card,
  Stack,
  Avatar,
  Button,
  ListItem,
  TextField,
  Typography,
  ListItemAvatar,
  InputAdornment,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import {
  cardTicketModalStyle,
  listTicketModalStyle,
  listItemTicketModalStyle,
} from 'src/sections/ticketDetails/styles';

import Label from '../../../components/label';
import { useConfirmModal } from '../../../hooks/useModal';
import { convertLocalTime } from '../../../utils/convertTime';
import { getComparator, applySortFilter } from '../../../utils/filterData';

DelegateTicketModal.propTypes = {
  delegateTicket: PropTypes.func,
  usersList: PropTypes.array,
  department: PropTypes.string,
  ticketData: PropTypes.object,
  isDelegate: PropTypes.bool,
};

const order = 'asc';
const orderBy = 'name';

export default function DelegateTicketModal({
  delegateTicket,
  usersList,
  department,
  ticketData,
  isDelegate,
}) {
  const [selectedAgent, setSelectedAgent] = useState({
    id: 0,
    username: '',
  });
  const [filterName, setFilterName] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const filteredUsers = useMemo(() => {
    const filteredData = applySortFilter(
      usersList,
      getComparator(order, orderBy),
      filterName,
      'username'
    );
    setIsNotFound(!filteredData.length && !!filterName);
    return filteredData;
  }, [filterName, usersList]);

  const { showConfirmModal } = useConfirmModal(
    isDelegate
      ? t('pages.delegateTicket.confirmDelegation')
      : t('pages.delegateTicket.confirmReassign'),
    isDelegate
      ? t('pages.delegateTicket.areYouSureDelegate', {
          agentName: selectedAgent.username,
        })
      : t('pages.delegateTicket.areYouSureReassign', {
          agentName: selectedAgent.username,
        }),
    async () => {
      const delegatedTicketData = { ...ticketData, assignedTo: selectedAgent.id };
      delegateTicket(delegatedTicketData);
    }
  );

  return (
    <Stack px={2} spacing={2} my={2}>
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: isSmallScreen ? '0.775rem' : '1rem',
        }}
      >
        {t('pages.assignTicket.availableAgents')} {department} :
      </Typography>
      <TextField
        value={filterName}
        onChange={handleFilterByName}
        placeholder={`${t('filters.search')} agent...`}
        size={isSmallScreen ? 'small' : 'medium'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{
                  color: 'text.disabled',
                  width: isSmallScreen ? 16 : 20,
                  height: isSmallScreen ? 16 : 20,
                }}
              />
            </InputAdornment>
          ),
        }}
        sx={{
          '& input': {
            fontSize: isSmallScreen ? '0.775rem' : '1rem',
          },
        }}
      />
      <Card
        sx={{
          ...cardTicketModalStyle,
          height: 'auto',
          minHeight: isSmallScreen ? '50px' : '100px',
          maxHeight: '350px',
        }}
      >
        <List sx={listTicketModalStyle}>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => {
              const { id, username, email, phoneNumber, avatar, available, availableOn } =
                user;

              return (
                <ListItem
                  divider
                  key={id}
                  onClick={() => {
                    setSelectedAgent(user);
                  }}
                  sx={listItemTicketModalStyle(selectedAgent, user)}
                  alignItems="flex-start"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Stack direction="row">
                      <ListItemAvatar>
                        <Avatar
                          alt=""
                          src={avatar}
                          sx={{
                            width: isSmallScreen ? 30 : 40,
                            height: isSmallScreen ? 30 : 40,
                          }}
                        />
                      </ListItemAvatar>

                      <Stack spacing={0.5}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: isSmallScreen ? '0.775rem' : '1rem',
                          }}
                        >
                          <b>{username}</b>
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: isSmallScreen ? '0.675rem' : '1rem',
                          }}
                        >
                          <b>{t('attributes.email')}: </b>
                          {email}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: isSmallScreen ? '0.675rem' : '1rem',
                          }}
                        >
                          <b>{t('attributes.phoneNumber')}: </b>
                          {phoneNumber}
                        </Typography>
                        {!available && (
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: isSmallScreen ? '0.675rem' : '1rem',
                            }}
                          >
                            <b>{t('attributes.availableOn')}: </b>
                            {convertLocalTime(new Date(availableOn)).toLocaleString(
                              ('en-GB',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                            )}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <Label color={(available && 'success') || 'error'}>
                      {available ? (
                        isSmallScreen ? (
                          <Iconify
                            icon="eva:checkmark-circle-fill"
                            color="success"
                            sx={{ width: 20, height: 20 }}
                          />
                        ) : (
                          t('attributes.available')
                        )
                      ) : isSmallScreen ? (
                        <Iconify
                          icon="eva:close-circle-fill"
                          color="error"
                          sx={{ width: 20, height: 20 }}
                        />
                      ) : (
                        t('attributes.unavailable')
                      )}
                    </Label>
                  </Stack>
                </ListItem>
              );
            })
          ) : (
            <Typography
              textAlign="center"
              variant="subtitle1"
              sx={{
                fontSize: isSmallScreen ? '0.675rem' : '1rem',
              }}
            >
              {isNotFound
                ? t('pages.assignTicket.noAgentsFound')
                : t('pages.assignTicket.noAgentsAvailable')}
            </Typography>
          )}
        </List>
      </Card>
      <Button
        variant="outlined"
        onClick={selectedAgent.id !== 0 ? showConfirmModal : null}
        disabled={selectedAgent.id === 0}
        sx={{
          fontSize: isSmallScreen ? '0.675rem' : '1rem',
        }}
      >
        {isDelegate
          ? t('pages.delegateTicket.delegateTicket')
          : t('buttons.reassignTicket')}
      </Button>
    </Stack>
  );
}
