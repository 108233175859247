export const scrollbarStyle = {
  maxHeight: 200,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(136, 136, 136, 0.5)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(85, 85, 85, 0.6)',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(240, 240, 240, 0.7)',
  },
};
