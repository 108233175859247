import { useState } from 'react';
import PropTypes from 'prop-types';

import { Popover, SwipeableDrawer } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import FormTabs from './sections/FormTabs';
import FormShare from './sections/FormShare';

FormDetails.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  initialData: PropTypes.object,
  formId: PropTypes.number,
  setSelectedForm: PropTypes.func,
};

export default function FormDetails({
  setOpen,
  open,
  formId,
  initialData,
  setSelectedForm,
}) {
  const [value, setValue] = useState('0');
  const [popoverTabValue, setPopoverTabValue] = useState('0');
  const [anchorEl, setAnchorEl] = useState(null);
  const { isSmallScreen } = useResponsiveScreen();

  const [token, setToken] = useState(null);
  const handleClose = () => {
    setOpen(false);
    setSelectedForm(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverTabChange = (event, newValue) => {
    setPopoverTabValue(newValue);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const handleRemoveToken = () => {
    setToken(null);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      onOpen={handleOpen}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { width: isSmallScreen ? '80%' : '60%' },
      }}
    >
      <FormTabs
        value={value}
        handleChange={handleChange}
        formId={formId}
        handleClose={handleClose}
        handlePopoverOpen={handlePopoverOpen}
        initialData={initialData}
      />

      {/* Popover for Send Options */}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: isSmallScreen ? '80%' : 'auto',
          },
        }}
        sx={{ boxShadow: 20 }}
      >
        <FormShare
          formId={formId}
          handlePopoverClose={handlePopoverClose}
          handlePopoverTabChange={handlePopoverTabChange}
          handleRemoveToken={handleRemoveToken}
          popoverTabValue={popoverTabValue}
          setToken={setToken}
          token={token}
        />
      </Popover>
    </SwipeableDrawer>
  );
}
