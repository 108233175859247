import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Paper, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

EmptyGrid.propTypes = {
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
};

export default function EmptyGrid({ searchIsNotFound, filterName }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Paper
      sx={{
        width: '100%',
        py: isSmallScreen ? 3 : 5,
        px: isSmallScreen ? 2 : 5,
        my: isSmallScreen ? 3 : 5,
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h6"
        paragraph
        sx={{
          fontSize: isSmallScreen && '0.7rem',
        }}
      >
        {t('filters.notFound')}
      </Typography>
      {searchIsNotFound && (
        <Typography
          variant="body2"
          sx={{
            fontSize: isSmallScreen && '0.65rem',
          }}
        >
          {t('filters.noResultsFound', { filterName })}
        </Typography>
      )}
    </Paper>
  );
}
