import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/system';
import { Radio, Button, RadioGroup, FormControlLabel } from '@mui/material';

import { clearButtonSelection } from './style';

SelectInput.propTypes = {
  defaultValue: PropTypes.node,
  labelValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedInput: PropTypes.func,
  removeSection: PropTypes.func,
  isRequired: PropTypes.bool,
  setHasSelectedSection: PropTypes.func,
  input: PropTypes.object,
};

export default function SelectInput({
  defaultValue,
  options,
  setSelectedInput,
  isRequired,
  setHasSelectedSection,
  input,
  ...other
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue || '');
  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedInput(event);
  };

  useEffect(() => {
    if (value && setHasSelectedSection && input?.redirectOnAnswer) {
      setHasSelectedSection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClearSelection = () => {
    if (!isRequired) {
      setValue(null);
      setSelectedInput({ target: { value: null } });
      if (setHasSelectedSection && input?.redirectOnAnswer) {
        setHasSelectedSection(false);
      }
    }
  };

  return (
    <>
      <RadioGroup {...other} value={value} onChange={handleChange}>
        {options?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.text}
          />
        ))}
      </RadioGroup>

      {/* Render the Clear Selection button if not required */}
      {!isRequired && value && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button
            onClick={handleClearSelection}
            variant="outlined"
            sx={clearButtonSelection}
          >
            {t('pages.formPage.clearSelection')}
          </Button>
        </Box>
      )}
    </>
  );
}
