import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

// @mui
import { Box, Card, CardHeader } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

// components
import { useChart } from '../chart';

// ----------------------------------------------------------------------

LineChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function LineChart({
  title,
  subheader,
  chartLabels,
  chartData,
  ...other
}) {
  const { isSmallScreen } = useResponsiveScreen();
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      type: 'number',
      labels: {
        style: {
          fontSize: isSmallScreen ? '0.675rem' : '0.8rem',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: isSmallScreen ? '0.675rem' : '0.8rem',
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: isSmallScreen ? '0.675rem' : '0.8rem',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} tickets`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        sx={{
          '& .MuiCardHeader-title': { fontSize: isSmallScreen && '0.8rem' },
          '& .MuiCardHeader-subheader': { fontSize: isSmallScreen && '0.7rem' },
        }}
      />

      <Box sx={{ p: 3, pb: isSmallScreen ? 0 : 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
