import axios from 'axios';

import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/files/`;

// get all files
export const getAllFiles = async () => {
  const result = await axios.get(`${url}files`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// upload a file

export const uploadFile = async (formData) => {
  const result = await axios.post(`${url}upload`, formData, {
    headers: { ...getAuthorizationHeader(), 'Content-Type': 'multipart/form-data' },
  });
  return result.data;
};

// get file by name

export const getFileByName = async (fileName, ticketId) => {
  const res = await axios.get(
    `${url}files/file?filename=${fileName}${ticketId ? `&ticketId=${ticketId}` : ''}`,
    {
      headers: { ...getAuthorizationHeader(), 'Content-Type': 'multipart/form-data' },
      responseType: 'blob',
    }
  );
  return res.data;
};
