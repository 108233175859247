import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, InputLabel } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

ImageInput.propTypes = {
  handleChange: PropTypes.func,
  existingFile: PropTypes.string,
  selectedFile: PropTypes.object,
  defaultImage: PropTypes.string,
};
function ImageInput({ handleChange, selectedFile, existingFile, defaultImage }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <div>
      <img
        src={
          !selectedFile && !existingFile
            ? defaultImage
            : (selectedFile && URL.createObjectURL(selectedFile)) || existingFile
        }
        alt="Selected"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '100%',
          maxHeight: '200px',
          marginTop: '10px',
          marginBottom: '20px',
        }}
      />

      <InputLabel>
        <Button
          fullWidth
          component="span"
          variant="contained"
          sx={{
            fontSize: isSmallScreen ? '0.65rem' : '1rem',
          }}
        >
          {t('buttons.chooseImage')}
        </Button>

        <input
          hidden
          accept="image/*"
          style={{ display: 'none' }}
          id="image-file-input"
          type="file"
          onChange={handleChange}
        />
      </InputLabel>
    </div>
  );
}

export default ImageInput;
