import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Stack, Popper, MenuItem, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';

import { TABLE_HEAD } from '../table/statusTable';

FilterPopover.propTypes = {
  setFilterBy: PropTypes.func,
  setFilterTitle: PropTypes.func,
  setSearchBy: PropTypes.func,
  setPage: PropTypes.func,
  departmentData: PropTypes.array,
  handleCloseFilter: PropTypes.func,
  filterPopover: PropTypes.object,
  setSubFilterPopover: PropTypes.func,
  subFilterPopover: PropTypes.object,
  handleCloseSubFilter: PropTypes.func,
};

export default function FilterPopover({
  setSubFilterPopover,
  subFilterPopover,
  handleCloseSubFilter,
  filterPopover,
  handleCloseFilter,
  setPage,
  setFilterTitle,
  setFilterBy,
  setSearchBy,
  departmentData,
}) {
  const [selectedFilter, setSelectedFilter] = useState('name');
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { role } = useAuth();

  const handleOpenSubFilter = (event, category) => {
    if (category === 'departement') {
      setSubFilterPopover({ department: event.currentTarget });
    } else if (category === 'systemState') {
      setSubFilterPopover({ systemState: event.currentTarget });
    }
  };

  return (
    <>
      <Popper
        open={Boolean(filterPopover)}
        anchorEl={filterPopover}
        placement="left-start"
        onClose={handleCloseFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {TABLE_HEAD.filter(({ id }) => id !== 'options').map(({ id, name }) =>
            name === 'systemState' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'systemState'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'systemState')}
                onTouchStart={(e) => handleOpenSubFilter(e, 'systemState')}
                onClick={(e) => handleOpenSubFilter(e, 'systemState')}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify
                    icon="eva:arrow-ios-back-outline"
                    sx={{
                      mr: 1,
                      width: isSmallScreen ? 16 : 20,
                      height: isSmallScreen ? 16 : 20,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: isSmallScreen && '0.7rem',
                    }}
                  >
                    {t('filters.byRelatedSystemStatus')}
                  </Typography>
                </Stack>
              </MenuItem>
            ) : name === 'departement' && role.authority === 'ROLE_ADMIN' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'departement'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'departement')}
                onTouchStart={(e) => handleOpenSubFilter(e, 'departement')}
                onClick={(e) => handleOpenSubFilter(e, 'departement')}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify
                    icon="eva:arrow-ios-back-outline"
                    sx={{
                      mr: 1,
                      width: isSmallScreen ? 16 : 20,
                      height: isSmallScreen ? 16 : 20,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: isSmallScreen && '0.7rem',
                    }}
                  >
                    {t('filters.byDepartment')}
                  </Typography>
                </Stack>
              </MenuItem>
            ) : [
                'createdAt',
                'updatedAt',
                'description',
                'color',
                'correspondances',
              ].includes(name) ||
              (name === 'departement' && role.authority !== 'ROLE_ADMIN') ? null : (
              <MenuItem
                key={id}
                selected={selectedFilter === name}
                onClick={() => {
                  setFilterTitle('');
                  setFilterBy(name);
                  setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                  setSelectedFilter(name);
                  handleCloseFilter();
                }}
                onTouchStart={() => {
                  setFilterTitle('');
                  setFilterBy(name);
                  setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                  setSelectedFilter(name);
                  handleCloseFilter();
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {t(`filters.by${name && name.charAt(0).toUpperCase() + name.slice(1)}`)}
                </Typography>
              </MenuItem>
            )
          )}
        </Card>
      </Popper>

      {role.authority === 'ROLE_ADMIN' && (
        <Popper
          open={Boolean(subFilterPopover.department)}
          anchorEl={subFilterPopover.department}
          placement="left-start"
          onClose={handleCloseSubFilter}
        >
          <Card
            sx={{
              p: 1,
              minwidth: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                boxShadow: '0.5px 0.5px 2 px',
              },
            }}
          >
            <Scrollbar sx={{ maxHeight: 200, overflowY: 'auto' }}>
              <MenuItem
                onClick={() => {
                  setFilterBy('department');
                  setSearchBy('byDepartment');
                  setSelectedFilter('department');
                  setFilterTitle('');
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterBy('department');
                  setSearchBy('byDepartment');
                  setSelectedFilter('department');
                  setFilterTitle('');
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {t('pages.statusPage.allDepartments')}
                </Typography>
              </MenuItem>

              {departmentData?.map((department) => (
                <MenuItem
                  key={department.id}
                  onClick={() => {
                    setFilterBy('department');
                    setSearchBy('byDepartment');
                    setSelectedFilter('department');
                    setFilterTitle(department.name);
                    handleCloseFilter();
                    handleCloseSubFilter();
                    setPage(0);
                  }}
                  onTouchStart={() => {
                    setFilterBy('department');
                    setSearchBy('byDepartment');
                    setSelectedFilter('department');
                    setFilterTitle(department.name);
                    handleCloseFilter();
                    handleCloseSubFilter();
                    setPage(0);
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: isSmallScreen && '0.7rem',
                    }}
                  >
                    {department.name}
                  </Typography>
                </MenuItem>
              ))}
            </Scrollbar>
          </Card>
        </Popper>
      )}

      <Popper
        open={Boolean(subFilterPopover.systemState)}
        anchorEl={subFilterPopover.systemState}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          <Scrollbar sx={{ maxHeight: 200, overflowY: 'auto' }}>
            {[
              { value: 'ONHOLD', display: t('status.ONHOLD') },
              { value: 'UNASSIGNED', display: t('status.UNASSIGNED') },
              { value: 'OPEN', display: t('status.OPEN') },
              { value: 'RESOLVED', display: t('status.RESOLVED') },
              { value: 'CLOSED', display: t('status.CLOSED') },
            ].map((systemState, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilterBy('systemMappedStatus');
                  setSearchBy('byRelatedSystemStatus');
                  setSelectedFilter('systemMappedStatus');
                  setFilterTitle(systemState.value);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
                onTouchStart={() => {
                  setFilterBy('systemMappedStatus');
                  setSearchBy('byRelatedSystemStatus');
                  setSelectedFilter('systemMappedStatus');
                  setFilterTitle(systemState.value);
                  handleCloseFilter();
                  handleCloseSubFilter();
                  setPage(0);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isSmallScreen && '0.7rem',
                  }}
                >
                  {systemState.display}
                </Typography>
              </MenuItem>
            ))}
          </Scrollbar>
        </Card>
      </Popper>
    </>
  );
}
