import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Select,
  Dialog,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  FormControl,
  DialogTitle,
  DialogContent,
  FormHelperText,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { phoneNumberSplit } from 'src/utils/phoneNumberSplit';

import Iconify from 'src/components/iconify';

import { scrollbarStyle } from 'src/sections/style';

import countries from '../../../locale/phoneCode';
import { EMAIL_REGEX, PHONENUMBER_REGEX } from '../../../utils/regex';

ModifyUserModal.propTypes = {
  row: PropTypes.object,
  setOpen: PropTypes.func,
  modifyUser: PropTypes.func,
  open: PropTypes.bool,
  currentRole: PropTypes.string,
  departmentsData: PropTypes.array,
};

export default function ModifyUserModal({
  row,
  setOpen,
  modifyUser,
  open,
  currentRole,
  departmentsData,
}) {
  const { i18n, t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  useEffect(() => {
    countries.sort((a, b) => a[i18n.language].localeCompare(b[i18n.language]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: row.id,
      givenName: row.givenName,
      familyName: row.familyName,
      countryCode: phoneNumberSplit(row?.phoneNumber).partOne,
      phoneNumber: phoneNumberSplit(row?.phoneNumber).partTwo,
      appUserRole: row.appUserRole,
      departmentId: row.departmentId,
      departmentName: row.departmentName,
      email: row.email,
    },
  });

  useMemo(() => {
    if (row) {
      reset({
        id: row.id || '',
        givenName: row.givenName || '',
        familyName: row.familyName || '',
        countryCode: phoneNumberSplit(row?.phoneNumber)?.partOne || '',
        phoneNumber: phoneNumberSplit(row?.phoneNumber)?.partTwo || '',
        appUserRole: row.appUserRole || '',
        departmentId: row.departmentId || '',
        departmentName: row.departmentName || '',
        email: row.email || '',
      });
    }
  }, [row, reset]);

  const selectedRole = watch('appUserRole');
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data) => {
    // Concatenate the country code with the phone number
    data.phoneNumber = `(${data.countryCode}) ${data.phoneNumber}`;

    if (
      data.appUserRole === 'ROLE_AGENT' ||
      data.appUserRole === 'ROLE_DEPARTMENTMANAGER'
    ) {
      const selectedDepartmentObject = departmentsData.find(
        (department) => department.id === data.departmentId
      );
      data.departmentName = selectedDepartmentObject.name;
    }

    modifyUser(data);
    handleClose();
  };

  useMemo(() => {
    const defaultValues = {
      id: row.id,
      givenName: row.givenName,
      familyName: row.familyName,
      countryCode: phoneNumberSplit(row?.phoneNumber).partOne,
      phoneNumber: phoneNumberSplit(row?.phoneNumber).partTwo,
      appUserRole: row.appUserRole,
      departmentId: row.departmentId,
      departmentName: row.departmentName,
      email: row.email,
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="modal--title" variant="h6" component="h2">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontSize: isSmallScreen ? '0.85rem' : '1rem',
              }}
            >
              {t('pages.usersPage.form.modifyUser')}
            </Typography>
            <IconButton onClick={() => handleClose()} sx={{ mr: -1.5 }}>
              <Iconify
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    color: '#B72136',
                  },
                }}
                width={isSmallScreen ? 25 : 30}
                icon="eva:close-square-fill"
              />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mt={2}>
              <TextField
                name="givenName"
                label={t('attributes.name')}
                error={!!errors.givenName}
                helperText={errors.givenName?.message || ''}
                {...register('givenName', {
                  required: t('formControl.enterName'),
                  minLength: {
                    value: 3,
                    message: t('formControl.minName'),
                  },
                })}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: isSmallScreen ? '0.675rem' : '1rem',
                  },
                }}
              />
              <TextField
                name="familyName"
                label={t('attributes.lastName')}
                error={!!errors.familyName}
                helperText={errors.familyName?.message || ''}
                {...register('familyName', {
                  required: t('formControl.enterLasName'),
                  minLength: {
                    value: 3,
                    message: t('formControl.minLastName'),
                  },
                })}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: isSmallScreen ? '0.675rem' : '1rem',
                  },
                }}
              />
              <Stack direction="row" spacing={1}>
                <FormControl sx={{ width: isSmallScreen ? '40%' : '20%' }}>
                  <InputLabel
                    id="country-code-label"
                    sx={{
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                  >
                    {t('attributes.country')}
                  </InputLabel>
                  <Select
                    labelId="country-code-label"
                    label={t('attributes.country')}
                    id="countryCode"
                    name="countryCode"
                    defaultValue={phoneNumberSplit(row?.phoneNumber).partOne}
                    value={watch('countryCode') || '+39'}
                    renderValue={(countryCode) => `(${countryCode})`}
                    {...register('countryCode', {
                      required: t('formControl.selectCountryCode'),
                    })}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          ...scrollbarStyle,
                        },
                      },
                    }}
                    sx={{
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                    size={isSmallScreen ? 'small' : 'medium'}
                  >
                    {countries.map((country, index) => (
                      <MenuItem
                        key={index}
                        value={country.code}
                        sx={{
                          fontSize: isSmallScreen ? '0.8rem' : '1rem',
                        }}
                      >
                        {`${country[i18n.language]}  (${country.code})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  name="phoneNumber"
                  id="user-phone-input"
                  type="number"
                  label={t('attributes.phoneNumber')}
                  autoComplete="tel-national"
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message || ''}
                  {...register('phoneNumber', {
                    required: t('formControl.enterPhoneNumber'),
                    pattern: {
                      value: PHONENUMBER_REGEX,
                      message: t('formControl.invalidPhoneNumber'),
                    },
                  })}
                  size={isSmallScreen ? 'small' : 'medium'}
                  sx={{
                    width: '80%',
                    '& .MuiInputBase-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: isSmallScreen ? '0.675rem' : '1rem',
                    },
                  }}
                />
              </Stack>
              {currentRole === 'ROLE_ADMIN' && (
                <FormControl fullWidth error={!!errors.appUserRole}>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                  >
                    {t('pages.profilePage.role')}
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="appUserRole"
                    value={watch('appUserRole')}
                    defaultValue={row.appUserRole}
                    label={t('pages.usersPage.form.role')}
                    {...register('appUserRole', {
                      required: t('formControl.selectRole'),
                    })}
                    size={isSmallScreen ? 'small' : 'medium'}
                    sx={{
                      fontSize: isSmallScreen ? '0.8rem' : '1rem',
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          ...scrollbarStyle,
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value="ROLE_CUSTOMER"
                      sx={{
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                    >
                      {t('roles.ROLE_CUSTOMER')}
                    </MenuItem>
                    <MenuItem
                      value="ROLE_AGENT"
                      sx={{
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                    >
                      {t('roles.ROLE_AGENT')}
                    </MenuItem>
                    <MenuItem
                      value="ROLE_ADMIN"
                      sx={{
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                    >
                      {t('roles.ROLE_ADMIN')}
                    </MenuItem>
                    <MenuItem
                      value="ROLE_DEPARTMENTMANAGER"
                      sx={{
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                    >
                      {t('roles.ROLE_DEPARTMENTMANAGER')}
                    </MenuItem>
                  </Select>
                  <FormHelperText
                    sx={{
                      fontSize: isSmallScreen ? '0.675rem' : '1rem',
                    }}
                  >
                    {errors.appUserRole?.message}
                  </FormHelperText>
                </FormControl>
              )}
              {selectedRole &&
                selectedRole !== 'ROLE_CUSTOMER' &&
                selectedRole !== 'ROLE_ADMIN' &&
                currentRole === 'ROLE_ADMIN' && (
                  <FormControl fullWidth error={!!errors.departmentId}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                    >
                      {t('attributes.department')}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={watch('departmentId')}
                      defaultValue={row?.departmentId || ''}
                      label="Department"
                      {...register('departmentId', {
                        required: t('formControl.selectDepartment'),
                      })}
                      size={isSmallScreen ? 'small' : 'medium'}
                      sx={{
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            ...scrollbarStyle,
                          },
                        },
                      }}
                    >
                      <MenuItem />
                      {departmentsData?.length > 0 ? (
                        departmentsData.map((department) => {
                          const { id, name } = department;
                          return (
                            <MenuItem
                              key={id}
                              value={id}
                              sx={{
                                fontSize: isSmallScreen ? '0.8rem' : '1rem',
                              }}
                            >
                              {name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          disabled
                          value=""
                          sx={{
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          }}
                        >
                          {t('infoMessages.noOptions')}
                        </MenuItem>
                      )}
                    </Select>

                    <FormHelperText
                      sx={{
                        fontSize: isSmallScreen ? '0.675rem' : '1rem',
                      }}
                    >
                      {errors.departmentId?.message}
                    </FormHelperText>
                  </FormControl>
                )}

              <TextField
                name="email"
                label={t('pages.usersPage.form.emailAdress')}
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email?.message || ''}
                {...register('email', {
                  required: t('formControl.enterEmail'),
                  pattern: {
                    value: EMAIL_REGEX,
                    message: t('formControl.invalidEmail'),
                  },
                })}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.8rem' : '1rem',
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: isSmallScreen ? '0.675rem' : '1rem',
                  },
                }}
              />
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <LoadingButton
                sx={{
                  backgroundColor: '#d21426',
                  color: '#fff',
                  fontSize: isSmallScreen ? '0.6rem' : '1rem',
                }}
                fullWidth
                size={isSmallScreen ? 'small' : 'medium'}
                variant="contained"
                color="error"
                disabled={!isDirty}
                onClick={() => {
                  reset();
                }}
              >
                {t('buttons.cancelChanges')}
              </LoadingButton>
              <LoadingButton
                sx={{
                  backgroundColor: 'success.dark',
                  color: '#fff',
                  ':hover': { backgroundColor: 'success.darker' },
                  fontSize: isSmallScreen ? '0.6rem' : '1rem',
                }}
                fullWidth
                size={isSmallScreen ? 'small' : 'medium'}
                type="submit"
                variant="contained"
                color="success"
                disabled={!isValid || !isDirty}
              >
                {t('buttons.update')}
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
