export const cardStyle = {
  boxShadow: 15,
  paddingTop: 3,
  pl: 4,
  pr: 4,
  borderRadius: 5,
  backgroundColor: 'white',
};

export const boxStyleProgress = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const boxStyleFormFooter = {
  width: '100%',

  py: 2,
  textAlign: 'center',
  borderTop: '1px solid #ddd',
};
