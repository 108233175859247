import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { Grid } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import OptionSelector from '../OptionsSelector';
import { useFormContext } from '../../../../hooks/useForm';
import TextInput from '../../../../components/FormInputs/TextInput';
import FileInput from '../../../../components/FormInputs/FileInput';
import SelectInput from '../../../../components/FormInputs/SelectInput';
import TextAreaInput from '../../../../components/FormInputs/TextAreaInput';
import DateTimePickerInput from '../../../../components/FormInputs/DateTimePickerInput';

const InputPlaceHolder = ({ selectedInput, question, sectionId, selectedType }) => {
  const [type, setType] = useState(question.type ?? selectedInput);
  const { isSmallScreen } = useResponsiveScreen();

  useEffect(() => {
    setType(selectedInput);
  }, [selectedInput]);

  if (type === 'TextInput')
    return (
      <TextInput
        label="Text input"
        disabled
        size={isSmallScreen ? 'small' : 'medium'}
        sx={{
          mt: 1,
          '& .MuiInputLabel-root': {
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          },
          '& .MuiInputBase-input': {
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          },
        }}
      />
    );
  if (type === 'TextAreaInput')
    return (
      <TextAreaInput
        label="Text input"
        disabled
        size={isSmallScreen ? 'small' : 'medium'}
        sx={{
          mt: 1,
          '& .MuiInputLabel-root': {
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          },
          '& .MuiInputBase-input': {
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          },
        }}
      />
    );
  if (type === 'DateTimePickerInput')
    return (
      <DateTimePickerInput
        disabled
        size={isSmallScreen ? 'small' : 'medium'}
        sx={{
          mt: 1,
          '& .MuiInputLabel-root': {
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          },
          '& .MuiInputBase-input': {
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          },
        }}
      />
    );
  if (type === 'FileInput')
    return (
      <FileInput
        disabled
        size={isSmallScreen ? 'small' : 'medium'}
        sx={{
          mt: 1,
          '& .MuiInputLabel-root': {
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          },
          '& .MuiInputBase-input': {
            fontSize: isSmallScreen ? '0.8rem' : '1rem',
          },
        }}
      />
    );

  return (
    <OptionSelector
      question={question}
      sectionId={sectionId}
      selectedInput={type}
      selectedType={selectedType}
    />
  );
};
InputPlaceHolder.propTypes = {
  selectedInput: PropTypes.string,
  question: PropTypes.object,
  sectionId: PropTypes.string,
  selectedType: PropTypes.string,
};

export default function Question({ sectionId, question, selectedType }) {
  const { modifyQuestion } = useFormContext();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [updatedQuestion, setUpdatedQuestion] = useState(question);
  const [selectedInput, setSelectedInput] = useState(question.type ?? 'TextInput');
  const Inputs = [
    { text: t('formInputs.text'), value: 'TextInput' },
    { text: t('formInputs.textArea'), value: 'TextAreaInput' },
    { text: t('formInputs.select'), value: 'SelectInput' },
    { text: t('formInputs.checkbox'), value: 'CheckboxInput' },
    { text: t('formInputs.multipleChoice'), value: 'RadioInput' },
    { text: t('formInputs.date'), value: 'DateTimePickerInput' },
    { text: t('formInputs.fileUpload'), value: 'FileInput' },
  ];

  const handleSelectInput = (selectedel) => {
    setSelectedInput(selectedel);
    modifyQuestion(question.id, { ...question, type: selectedel });
  };
  const handleQuestionChange = () => {
    modifyQuestion(question.id, { ...question, ...updatedQuestion });
  };

  return (
    <>
      <Grid container mt={2} mb={2}>
        {!isSmallScreen && (
          <Grid item xs={7}>
            <TextInput
              name="Question label"
              label={t('pages.formPage.question')}
              variant="standard"
              size="medium"
              color="info"
              fullWidth
              defaultValue={question.text || ''}
              onChange={(e) => setUpdatedQuestion({ ...question, text: e.target.value })}
              onBlur={handleQuestionChange}
            />
          </Grid>
        )}
        <Grid
          item
          xs={isSmallScreen ? 12 : 4}
          sx={{ textAlign: 'start', ml: isSmallScreen ? {} : 5 }}
        >
          <SelectInput
            labelValue={t('pages.formPage.type')}
            name="Type selector"
            defaultValue={question.type || t('pages.formPage.textInput')}
            options={Inputs}
            setSelectedInput={handleSelectInput}
            sx={{ width: '100%' }}
          />
        </Grid>
        {isSmallScreen && (
          <Grid item xs={11}>
            <TextInput
              name="Question label"
              label={t('pages.formPage.question')}
              variant="standard"
              size="small"
              color="info"
              fullWidth
              defaultValue={question.text || ''}
              onChange={(e) => setUpdatedQuestion({ ...question, text: e.target.value })}
              onBlur={handleQuestionChange}
              sx={{
                mt: 2,
                mx: 1,
                '& .MuiInputLabel-root': {
                  fontSize: '0.8rem',
                },
                '& .MuiInputBase-input': {
                  fontSize: '0.8rem',
                },
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid mb={2}>
        <InputPlaceHolder
          selectedInput={selectedInput}
          question={question}
          sectionId={sectionId}
          selectedType={selectedType}
        />
      </Grid>
    </>
  );
}

Question.propTypes = {
  question: PropTypes.object,
  sectionId: PropTypes.string,
  selectedType: PropTypes.string,
};
