// @mui
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { TabList, TabPanel, TabContext } from '@mui/lab';
import { Tab, Box, Stack, Container, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { FormProvider } from '../../hooks/useForm';
import FormPreview from '../../sections/form/FormPreview';
import FormBody from '../../sections/form/formComposer/FormBody';
// ----------------------------------------------------------------------

export default function FormPage() {
  const [value, setValue] = useState('0');
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title> {t('nav.newForm')}</title>
      </Helmet>
      <Container sx={{ overflowY: 'auto', height: '86%', position: 'fixed' }}>
        <FormProvider>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab
                  label={t('pages.formPage.formComposer')}
                  value="0"
                  sx={{ fontSize: isSmallScreen ? '11px' : {} }}
                />
                <Tab
                  label={t('pages.formPage.previewForm')}
                  value="1"
                  sx={{ fontSize: isSmallScreen ? '11px' : {} }}
                />
              </TabList>
            </Box>
            <TabPanel value="0">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={isSmallScreen ? 2 : 5}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: isSmallScreen && '16px',
                  }}
                >
                  {t('buttons.newForm')}
                </Typography>
              </Stack>
              <FormBody />
            </TabPanel>
            <TabPanel value="1">
              <FormPreview disabled />
            </TabPanel>
          </TabContext>
        </FormProvider>
      </Container>
    </>
  );
}
