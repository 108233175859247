import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { Box } from '@mui/system';
import {
  Card,
  Grid,
  Switch,
  Tooltip,
  Accordion,
  Container,
  FormLabel,
  IconButton,
  Typography,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  FormControlLabel,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import debounce from '../../../utils/debounce';
import Iconify from '../../../components/iconify';
import QuestionList from './Question/QuestionList';
import { useFormContext } from '../../../hooks/useForm';
import TextInput from '../../../components/FormInputs/TextInput';
import SelectInput from '../../../components/FormInputs/SelectInput';
import { getUsersByDepartment } from '../../../services/user.service';
import CheckboxInput from '../../../components/FormInputs/CheckboxInput';
import DurationSelector from '../../../components/durationSelecor/DurationSelector';

FormSection.propTypes = {
  section: PropTypes.object,
  addSection: PropTypes.func,
  index: PropTypes.number,
  updateSectionQuestions: PropTypes.func,
  setFocusedSection: PropTypes.func,
  focusedSection: PropTypes.string,
  sectionLength: PropTypes.number,
  selectedDepartment: PropTypes.number,
};

const iconButtonStyle = {
  color: 'white',
  transition: 'color 0.3s', // Add a smooth color transition
  '&:hover': {
    color: (theme) => theme.palette.grey[500], // Change to the desired hover color
  },
};

export default function FormSection({
  section,
  index,
  setFocusedSection,
  focusedSection,
  sectionLength,
  selectedDepartment,
}) {
  const { deleteSection, addSection, addQuestion, modifySection, getOrderedSections } =
    useFormContext();

  const [currentSection] = useState(section);
  const [expanded, setExpanded] = useState(true);
  const [sectionList, setSectionList] = useState();
  const [nextSection, setNextSection] = useState(section?.nextSection);
  const [priority, setPriority] = useState(section?.priority || 'sel');
  const [category, setCategory] = useState(section?.category || '');
  const [duration, setDuration] = useState(
    section?.duration || { days: 0, hours: 1, minutes: 0 }
  );
  const [sectionName, setSectionName] = useState(section?.name || '');
  const [agents, setAgents] = useState(section?.agents);
  const [autoAssign, setAutoAssign] = useState(section?.autoAssign ?? false);
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();

  const filteredAgents = useMemo(
    () =>
      agents
        ?.filter((agent) => agent.appUserRole === 'ROLE_AGENT')
        .map((agent) => ({ text: agent.username, value: agent.id })),
    [agents]
  );

  const defaultfilteredAgents = useMemo(
    () => (filteredAgents ? filteredAgents?.map((f) => f.value) : []),
    [filteredAgents]
  );

  const handlePrioritySeceltion = (subPriority) => {
    setPriority(subPriority);
    modifySection(section.id, { ...section, priority: subPriority });
  };

  const handleCategoryChange = (cat) => {
    setCategory(cat);
    modifySection(section.id, { ...section, category: cat });
  };

  const handleDurationChange = (updatedDuration) => {
    setDuration(updatedDuration);
    modifySection(section.id, { ...section, duration: updatedDuration });
  };

  const handleSectionNameChange = (name) => {
    setSectionName(name);
    modifySection(section.id, { ...section, name });
  };

  const handleAutoAssignToggle = () => {
    const newAutoAssign = !autoAssign;
    modifySection(section.id, { ...section, autoAssign: newAutoAssign });
    setAutoAssign(newAutoAssign);
  };

  useEffect(() => {
    if (expanded === true) {
      setFocusedSection(section.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  useEffect(() => {
    if (focusedSection === section.id) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedSection]);

  useEffect(() => {
    if (selectedDepartment && section.nextSection === 'submit') {
      getUsersByDepartment(selectedDepartment).then((res) => {
        setAgents(res.filter((agent) => !agent.archived));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment, section.nextSection]);

  useEffect(() => {
    const sections = getOrderedSections()
      .filter((sectionItem) => sectionItem.id !== section.id)
      .map((section) => ({
        text: `${t('pages.formPage.section')} ${section.name}`,
        value: section.id,
      }));
    sections.push({ text: t('buttons.submit'), value: 'submit' });
    setSectionList(sections);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderedSections, t]);

  // Sets selected section
  const handleNextSectionSelection = (nextSectionID) => {
    setNextSection(nextSectionID);
    modifySection(section.id, { ...section, nextSection: nextSectionID });
  };

  const handleAddSection = () => {
    debounce(
      addSection({ name: sectionLength + 1, questions: [], isFirstSection: false }),
      500
    );
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Set selected agents
  const handleAgentSelection = (agents) => {
    modifySection(section.id, { ...section, agents, autoAssign });
  };

  return (
    <Grid container mt={2}>
      <Grid item xs={11}>
        <Accordion expanded={expanded} onChange={toggleExpanded}>
          <AccordionSummary
            expandIcon={<Iconify icon="ooui:expand" width={isSmallScreen ? 14 : 20} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: 'aliceblue',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              borderBottomLeftRadius: expanded ? '0px' : '10px',
              borderBottomRightRadius: expanded ? '0px' : '10px',
              py: '0.5vh',
            }}
          >
            <TextInput
              defaultValue={sectionName || index}
              label={
                <Typography
                  variant="body2"
                  sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                >
                  {t('pages.formPage.sectionName')}
                </Typography>
              }
              onBlur={(e) => handleSectionNameChange(e.target.value)}
              size={isSmallScreen ? 'small' : 'medium'}
              sx={{ marginRight: '2vh' }}
              inputProps={{
                style: {
                  fontSize: isSmallScreen ? '0.775rem' : '1rem',
                },
              }}
            />
          </AccordionSummary>

          {expanded && (
            <AccordionDetails>
              <QuestionList {...section} />
              {section.nextSection === 'submit' && (
                <>
                  <SelectInput
                    options={[
                      { text: t('formControl.selectPriority'), value: 'sel' },
                      { text: t('priority.HIGH'), value: 'HIGH' },
                      { text: t('priority.MEDIUM'), value: 'MEDIUM' },
                      { text: t('priority.LOW'), value: 'LOW' },
                    ]}
                    variant="standard"
                    sx={{ width: '100%', marginTop: '2vh', marginBottom: '2vh' }}
                    setSelectedInput={handlePrioritySeceltion}
                    defaultValue={priority}
                    labelValue={t('attributes.priority')}
                  />

                  <FormControlLabel
                    sx={{
                      my: isSmallScreen ? '0.5vh' : '1.5vh',
                      width: '100%',
                      '& .MuiFormControlLabel-label': {
                        fontSize: isSmallScreen ? '0.8rem' : '1rem',
                      },
                    }}
                    label="Auto-assign"
                    onChange={handleAutoAssignToggle}
                    value={autoAssign}
                    checked={autoAssign}
                    control={
                      <Switch
                        name="toggleAutoAssign"
                        size={isSmallScreen ? 'small' : 'medium'}
                        label="Auto-assign"
                      />
                    }
                  />

                  {filteredAgents && autoAssign ? (
                    <>
                      <FormLabel
                        component="legend"
                        sx={{
                          fontSize: isSmallScreen ? '0.8rem' : '1rem',
                          marginTop: '2vh',
                        }}
                      >
                        {t('pages.formPage.assignResponsibility')}
                      </FormLabel>
                      <CheckboxInput
                        options={filteredAgents}
                        variant="standard"
                        sx={{ width: '100%', marginTop: '1vh' }}
                        defaultValue={
                          currentSection?.agents
                            ? currentSection.agents
                            : defaultfilteredAgents || []
                        }
                        setSelectedVals={handleAgentSelection}
                        label={t('attributes.agents')}
                      />
                    </>
                  ) : null}

                  <Box sx={{ marginTop: '3vh' }}>
                    <DurationSelector
                      days={parseInt(duration.days, 10)}
                      setDays={(val) => handleDurationChange({ ...duration, days: val })}
                      hours={parseInt(duration.hours, 10)}
                      setHours={(val) =>
                        handleDurationChange({ ...duration, hours: val })
                      }
                      minutes={parseInt(duration.minutes, 10)}
                      setMinutes={(val) =>
                        handleDurationChange({ ...duration, minutes: val })
                      }
                    />
                  </Box>

                  <TextInput
                    defaultValue={category}
                    fullWidth
                    label={t('attributes.category')}
                    sx={{
                      marginTop: isSmallScreen ? '1vh' : '2vh',
                      '& .MuiInputLabel-root': {
                        fontSize: isSmallScreen ? '0.75rem' : '1rem',
                      },
                      '& .MuiInputBase-root': {
                        fontSize: isSmallScreen ? '0.875rem' : '1rem',
                      },
                    }}
                    size={isSmallScreen ? 'small' : 'medium'}
                    onBlur={(e) => handleCategoryChange(e.target.value)}
                  />
                </>
              )}
            </AccordionDetails>
          )}
          <AccordionActions
            sx={{
              backgroundColor: 'aliceblue',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              padding: isSmallScreen ? '1vh' : '2vh',
              flexWrap: 'wrap',
            }}
          >
            <Grid
              container
              spacing={isSmallScreen ? 2 : 1}
              alignItems={isSmallScreen ? 'flex-start' : 'center'}
              justifyContent="space-between"
              direction="row"
            >
              <Grid
                item
                xs={2.5}
                container
                direction="row"
                justifyContent={isSmallScreen ? 'center' : 'flex-start'}
                alignItems={isSmallScreen ? 'center' : 'flex-start'}
                spacing={isSmallScreen ? 1 : 0}
              >
                <Grid item>
                  <Tooltip title={t('pages.formPage.addSection')}>
                    <IconButton onClick={handleAddSection}>
                      <Iconify
                        icon="typcn:plus"
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                          transition: 'color 0.3s',
                          '&:hover': {
                            color: (theme) => theme.palette.primary.main,
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={t('buttons.delete')}>
                    <IconButton onClick={() => deleteSection(section.id)}>
                      <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              {isSmallScreen && (
                <Grid item xs={8.5} sx={{ m: isSmallScreen ? 1 : 0 }}>
                  <Typography noWrap sx={{ fontSize: isSmallScreen ? '0.675rem' : {} }}>
                    {t('pages.formPage.afterSection')}{' '}
                    <Tooltip title={section.name}>
                      <span>
                        <b>{section.name}</b> :
                      </span>
                    </Tooltip>
                  </Typography>

                  <SelectInput
                    options={sectionList || [{ value: section.nextSection } || '']}
                    variant="standard"
                    sx={{ width: '100%', marginTop: '1vh' }}
                    setSelectedInput={handleNextSectionSelection}
                    defaultValue={nextSection || ''}
                    labelValue={t('pages.formPage.continueTo')}
                  />
                </Grid>
              )}

              {!isSmallScreen && (
                <>
                  <Grid item xs={3}>
                    <Typography noWrap>
                      {t('pages.formPage.afterSection')}{' '}
                      <Tooltip title={section.name}>
                        <span>
                          <b>{section.name}</b> :
                        </span>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectInput
                      options={sectionList || [{ value: section.nextSection } || '']}
                      variant="standard"
                      sx={{ width: '100%' }}
                      setSelectedInput={handleNextSectionSelection}
                      defaultValue={nextSection || ''}
                      labelValue={t('pages.formPage.continueTo')}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </AccordionActions>
        </Accordion>
      </Grid>
      {expanded && focusedSection === section.id && (
        <Grid item xs={1}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              position: 'relative',
            }}
          >
            <Card
              color="primary"
              aria-label="add"
              variant="extended"
              size="small"
              direction="down"
              raised
              sx={{
                top: '50vh',
                opacity: '0.9',
                textAlign: 'center',
                position: 'sticky',
                display: 'inline-grid',
                bgcolor: '#2065D1',
                overflow: 'hidden',
                padding: isSmallScreen ? '0vh' : '0.2vh',
              }}
            >
              <Tooltip title={t('pages.formPage.addQuestion')}>
                <IconButton
                  onClick={() => {
                    addQuestion(section.id, {});
                  }}
                >
                  <Iconify
                    icon="simple-line-icons:plus"
                    sx={iconButtonStyle}
                    width={isSmallScreen ? 15 : 20}
                    height={isSmallScreen ? 15 : 20}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('pages.formPage.addSection')}>
                <IconButton onClick={handleAddSection}>
                  <Iconify
                    icon="bi:sign-intersection"
                    sx={iconButtonStyle}
                    width={isSmallScreen ? 15 : 20}
                    height={isSmallScreen ? 15 : 20}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('pages.formPage.deleteSection')}>
                <IconButton onClick={() => deleteSection(section.id)}>
                  <Iconify
                    icon="eva:trash-2-fill"
                    sx={iconButtonStyle}
                    width={isSmallScreen ? 15 : 20}
                    height={isSmallScreen ? 15 : 20}
                  />
                </IconButton>
              </Tooltip>
            </Card>
          </Container>
        </Grid>
      )}
    </Grid>
  );
}
