import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

function CSVUploadInstructions() {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Box bgcolor="#c4ecbc" p={2} borderRadius={4} mb={2} boxShadow={3} color="#41aa35">
      <Typography
        variant="h5"
        gutterBottom
        sx={{ marginLeft: '20px', fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
      >
        {t('inputs.CSV.titleText')}
      </Typography>
      <Typography
        color="Black"
        sx={{ marginLeft: '40px', fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
        variant="body1"
      >
        {t('inputs.CSV.tableDescriptionText')}
      </Typography>

      <TableContainer size="small" component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                Username
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                Email
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                AppUserRole
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                GivenName
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                FamilyName
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                PhoneNumber
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                IsAvailable
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                DepartmentId
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                agent1
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                agent1@example.com
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                ROLE_AGENT
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                John
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                Doe
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                12345678
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                TRUE
              </TableCell>
              <TableCell
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                1
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CSVUploadInstructions;
