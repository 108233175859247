// @mui
import PropTypes from 'prop-types';

import { Box, Card, Paper, Typography, CardHeader, CardContent } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

// utils
import { fShortenNumber } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

AppTrafficBySite.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppTrafficBySite({ title, subheader, list, ...other }) {
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        sx={{
          '& .MuiCardHeader-title': { fontSize: isSmallScreen && '0.8rem' },
          '& .MuiCardHeader-subheader': { fontSize: isSmallScreen && '0.7rem' },
        }}
      />

      <CardContent>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          {list.map((site) => (
            <Paper
              key={site.name}
              variant="outlined"
              sx={{ py: 2.5, textAlign: 'center' }}
            >
              <Box sx={{ mb: 0.5, fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>
                {site.icon}
              </Box>

              <Typography
                variant="h6"
                sx={{
                  fontSize: isSmallScreen ? '0.8rem' : '1rem',
                }}
              >
                {fShortenNumber(site.value)}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  fontSize: isSmallScreen ? '0.8rem' : '1rem',
                }}
              >
                {site.name}
              </Typography>
            </Paper>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
