import PropTypes from 'prop-types';

import { TableCell, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

DataCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default function DataCell({ value }) {
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <TableCell align="left">
      <Typography
        variant="body2"
        sx={{
          fontSize: isSmallScreen && '0.65rem',
        }}
      >
        {value}
      </Typography>
    </TableCell>
  );
}
