import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Dialog,
  TextField,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import { useToast } from '../../../hooks/useToast';
import convertToBase64 from '../../../utils/convertBase64';
import compressImage from '../../../utils/imageCompressor';
import ImageInput from '../../../components/inputs/ImageInput';

DepartmentFormModal.propTypes = {
  department: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  apiCall: PropTypes.func,
};

const maxSize = 1024 * 1024 * 2; // 2MB maximum file size
const allowedTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
];

export default function DepartmentFormModal({ department, open, setOpen, apiCall }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: department?.id || '',
      name: department?.name || '',
      description: department?.description || '',
      image: department?.image || '',
      createdBy: department?.createdBy || '',
    },
  });
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { id } = useAuth();
  const { showToast, hideToast } = useToast();
  const [selectedFile, setSelectedFile] = useState();

  const watchedValues = watch();

  const isFormDirty = useMemo(
    () =>
      watchedValues.name !== department?.name ||
      watchedValues.description !== department?.description ||
      selectedFile !== null,
    [watchedValues, department, selectedFile]
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setSelectedFile(file);
      } else if (!allowedTypes.includes(file.type)) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImage'),
          severity: 'error',
          props: { hideToast },
        });
      } else if (file.size > maxSize) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImageSize'),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
  };

  const handleClose = () => {
    reset();
    setSelectedFile(null);
    setOpen(false);
  };

  const onSubmit = async (data) => {
    const callBackFunc = (convertedFile) => {
      const fullData = {
        ...data,
        createdBy: department ? data.createdBy : id,
        image: convertedFile,
      };
      apiCall(fullData);
      if (!department) reset();
      handleClose();
    };
    if (selectedFile) {
      if (selectedFile.type === 'image/gif')
        convertToBase64(selectedFile).then(callBackFunc);
      else compressImage(selectedFile, callBackFunc);
    } else {
      const fullData = {
        ...data,
        createdBy: department ? data.createdBy : id,
      };
      apiCall(fullData);
      if (!department) reset();
      handleClose();
    }
  };

  useMemo(() => {
    const defaultValues = {
      id: department?.id || '',
      name: department?.name || '',
      description: department?.description || '',
      image: department?.image || '',
      createdBy: department?.createdBy || '',
    };
    reset({ ...defaultValues });
    setSelectedFile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  const handleReset = () => {
    const defaultValues = {
      id: department?.id || '',
      name: department?.name || '',
      description: department?.description || '',
      image: department?.image || '',
      createdBy: department?.createdBy || '',
    };
    reset(defaultValues);
    setSelectedFile(null);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="modal--title" variant="h6" component="h2">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontSize: isSmallScreen ? '0.8rem' : '1rem',
              }}
            >
              {department
                ? t('pages.departmentPage.form.modifyDepartment')
                : t('pages.departmentPage.form.createDepartment')}
            </Typography>
            <IconButton onClick={() => handleClose()} sx={{ mr: -1.5 }}>
              <Iconify
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    color: '#B72136',
                  },
                }}
                width={isSmallScreen ? 25 : 30}
                icon="eva:close-square-fill"
              />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mt={1}>
              <TextField
                name="name"
                label={t('attributes.name')}
                error={!!errors.name}
                helperText={errors.name?.message || ''}
                {...register('name', {
                  required: t('formControl.enterName'),
                  minLength: {
                    value: 4,
                    message: t('formControl.minName'),
                  },
                  maxLength: {
                    value: 50,
                    message: t('formControl.maxName'),
                  },
                })}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.7rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.7rem' : '1rem',
                  },
                }}
              />

              <TextField
                multiline
                minRows={4}
                name="description"
                label={t('attributes.description')}
                error={!!errors.description}
                helperText={errors.description?.message || ''}
                {...register('description', {})}
                size={isSmallScreen ? 'small' : 'medium'}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isSmallScreen ? '0.7rem' : '1rem',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: isSmallScreen ? '0.7rem' : '1rem',
                  },
                }}
              />

              <ImageInput
                handleChange={handleFileChange}
                selectedFile={selectedFile}
                existingFile={department?.image || undefined}
                defaultImage="/assets/images/departments/defaultDepartment.png"
              />

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                {department && (
                  <LoadingButton
                    sx={{
                      backgroundColor: '#d21426',
                      color: '#fff',
                      fontSize: isSmallScreen ? '0.65rem' : '1rem',
                    }}
                    fullWidth
                    size={isSmallScreen ? 'small' : 'medium'}
                    variant="contained"
                    color="error"
                    disabled={!isFormDirty}
                    onClick={handleReset}
                  >
                    {t('buttons.cancelChanges')}
                  </LoadingButton>
                )}
                <LoadingButton
                  sx={{
                    backgroundColor: 'success.dark',
                    color: '#fff',
                    ':hover': { backgroundColor: 'success.darker' },
                    fontSize: isSmallScreen ? '0.65rem' : '1rem',
                  }}
                  fullWidth
                  size={isSmallScreen ? 'small' : 'medium'}
                  type="submit"
                  variant="contained"
                  disabled={!isValid || !isFormDirty}
                >
                  {department ? t('buttons.editDepartment') : t('buttons.addDepartment')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
