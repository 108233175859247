import { t } from 'i18next';
import * as React from 'react';
import PropTypes from 'prop-types';

import { Select, MenuItem, InputLabel, Typography, FormControl } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { scrollbarStyle } from 'src/sections/style';

SelectInput.propTypes = {
  defaultValue: PropTypes.node,
  labelValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedInput: PropTypes.func,
  removeSection: PropTypes.func,
  setHasSelectedSection: PropTypes.func,
  input: PropTypes.object,
};

export default function SelectInput({
  defaultValue,
  labelValue,
  options,
  setSelectedInput,
  setHasSelectedSection,
  input,
  ...other
}) {
  const [value, setValue] = React.useState(defaultValue || '');
  const { isSmallScreen } = useResponsiveScreen();

  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedInput(event.target.value);
    if (setHasSelectedSection && input?.redirectOnAnswer) {
      setHasSelectedSection(false);
    }
  };

  React.useEffect(() => {
    if (value && setHasSelectedSection && input?.redirectOnAnswer) {
      setHasSelectedSection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FormControl {...other}>
      <InputLabel id="select-label" sx={{ fontSize: isSmallScreen ? '0.7rem' : {} }}>
        {labelValue}
      </InputLabel>
      <Select
        labelId="select"
        id="select"
        value={value}
        label={
          <Typography
            variant="body2"
            sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
          >
            {labelValue}
          </Typography>
        }
        size={isSmallScreen ? 'small' : 'medium'}
        onChange={handleChange}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
        sx={{ width: '100%' }}
        MenuProps={{
          PaperProps: {
            sx: {
              ...scrollbarStyle,
            },
          },
        }}
      >
        {options?.length > 0 ? (
          options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{
                color: option.value === '' ? 'grey' : 'inherit',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {option.text}
              </Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            {t('infoMessages.noOptions')}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
