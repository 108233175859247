export const optionButtonStyle = {
  margin: '0',
  position: 'relative',
};

export const smallOptionButtonStyle = {
  margin: '5px 15px',
  position: 'relative',
  padding: '0',
};

export const buttonBoxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
