import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { TabList, TabPanel, TabContext } from '@mui/lab';
// @mui
import { Tab, Grid, Stack, Container, Typography, Pagination } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { titleHeadingStyle, titleHeadingStyleSmole } from 'src/pages/style';

import TableToolbar from 'src/components/ToolBars/TableToolbar';
import ActionButton from 'src/components/action-button/action-button';

import DepartmentsList from 'src/sections/department/card/DepartmentsList';
import DepartmentTable from 'src/sections/department/table/DepartmentTable';

import Iconify from '../../components/iconify';
import { useToast } from '../../hooks/useToast';
// components
import DepartmentFormModal from './modal/DepartmentFormModal';
// utils
import { getComparator, applySortFilter } from '../../utils/filterData';
// services
import {
  fetchDepartments,
  deleteDepartment,
  createDepartment,
  updateDepartment,
  downloadDepartments,
} from '../../services/department.service';
// ----------------------------------------------------------------------
const itemsPerPage = 8;

export default function DepartmentsView() {
  const [tabValue, setTabValue] = useState('1');
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const [gridPage, setGridPage] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [openAddDepartment, setOpenAddDepartment] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [isDownloadDebouncing, setIsDownloadDebouncing] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [dataInCSV, setDataInCSV] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const { showToast, hideToast } = useToast();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    downloadDepartments().then((res) => {
      setDataInCSV(res);
    });
  }, []);

  // fetch departments
  const fetchData = async () => {
    try {
      setIsloading(true);
      const res = await fetchDepartments();
      setDepartmentData(res);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadDep'),
        severity: 'error',
        props: { hideToast },
      });
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // created department
  const addNewDepartment = async (newDepartmentData) => {
    try {
      await createDepartment({
        id: newDepartmentData.id,
        name: newDepartmentData.name,
        description: newDepartmentData.description,
        image: newDepartmentData.image,
        createdBy: newDepartmentData.createdBy,
      });
      showToast({
        message: t('successMessages.successDepartmentCreate'),
        severity: 'success',
        props: { hideToast },
      });
      fetchData();
    } catch (error) {
      const errorMessage = t(
        error.response?.data === 'Department name already exists'
          ? 'errorMessages.depNameExists'
          : 'errorMessages.couldntCreateDep'
      );
      showToast({
        message: errorMessage,
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  // edit a ticket
  const modifyDepartment = async (newDepartmentData) => {
    try {
      await updateDepartment({
        id: newDepartmentData.id,
        name: newDepartmentData.name,
        description: newDepartmentData.description,
        image: newDepartmentData.image,
        createdBy: newDepartmentData.createdBy,
      });
      showToast({
        message: t('successMessages.successDepartmentUpdate'),
        severity: 'success',
        props: { hideToast },
      });
      const newDepartmentList = departmentData.map((department) =>
        department.id === newDepartmentData.id
          ? {
              ...department,
              name: newDepartmentData.name,
              description: newDepartmentData.description,
              image: newDepartmentData.image,
              createdBy: newDepartmentData.createdBy,
            }
          : department
      );
      setDepartmentData(newDepartmentList);
    } catch (error) {
      const errorMessage = t(
        error.response?.data === 'Department name already exists'
          ? 'errorMessages.depNameExists'
          : 'errorMessages.couldntUpdateDep'
      );
      showToast({
        message: errorMessage,
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  // delete department
  const handleDelete = async (id) => {
    try {
      await deleteDepartment(id);

      showToast({
        message: t('successMessages.successDepartmentDelete'),
        severity: 'success',
        props: { hideToast },
      });

      setDepartmentData((prevData) =>
        prevData.filter((department) => department.id !== id)
      );
      setGridPage(0);
    } catch (e) {
      const errorMessages = {
        users: t('errorReasons.users'),
        tickets: t('errorReasons.tickets'),
        forms: t('errorReasons.forms'),
      };

      if (e.response?.data) {
        const backendMessage = e.response.data;
        const translatedReasons = backendMessage
          .split(', ')
          .map((reason) => errorMessages[reason] || reason);

        const translatedMessage = t('errorMessages.deleteDepartmentError', {
          reason: translatedReasons.join(', '),
        });

        showToast({
          message: translatedMessage,
          severity: 'error',
          props: { hideToast },
        });
      } else {
        showToast({
          message: t('errorMessages.unexpectedError'),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
  };

  const handleDownloadClick = () => {
    if (!isDownloadDebouncing) {
      setIsDownloadDebouncing(true);
      setTimeout(() => {
        setIsDownloadDebouncing(false);
      }, 1500);
    }
  };

  const handleFilterByName = (event) => {
    setGridPage(0);
    setFilterName(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setTablePage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeGridPage = (event, newPage) => {
    setGridPage(newPage - 1);
  };

  const handleChangeTablePage = (event, newPage) => {
    setTablePage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredDepartments = useMemo(
    () =>
      applySortFilter(departmentData, getComparator(order, orderBy), filterName, 'name'),
    [departmentData, filterName, order, orderBy]
  );

  const totalPages = useMemo(
    () => Math.ceil(filteredDepartments.length / itemsPerPage),
    [filteredDepartments]
  );

  const { isNotFound, searchIsNotFound } = useMemo(() => {
    const isNotFound = !filteredDepartments.length;
    const searchIsNotFound = !filteredDepartments.length && !!filterName;
    return { isNotFound, searchIsNotFound };
  }, [filteredDepartments, filterName]);

  return (
    <>
      <Helmet>
        <title> {t('nav.departments')} </title>
      </Helmet>
      <DepartmentFormModal
        open={openAddDepartment}
        setOpen={setOpenAddDepartment}
        apiCall={addNewDepartment}
      />
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={isSmallScreen ? 2 : 5}
        >
          <Typography
            style={isSmallScreen ? titleHeadingStyleSmole : titleHeadingStyle}
            mx={1}
          >
            {t('nav.departments')}
          </Typography>
          <Grid>
            <ActionButton
              id="add-department-btn"
              onClick={() => setOpenAddDepartment(true)}
              icon={isSmallScreen ? 'octicon:plus-circle-16' : 'eva:plus-fill'}
              text={t('buttons.newDepartment')}
              title={t('buttons.newDepartment')}
              sx={{ mr: 1 }}
            />

            <ActionButton
              id="download-departments-btn"
              onClick={handleDownloadClick}
              icon={isSmallScreen ? 'octicon:download-16' : 'eva:download-fill'}
              text={t('buttons.download')}
              title={t('buttons.download')}
              href={`data:text/csv;charset=utf-8,${dataInCSV}`}
              disabled={isDownloadDebouncing}
              download="departments.csv"
              sx={{ mx: 1 }}
            />
          </Grid>
        </Stack>
        <TableToolbar
          numSelected={0}
          filterName={filterName}
          setFilterName={setFilterName}
          onFilterName={handleFilterByName}
          model={t('nav.departments')}
        />
        <TabContext value={tabValue}>
          <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
            <TabList onChange={handleTabChange} aria-label="status-tabs">
              <Tab
                onClick={() => setOrderBy('statusId')}
                icon={<Iconify icon="gridicons:grid" />}
                value="1"
              />
              <Tab icon={<Iconify icon="gridicons:list-unordered" />} value="2" />
            </TabList>
          </Stack>
          <TabPanel value="1">
            <DepartmentsList
              departments={filteredDepartments}
              handleDelete={handleDelete}
              editDepartment={modifyDepartment}
              itemsPerPage={itemsPerPage}
              filterName={filterName}
              isNotFound={isNotFound}
              searchIsNotFound={searchIsNotFound}
              isLoading={isLoading}
              page={gridPage}
            />
            {filteredDepartments.length > 0 && (
              <Stack alignItems="center" sx={{ mt: 5 }}>
                <Pagination
                  component="div"
                  color="primary"
                  count={totalPages}
                  size={isSmallScreen ? 'medium' : 'large'}
                  page={gridPage + 1}
                  onChange={handleChangeGridPage}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      fontSize: isSmallScreen && '0.75rem',
                    },
                  }}
                />
              </Stack>
            )}
          </TabPanel>
          <TabPanel value="2">
            <DepartmentTable
              departments={filteredDepartments}
              handleDelete={handleDelete}
              editDepartment={modifyDepartment}
              page={tablePage}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangeTablePage}
              handleRequestSort={handleRequestSort}
              filterName={filterName}
              isNotFound={isNotFound}
              searchIsNotFound={searchIsNotFound}
              order={order}
              orderBy={orderBy}
              isLoading={isLoading}
            />
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
}
