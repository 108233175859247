import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Copyright from 'src/components/copyright/CopyRight';
import LanguagePopover from 'src/components/LanguagePopover';

import Carousel from './carousel/carousel';
import Logo from '../../components/logo/Logo';
import {
  logoStyles,
  carouselStyles,
  childrenStyles,
  containerStyles,
  topContainerStyles,
  boxContainerStyles,
} from './styles';

export function LoginLayout({ children }) {
  const { isSmallScreen } = useResponsiveScreen();
  return (
    <Grid container>
      <Grid item xs={12} md={6} bgcolor="white">
        <Box sx={{ ...boxContainerStyles, height: isSmallScreen ? 'auto' : '100vh' }}>
          <Box sx={topContainerStyles}>
            <Logo sx={logoStyles} />
            <LanguagePopover />
          </Box>
          <Box sx={containerStyles}>
            <Box sx={childrenStyles}>{children}</Box>
            {!isSmallScreen && (
              <>
                <br />
                <Copyright />
              </>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} bgcolor="white">
        <Box
          sx={{
            ...carouselStyles,
            height: isSmallScreen ? 'auto' : '100vh',
            mt: isSmallScreen && 3,
          }}
        >
          <Carousel />
        </Box>

        {isSmallScreen && (
          <>
            <Copyright />
            <br />
          </>
        )}
      </Grid>
    </Grid>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.node,
};
