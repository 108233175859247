import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import { Grid, Stack, Paper, Pagination } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Loader from 'src/components/loaders/Loader';
import EmptyGrid from 'src/components/emptyGrid/emptyGrid';

import StatusCard from './statusCard';

StatusGrid.propTypes = {
  statusData: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleDetailsClick: PropTypes.func,
  deleteStatus: PropTypes.func,
  isLoading: PropTypes.bool,
  isNotFound: PropTypes.bool,
  searchIsNotFound: PropTypes.bool,
  filterName: PropTypes.string,
};
export default function StatusGrid({
  statusData,
  itemsPerPage,
  page,
  handleChangePage,
  handleDetailsClick,
  deleteStatus,
  isLoading,
  searchIsNotFound,
  isNotFound,
  filterName,
}) {
  const { isSmallScreen } = useResponsiveScreen();
  const totalPages = useMemo(
    () => Math.ceil(statusData.length / itemsPerPage),
    [statusData, itemsPerPage]
  );

  return (
    <>
      <Grid
        container
        columns={15}
        spacing={3}
        sx={{
          justifyContent: isSmallScreen ? 'center' : 'flex-start',
        }}
      >
        {!isLoading ? (
          <>
            {statusData
              .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
              .map((status, idx) => (
                <Grid item key={idx} xs={6} sm={6} md={3}>
                  <StatusCard
                    status={status}
                    handleDetailsClick={handleDetailsClick}
                    deleteStatus={deleteStatus}
                  />
                </Grid>
              ))}
            {isNotFound && (
              <Box sx={{ width: '90%', ml: 2 }}>
                <EmptyGrid searchIsNotFound={searchIsNotFound} filterName={filterName} />
              </Box>
            )}
          </>
        ) : (
          <Paper
            sx={{
              width: '100%',
              py: 5,
              my: 5,
              textAlign: 'center',
            }}
          >
            <Loader size={175} />
          </Paper>
        )}
      </Grid>
      {statusData.length > 0 && (
        <Stack alignItems="center" sx={{ mt: isSmallScreen ? 3 : 5 }}>
          <Pagination
            component="div"
            color="primary"
            count={totalPages}
            size={isSmallScreen ? 'medium' : 'large'}
            page={page + 1}
            onChange={handleChangePage}
            sx={{
              '& .MuiPaginationItem-root': {
                fontSize: isSmallScreen ? '0.75rem' : '1rem',
              },
            }}
          />
        </Stack>
      )}
    </>
  );
}
