import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useContext, useReducer, createContext } from 'react';

import { Stack } from '@mui/system';
import {
  Alert,
  Slide,
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import Iconify from '../components/iconify';
import useResponsiveScreen from './useResponsiveScreen';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ModalContext = createContext({
  showModal: () => undefined,
  hideModal: () => undefined,
  isVisible: false,
});

const { Provider, Consumer: ModalConsumer } = ModalContext;

const reducer = (state, action) => {
  switch (action.type) {
    case 'openModal': {
      const { title, Component, props } = action;
      return { ...state, title, Component, props };
    }
    case 'hideModal':
      return { ...state, title: null, Component: null, props: {} };
    default:
      throw new Error('Unspecified reducer action');
  }
};

const ModalProvider = ({ children }) => {
  const initialState = {
    title: null,
    Component: null,
    props: {},
  };

  ModalProvider.propTypes = {
    children: PropTypes.node,
    maxWidth: PropTypes.string,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { title, Component, props } = state;
  // eslint-disable-next-line react/prop-types
  const maxWidth = props.maxWidth ? 'md' : 'sm';
  const { isSmallScreen } = useResponsiveScreen();
  const isVisible = !!Component;
  const showModal = (modal) => {
    dispatch({ type: 'openModal', ...modal });
  };

  const hideModal = () => {
    dispatch({ type: 'hideModal' });
    // eslint-disable-next-line no-unused-expressions, react/prop-types
    props.onClose && props.onClose();
  };

  return (
    <Provider value={{ ...state, showModal, hideModal, isVisible }}>
      {children}
      <Dialog
        open={isVisible}
        onClose={hideModal}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={maxWidth}
      >
        {title && (
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={{ fontSize: isSmallScreen ? 13 : 18, mt: 1, fontWeight: 'bold' }}
                variant="subtitle1"
              >
                {title}
              </Typography>
              <IconButton onClick={hideModal} sx={{ cursor: 'pointer', mr: -1.5 }}>
                <Iconify
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: '#B72136',
                    },
                  }}
                  width={isSmallScreen ? 25 : 30}
                  icon="eva:close-square-fill"
                />
              </IconButton>
            </Stack>
          </DialogTitle>
        )}

        <DialogContent>
          {Component && (
            <div>
              <Component {...props} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.oneOf(['md', 'sm']),
};

const useModal = () => useContext(ModalContext);

const useConfirmModal = (title, message, onConfirm) => {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { showModal, hideModal, isVisible } = useModal();
  const showConfirmModal = (existingId) =>
    showModal({
      title,
      Component: () => (
        <>
          <Alert
            severity="warning"
            sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
          >
            {message}
            {typeof existingId === 'string' && existingId
              ? ` (${t('pages.shareableFormsPage.existingId')}: #${existingId})`
              : ''}
          </Alert>

          <div>
            <Button
              id="modal-confirm-btn"
              sx={{ color: 'red', fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
              onClick={() => {
                onConfirm();
                hideModal();
              }}
            >
              {t('buttons.confirm')}
            </Button>
            <Button
              id="modal-cancel-btn"
              type="button"
              onClick={hideModal}
              sx={{ fontSize: isSmallScreen ? '0.775rem' : '1rem' }}
            >
              {t('buttons.cancel')}
            </Button>
          </div>
        </>
      ),
      props: { onClose: hideModal },
    });

  return {
    showConfirmModal,
    hideModal,
    isVisible,
  };
};

export { useModal, ModalConsumer, ModalProvider, useConfirmModal };
