import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { useToast } from '../../hooks/useToast';
import { PASSWORD_REGEX } from '../../utils/regex';
import authService from '../../services/auth.service';
import PasswordTextInput from '../inputs/PasswordTextInput';
import { changePassword, resetPasswordUsingToken } from '../../services/user.service';

ChangePassword.propTypes = {
  oldPass: PropTypes.string,
  token: PropTypes.string,
};

export default function ChangePassword({ oldPass, token = '' }) {
  const navigate = useNavigate();
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { id: userId } = useAuth();
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      oldPassword: oldPass || '',
      token: token || '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const handleChangePassword = async (oldPassword, newPassword) => {
    try {
      await changePassword({ id: userId, oldPassword, newPassword });
      showToast({
        message: t('successMessages.passwordUpdated'),
        severity: 'success',
        props: { hideToast },
      });
      authService.logout(userId);
      navigate('/login', { replace: true });
    } catch (err) {
      showToast({
        message: t('errorMessages.enterCorrectOldPassword'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const handleResetPassword = async (oldPassword, newPassword) => {
    try {
      await resetPasswordUsingToken(token, newPassword);
      showToast({
        message: t('successMessages.passwordUpdated'),
        severity: 'success',
        props: { hideToast },
      });
      navigate('/login', { replace: true });
    } catch (err) {
      showToast({
        message: t('errorMessages.errorUpdatingPassword'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const onSubmit = async (data) => {
    const { oldPassword, newPassword } = data;

    setLoading(true);
    if (token) {
      await handleResetPassword(oldPassword, newPassword);
    } else {
      await handleChangePassword(oldPassword, newPassword);
    }
    setLoading(false);
  };

  const handleShowPassword = (name) => {
    setShowPassword((state) => ({
      ...state,
      [name]: !state[name],
    }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Stack spacing={3}>
          <Stack spacing={2}>
            {!token && (
              <PasswordTextInput
                hidden={oldPass}
                name="oldPassword"
                handleShowPassword={handleShowPassword}
                showPassword={showPassword.oldPassword}
                errors={errors}
                register={register}
                validations={{
                  required: t('formControl.enterOldPassword'),
                }}
                value={watch('oldPassword')}
              />
            )}
            <PasswordTextInput
              name="newPassword"
              handleShowPassword={handleShowPassword}
              showPassword={showPassword.newPassword}
              errors={errors}
              register={register}
              validations={{
                required: t('formControl.insertPassword'),
                minLength: {
                  value: 6,
                  message: t('formControl.passwordMin'),
                },
                pattern: {
                  value: PASSWORD_REGEX,
                  message: t('formControl.passwordContains'),
                },
              }}
              value={watch('newPassword')}
            />
            <PasswordTextInput
              name="confirmPassword"
              handleShowPassword={handleShowPassword}
              showPassword={showPassword.confirmPassword}
              errors={errors}
              register={register}
              validations={{
                required: t('formControl.confirmPassword'),
                validate: (val) => {
                  if (watch('newPassword') !== val) {
                    return t('formControl.passwordDontMatch');
                  }
                  return null;
                },
              }}
              value={watch('confirmPassword')}
            />
          </Stack>
          <Stack
            spacing={1}
            sx={{
              width: token
                ? {
                    xs: '90%',
                    sm: '80%',
                    md: '75vh',
                  }
                : '100%',
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isValid}
              loading={loading}
              size={isSmallScreen ? 'small' : 'medium'}
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              {t('buttons.updatePassword')}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </form>
  );
}
