import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Stack, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from 'src/components/iconify';

import { styles } from '../styles';

export default function UserDetails({ department, startDate }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { user } = useAuth();

  return (
    <Stack spacing={1}>
      <Stack sx={styles.iconBox}>
        <Iconify
          icon="eva:question-mark-circle-outline"
          sx={{
            mr: isSmallScreen ? 0.5 : 1,
            width: isSmallScreen ? 16 : 20,
            height: isSmallScreen ? 16 : 20,
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontSize: isSmallScreen && '0.8rem',
          }}
        >
          {t('pages.profilePage.userInfo')}
        </Typography>
      </Stack>
      <Card sx={styles.userInfoCard}>
        <Stack spacing={1}>
          <Typography
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            <b>{t('pages.profilePage.role')}: </b>
            {t(`roles.${user.appUserRole}`)}
          </Typography>
          {department && (
            <Typography
              sx={{
                fontSize: isSmallScreen && '0.7rem',
              }}
            >
              <b>{t('attributes.department')} : </b>
              {department}
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: isSmallScreen && '0.7rem',
            }}
          >
            <b>{t('pages.profilePage.startedAt')}: </b>
            {startDate}
          </Typography>
        </Stack>
      </Card>
    </Stack>
  );
}

UserDetails.propTypes = {
  department: PropTypes.string,
  startDate: PropTypes.string,
};
