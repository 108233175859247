import PropTypes from 'prop-types';

import { Box, Button, Tooltip, IconButton, Typography } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Iconify from '../iconify';
import { buttonBoxStyle, optionButtonStyle, smallOptionButtonStyle } from './style';

export default function ActionButton({ id, onClick, icon, text, title, ...rest }) {
  const { isSmallScreen } = useResponsiveScreen();
  if (isSmallScreen)
    return (
      <Tooltip title={title} arrow>
        <IconButton
          color="primary"
          variant="outlined"
          sx={smallOptionButtonStyle}
          onClick={onClick}
          {...rest}
        >
          <Iconify icon={icon} />
        </IconButton>
      </Tooltip>
    );
  return (
    <Button
      id={id}
      variant="contained"
      sx={optionButtonStyle}
      onClick={onClick}
      {...rest}
    >
      <Box sx={buttonBoxStyle}>
        <Iconify icon={icon} />
        <Typography variant="subtitle2" sx={{ marginLeft: '10px' }}>
          {text}
        </Typography>
      </Box>
    </Button>
  );
}

ActionButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};
