import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

import { Box, Grid, Backdrop, Container, Typography } from '@mui/material';

import { useToast } from 'src/hooks/useToast';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import Loader from 'src/components/loaders/Loader';
import DashboardToolbar from 'src/components/ToolBars/DashboardToolbar';

import { useAuth } from '../../hooks/useAuth';
import { fetchStatuses } from '../../services/status.service';
import { fetchTicketsByDate } from '../../services/ticket.service';
import { getUsersByDepartment } from '../../services/user.service';
import { getDepartmentById } from '../../services/department.service';
import { PieChart, BarChart, AppWidgetSummary } from '../../components/charts';
// ----------------------------------------------------------------------

export default function DepartmentManagerDashboard() {
  const [department, setDepartmentData] = useState({ id: 0, name: '' });
  const [tickets, setTickets] = useState([]);
  const [agents, setAgents] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { username, departmentId } = useAuth();

  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const { showToast, hideToast } = useToast();

  const fetchDepartment = async () => {
    try {
      const department = await getDepartmentById(departmentId);
      setDepartmentData(department);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadDep'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const getStatuses = async (depId) => {
    try {
      const res = await fetchStatuses();
      setStatusesList(
        res.filter((status) => status.departmentId === depId || status.departmentId === 0)
      );
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLadStatuses'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  useEffect(() => {
    getStatuses(departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const fetchAgents = async () => {
    const agentsRes = await getUsersByDepartment(departmentId);
    setAgents(
      agentsRes.filter((user) => user.appUserRole === 'ROLE_AGENT' && !user.archived)
    );
  };

  const getTicketsByDate = async ({ startDate, endDate, attributeName }) => {
    const startingDate = new Date(startDate).setHours(0, 0, 0);
    const endingDate = new Date(endDate).setHours(23, 59, 0);

    const Tickets = await fetchTicketsByDate({
      startDate: new Date(startingDate),
      endDate: new Date(endingDate),
      attributeName,
    });

    const updatedTickets = Tickets.reduce((acc, ticket) => {
      if (ticket.departmentId === departmentId) {
        const statusObject = statusesList.find(
          (status) => status.statusId === ticket.status_id
        );
        acc.push({ ...ticket, statusObject });
      }
      return acc;
    }, []);

    setTickets(updatedTickets);
    setIsLoading(false);
  };

  const { ticketCount, statusCounts, ticketsByAgent } = useMemo(() => {
    const ticketCount = { open: 0, onhold: 0, resolved: 0, closed: 0, unassigned: 0 };
    const statusCounts = {};
    const agentMap = {};

    tickets.forEach((ticket) => {
      const { statusObject, agentName, assignedTo } = ticket;

      // Ticket count by systemMappedStatus
      const status = statusObject?.systemMappedStatus;
      if (status) {
        ticketCount[status.toLowerCase()] = (ticketCount[status.toLowerCase()] || 0) + 1;
      }

      // Status counts by name
      const statusName = statusObject?.name;
      if (statusName) {
        statusCounts[statusName] = (statusCounts[statusName] || 0) + 1;
      }

      // Tickets by agent
      if (agentName && agentName !== 'none') {
        if (!agentMap[assignedTo]) {
          agentMap[assignedTo] = { agent: agentName, agentId: assignedTo, tickets: [] };
        }
        agentMap[assignedTo].tickets.push(ticket);
      }
    });

    const ticketsByAgent = Object.values(agentMap);

    return { ticketCount, statusCounts, ticketsByAgent };
  }, [tickets]);

  useEffect(() => {
    setIsLoading(true);
    if (statusesList.length > 0)
      getTicketsByDate({
        startDate: start,
        endDate: end,
        attributeName: 'created',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList]);

  useEffect(() => {
    fetchAgents();
    fetchDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      <Container maxWidth="xl">
        <Backdrop
          sx={{
            bgcolor: 'rgb(121 121 121 / 32%)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Loader size={175} hideText />
          </Box>
        </Backdrop>
        <Typography
          variant="h6"
          sx={{ mb: 5, fontSize: isSmallScreen ? '0.9rem' : '1rem' }}
        >
          {t('dashboard.greeting')} {username || ''} !
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            width: '100%',
            mt: isSmallScreen && 10,
            mb: isSmallScreen && 10,
          }}
        >
          <DashboardToolbar
            getTicketsByDate={getTicketsByDate}
            start={start}
            end={end}
            setIsLoading={setIsLoading}
          />
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.totalTickets')}
              total={tickets.length}
              color="warning"
              icon="ph:ticket"
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.unassignedTickets')}
              total={ticketCount.unassigned}
              color="error"
              icon="system-uicons:ticket"
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.totalAgents')}
              total={agents.length}
              color="info"
              icon="mdi:face-agent"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.closureRate')}
              total={(ticketCount.resolved / tickets.length) * 100 || 0}
              icon="material-symbols:percent"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <BarChart
              title={t('dashboard.bestPerAgents')}
              subheader={department.name}
              chartData={ticketsByAgent
                .filter((agent) =>
                  agent.tickets.some(
                    (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                  )
                )
                .sort(
                  (a, b) =>
                    b.tickets.filter(
                      (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                    ).length -
                    a.tickets.filter(
                      (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                    ).length
                )
                .map((agent) => ({
                  label: agent.agent,
                  value: agent.tickets.filter(
                    (ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED'
                  ).length,
                }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <PieChart
              title={t('dashboard.ticketByStatus')}
              chartData={statusesList.map((status) => {
                const statusName =
                  status.statusId < 6 ? t(`status.${status.name}`) : status.name;
                return { label: statusName, value: statusCounts[status.name] || 0 };
              })}
              chartColors={statusesList.map((status) => {
                switch (status.statusId) {
                  case 1:
                    return '#d32f2f';
                  case 2:
                    return '#4caf50';
                  case 3:
                    return '#9c27b0';
                  case 4:
                    return '#ed6c02';
                  case 5:
                    return '#1976d2';
                  default:
                    return status.color;
                }
              })}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
