import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Chip,
  Stack,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  Autocomplete,
  FormHelperText,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { EMAIL_REGEX } from '../../../utils/regex';
import { getUsersByDepartment } from '../../../services/user.service';

EditStatus.propTypes = {
  hideModal: PropTypes.func,
  emailsList: PropTypes.array,
  departmentList: PropTypes.array,
  role: PropTypes.string,
  status: PropTypes.object,
  editStatus: PropTypes.func,
};

export default function EditStatus({
  hideModal,
  emailsList,
  departmentList,
  role,
  status,
  editStatus,
}) {
  const [color, setColor] = useState(
    status.statusId <= 5 ? status.statusColor : status.color || '#000000'
  );
  const [emails, setEmails] = useState(status.correspondences);
  const [emailValid, setEmailValid] = useState(true);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const handleHtmlColorChange = (event) => {
    setColor(event.target.value);
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: status.statusId,
      description:
        status.statusId <= 5
          ? t(`statusDescription.${status.systemMappedStatus}`)
          : status.description,
      name: status.name,
      correspondences: status.correspondences,
      color: status.statusId <= 5 ? status.statusColor : status.color,
      systemMappedStatus: status.systemMappedStatus,
      departmentId: status.departmentId,
    },
  });

  const handleAddEmail = (event, newValue) => {
    let validEmails = true;
    newValue.forEach((email) => {
      if (EMAIL_REGEX.test(email) === false) {
        validEmails = false;
      }
    });
    if (validEmails) {
      setEmailValid(true);
      setEmails(newValue);
    } else if (newValue.length !== 0) {
      setEmailValid(false);
    }
  };

  useEffect(() => {
    if (role !== 'ROLE_ADMIN') setEmailSuggestions(emailsList);
  }, [emailsList, role]);

  const selectedDepartment = watch('departmentId');
  useEffect(() => {
    if (selectedDepartment)
      getUsersByDepartment(selectedDepartment).then((res) => {
        setEmailSuggestions(
          res.filter((user) => !user.archived).map((user) => user.email)
        );
      });
  }, [selectedDepartment]);

  const onSubmit = (data) => {
    data.color = color;
    data.correspondences = emails;
    editStatus(data);
    // reset();
    hideModal();
  };

  const handleKeyDown = (event) => {
    if ((event.key === ' ' || event.key === ',') && event.target.value !== '') {
      event.preventDefault();
      event.stopPropagation();
      handleAddEmail(event, [...emails, event.target.value]);
    }
  };

  const isFormDirty = useMemo(
    () =>
      isDirty ||
      color !== status.color ||
      JSON.stringify(emails) !== JSON.stringify(status.correspondences),
    [color, emails, isDirty, status]
  );

  const handleReset = () => {
    reset();
    setColor(status.color);
    setEmails(status.correspondences);
    setEmailValid(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack my={isSmallScreen ? 1 : 2} spacing={isSmallScreen ? 2 : 3}>
        <TextField
          id="status-name"
          name="name"
          size={isSmallScreen ? 'small' : 'medium'}
          label={
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('tableHeads.status.name')}
            </Typography>
          }
          error={!!errors.name}
          disabled={status.statusId <= 5}
          helperText={errors.name?.message || ''}
          {...register('name', { required: t('formControl.enterName') })}
          inputProps={{
            style: {
              fontSize: isSmallScreen ? '0.675rem' : '1rem',
            },
          }}
          FormHelperTextProps={{
            sx: {
              fontSize: isSmallScreen ? '0.6rem' : '0.8rem',
            },
          }}
        />

        <TextField
          multiline
          id="status-description"
          minRows={3}
          name="description"
          disabled={status.statusId <= 5}
          size={isSmallScreen ? 'small' : 'medium'}
          label={
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('tableHeads.status.description')}
            </Typography>
          }
          error={!!errors.description}
          helperText={errors.description?.message || ''}
          {...register('description', { required: t('formControl.enterDescription') })}
          inputProps={{
            style: {
              fontSize: isSmallScreen ? '0.675rem' : '1rem',
            },
          }}
          FormHelperTextProps={{
            sx: {
              fontSize: isSmallScreen ? '0.6rem' : '0.8rem',
            },
          }}
        />

        {role === 'ROLE_ADMIN' && status.statusId > 5 && (
          <FormControl
            fullWidth
            error={!!errors.departmentId}
            data-testid="user-department-select-control"
            size={isSmallScreen ? 'small' : 'medium'}
          >
            <InputLabel id="department-select-label">
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('tableHeads.status.department')}
              </Typography>
            </InputLabel>
            <Select
              labelId="department-select-label"
              id="department-select"
              inputProps={{
                'data-testid': 'department-select',
              }}
              disabled
              label={
                <Typography
                  variant="body2"
                  sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                >
                  {t('tableHeads.status.department')}
                </Typography>
              }
              defaultValue={status.departmentId}
              {...register('departmentId', {
                required: t('formControl.selectDepartment'),
              })}
            >
              {departmentList?.length > 0 ? (
                departmentList.map((department) => {
                  const { id, name } = department;
                  return (
                    <MenuItem key={id} value={id}>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                      >
                        {name}
                      </Typography>
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value="">
                  <Typography
                    variant="body2"
                    sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                  >
                    {t('infoMessages.noOptions')}
                  </Typography>
                </MenuItem>
              )}
            </Select>

            <FormHelperText sx={{ fontSize: isSmallScreen ? '0.6rem' : '0.8rem' }}>
              {errors.departmentId?.message}
            </FormHelperText>
          </FormControl>
        )}

        <FormControl
          fullWidth
          error={!!errors.appUserRole}
          size={isSmallScreen ? 'small' : 'medium'}
        >
          <InputLabel id="demo-simple-select-label">
            <Typography
              variant="body2"
              sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
            >
              {t('tableHeads.status.systemState')}
            </Typography>
          </InputLabel>

          <Select
            name="systemMappedStatus"
            labelId="demo-simple-select-label"
            id="related-state-select"
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('tableHeads.status.systemState')}
              </Typography>
            }
            disabled
            defaultValue={status.statusId > 5 ? status.systemMappedStatus : 'GLOBAL'}
            {...register('systemMappedStatus')}
          >
            {status.statusId <= 5 && (
              <MenuItem value="GLOBAL">
                <Typography
                  variant="body2"
                  sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                >
                  {t('pages.statusPage.globalStatus')}
                </Typography>
              </MenuItem>
            )}
            <MenuItem value="ONHOLD">
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('status.ONHOLD')}
              </Typography>
            </MenuItem>
            <MenuItem value="UNASSIGNED">
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('status.UNASSIGNED')}
              </Typography>
            </MenuItem>
            <MenuItem value="OPEN">
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('status.OPEN')}
              </Typography>
            </MenuItem>
            <MenuItem value="RESOLVED">
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('status.RESOLVED')}
              </Typography>
            </MenuItem>
            <MenuItem value="CLOSED">
              <Typography
                variant="body2"
                sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
              >
                {t('status.CLOSED')}
              </Typography>
            </MenuItem>
          </Select>
          <FormHelperText sx={{ fontSize: isSmallScreen ? '0.6rem' : '0.8rem' }}>
            {errors.systemMappedStatus?.message}
          </FormHelperText>
        </FormControl>

        {status.statusId > 5 && (
          <Autocomplete
            multiple
            id="email-input"
            options={emailSuggestions} // You can provide options for suggestions here
            freeSolo // Allows entering values not in the options
            defaultValue={status.correspondences}
            size={isSmallScreen ? 'small' : 'medium'}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={
                  <Typography
                    variant="body2"
                    sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}
                  >
                    {t('tableHeads.status.correspondances')}
                  </Typography>
                }
                placeholder={t('pages.statusPage.typeEmail')}
                onKeyDown={(e) => handleKeyDown(e)}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                error={!emailValid}
                helperText={!emailValid ? t('pages.statusPage.invalidEmail') : ''}
                InputLabelProps={{
                  sx: {
                    fontSize: isSmallScreen ? '0.675rem' : '1rem',
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    fontSize: isSmallScreen ? '0.675rem' : '1rem',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    '&::placeholder': {
                      fontSize: isSmallScreen ? '0.675rem' : '1rem',
                    },
                  },
                }}
              />
            )}
            onChange={(e, newValue) => {
              handleAddEmail(e, newValue);
            }}
            clearOnBlur
            onBlur={() => setEmailValid(true)}
            value={emails}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  label={
                    <Typography
                      sx={{
                        fontSize: isSmallScreen ? '0.675rem' : '1rem',
                      }}
                    >
                      {option}
                    </Typography>
                  }
                  sx={{
                    height: isSmallScreen ? 20 : 32,
                  }}
                />
              ))
            }
            ListboxProps={{
              style: {
                fontSize: isSmallScreen && '0.675rem',
              },
            }}
          />
        )}
        <Stack direction="row" id="color-picker" alignItems="center" spacing={1}>
          <Typography variant="h6" sx={{ fontSize: isSmallScreen ? '0.675rem' : '1rem' }}>
            {t('tableHeads.status.color')} :
          </Typography>
          <input
            style={{
              cursor: 'pointer',
              width: isSmallScreen ? '40px' : {},
              height: isSmallScreen ? '22px' : {},
            }}
            type="color"
            value={color}
            onChange={handleHtmlColorChange}
            disabled={status.statusId <= 5}
          />
        </Stack>
      </Stack>
      {status.statusId > 5 && (
        <Stack direction="row" spacing={isSmallScreen ? 1 : 2} mt={2}>
          <LoadingButton
            sx={{ backgroundColor: '#d21426', color: '#fff' }}
            fullWidth
            size={isSmallScreen ? 'small' : 'medium'}
            variant="contained"
            color="error"
            disabled={!isFormDirty}
            onClick={handleReset}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontSize: isSmallScreen ? '0.6rem' : '0.9rem' }}
            >
              {t('buttons.cancelChanges')}
            </Typography>
          </LoadingButton>

          <LoadingButton
            sx={{
              backgroundColor: 'success.dark',
              color: '#fff',
              ':hover': { backgroundColor: 'success.darker' },
            }}
            id="new-stat-btn"
            fullWidth
            size={isSmallScreen ? 'small' : 'medium'}
            type="submit"
            variant="contained"
            disabled={!isValid || !isFormDirty || !emailValid}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontSize: isSmallScreen ? '0.6rem' : '0.9rem' }}
            >
              {t('pages.statusPage.editStatus')}
            </Typography>
          </LoadingButton>
        </Stack>
      )}
    </form>
  );
}
