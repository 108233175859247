import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

// @mui
import { styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

// components
import { useChart } from '../chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;

const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentSubject.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppCurrentSubject({
  title,
  subheader,
  chartData,
  chartColors,
  chartLabels,
  ...other
}) {
  const { isSmallScreen } = useResponsiveScreen();
  const chartOptions = useChart({
    stroke: { width: 1.5 },
    fill: { opacity: 0.25 },
    legend: { floating: true, horizontalAlign: 'center' },

    markers: {
      size: 3.5,
      hover: {
        size: 7,
      },
    },
    xaxis: {
      categories: chartLabels,
      labels: {
        style: {
          colors: chartColors,
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        sx={{
          '& .MuiCardHeader-title': { fontSize: isSmallScreen && '0.8rem' },
          '& .MuiCardHeader-subheader': { fontSize: isSmallScreen && '0.7rem' },
        }}
      />

      <StyledChartWrapper dir="ltr">
        <ReactApexChart
          type="radar"
          series={chartData}
          options={chartOptions}
          height={340}
        />
      </StyledChartWrapper>
    </Card>
  );
}
