import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @mui
import {
  Box,
  Link,
  Stack,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  ClickAwayListener,
} from '@mui/material';

import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

// components
import Iconify from '../iconify';
import { useConfirmModal } from '../../hooks/useModal';
import { toolbarStyle, StyledSearch, boxSelectedStyle } from './style';

TableToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  setFilterName: PropTypes.func,
  onFilterName: PropTypes.func,
  filterBy: PropTypes.string,
  model: PropTypes.string,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
  deleteFunc: PropTypes.func,
  selected: PropTypes.array,
  handleRemoveSelected: PropTypes.func,
  generateCSVData: PropTypes.func,
  data: PropTypes.array,
};

export default function TableToolbar({
  numSelected,
  filterName,
  setFilterName,
  filterBy,
  onFilterName,
  model,
  handleOpenFilter,
  handleCloseFilter,
  deleteFunc,
  selected,
  handleRemoveSelected,
  generateCSVData,
  data,
}) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const theme = useTheme();
  const [dataInCSV, setDataInCSV] = useState('');
  const [isDownloadDebouncing, setIsDownloadDebouncing] = useState(false);

  const { showConfirmModal } = useConfirmModal(
    t('pages.deletePage.confirmDelete'),
    `${t('pages.deletePage.areYouSure')}`,
    async () => handleDelete()
  );

  const handleDelete = () => {
    deleteFunc(selected);
  };

  const isDisabled = useMemo(
    () =>
      filterBy === 'byStatus' ||
      filterBy === 'byRole' ||
      filterBy === 'byPriority' ||
      filterBy === 'byLanguage' ||
      filterBy === 'byDepartment' ||
      filterBy === 'byIsAnonymous' ||
      filterBy === 'byMultipleSubmission',
    [filterBy]
  );

  const handleClearSearch = () => {
    setFilterName('');
    onFilterName({ target: { value: '' } });
  };

  const handleDownloadCSV = () => {
    setIsDownloadDebouncing(true);
    const dataRes = data.filter((ticket) => selected.includes(ticket.id));
    if (model === 'Ticket') {
      const res = generateCSVData(dataRes);
      setDataInCSV(res);
    } else {
      const csvData = Papa.unparse(dataRes);
      setDataInCSV(csvData);
    }

    setTimeout(() => {
      setIsDownloadDebouncing(false);
    }, 1500);
  };

  return (
    <Toolbar sx={toolbarStyle(theme, numSelected)}>
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
        {numSelected > 0 ? (
          <Box sx={isSmallScreen ? boxSelectedStyle : {}}>
            <Typography
              component="div"
              variant="subtitle1"
              sx={{
                fontSize: isSmallScreen ? '0.8rem' : '1rem',
              }}
            >
              {numSelected} {t('filters.selected')}
            </Typography>
            <Link
              sx={{
                cursor: 'pointer',
                fontSize: isSmallScreen && '0.6rem',
              }}
              variant="caption"
              onClick={handleRemoveSelected}
            >
              {t('buttons.removeSelected')}
            </Link>
          </Box>
        ) : (
          <StyledSearch
            name="searchBar"
            id="search-bar"
            value={filterName}
            onChange={onFilterName}
            disabled={isDisabled}
            placeholder={`${t('filters.search')} ${model}...`}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: 'text.disabled',
                    width: isSmallScreen ? 16 : 20,
                    height: isSmallScreen ? 16 : 20,
                  }}
                />
              </InputAdornment>
            }
            endAdornment={
              filterName && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <Iconify
                      icon="eva:close-fill"
                      sx={{
                        color: 'text.disabled',
                        width: isSmallScreen ? 16 : 20,
                        height: isSmallScreen ? 16 : 20,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }
            sx={{
              width: filterName ? '320px' : '240px',
              fontSize: isSmallScreen ? '0.75rem' : '1rem',
              '&.Mui-focused': {
                width: isSmallScreen ? 290 : 320,
              },
            }}
            size={isSmallScreen ? 'small' : 'medium'}
          />
        )}
      </Stack>

      {numSelected > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {data && (
            <Tooltip title={t('toolbar.downloadCSV')}>
              <IconButton
                onClick={handleDownloadCSV}
                href={`data:text/csv;charset=utf-8,${dataInCSV}`}
                download={
                  model === 'Ticket' ? t('toolbar.ticketsCSV') : t('toolbar.usersCSV')
                }
                disabled={isDownloadDebouncing}
              >
                <Iconify
                  icon={
                    isDownloadDebouncing
                      ? 'line-md:downloading-loop'
                      : 'material-symbols:download'
                  }
                  sx={{
                    width: isSmallScreen ? 16 : 20,
                    height: isSmallScreen ? 16 : 20,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('toolbar.delete')}>
            <IconButton onClick={showConfirmModal} sx={{ p: isSmallScreen ? 0.5 : 1 }}>
              <Iconify
                icon="eva:trash-2-fill"
                sx={{
                  width: isSmallScreen ? 16 : 20,
                  height: isSmallScreen ? 16 : 20,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1.5}
          mt={isSmallScreen ? 0.5 : 1.5}
        >
          {filterBy && (
            <>
              <Typography
                variant={isSmallScreen ? 'body2' : 'body1'}
                sx={{
                  fontSize: isSmallScreen ? '0.7rem' : '1rem',
                }}
              >
                {t('filters.search')} {t(`filters.${filterBy}`)}
              </Typography>

              <ClickAwayListener
                onClickAway={() => {
                  if (handleCloseFilter) handleCloseFilter();
                }}
              >
                <div>
                  <Tooltip title="Filter list">
                    <IconButton
                      onClick={(e) => {
                        if (handleOpenFilter) handleOpenFilter(e);
                      }}
                      sx={{ p: isSmallScreen ? 0 : 1, marginLeft: isSmallScreen ? 0 : 1 }}
                    >
                      <Iconify
                        icon="ic:round-filter-list"
                        sx={{
                          width: isSmallScreen ? 18 : 20,
                          height: isSmallScreen ? 18 : 20,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </>
          )}
        </Stack>
      )}
    </Toolbar>
  );
}
