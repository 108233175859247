import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { Box, Tab, Grid, Tabs, Stack, Backdrop, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import { useModal } from 'src/hooks/useModal';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { getComparator, applySortFilter } from 'src/utils/filterData';

import authService from 'src/services/auth.service';
import { genericApiToaster } from 'src/services/utils';
import { fetchDepartments } from 'src/services/department.service';
import { titleHeadingStyle, titleHeadingStyleSmole } from 'src/pages/style';
import {
  fetchUsers,
  createUser,
  updateUser,
  uploadUsers,
  getUsersByDepartment,
} from 'src/services/user.service';

import Loader from 'src/components/loaders/Loader';
import CSVInput from 'src/components/inputs/CSVInput';
import ActionButton from 'src/components/action-button/action-button';

import AddUserForm from 'src/sections/users/modals/AddUserForm';
import ModifyUserModal from 'src/sections/users/modals/ModifyUserModal';

import UsersTable from './table/table';

UsersView.propTypes = {
  title: PropTypes.string,
};

export default function UsersView({ title }) {
  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { role: currentUserRole, departmentId } = useAuth();
  const { showModal, hideModal } = useModal();
  const [usersData, setUsersData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModifyUserModal, setModifyUserModal] = useState(false);
  const [page, setPage] = useState(0);
  const [departmentsData, setDepartmentData] = useState([]);
  const [filterBy, setFilterBy] = useState('fullName');
  const [order, setOrder] = useState('asc');
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [currentPageUsers, setCurrentPageUsers] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataInCSV, setDataInCSV] = useState('');
  const [noArchivedUsers, setNoArhivedUsers] = useState(false);
  const [noActiveUsers, setNoActiveUsers] = useState(false);
  const [searchIsNotFound, setSearchIsNotFound] = useState(false);
  const [isDownloadDebouncing, setIsDownloadDebouncing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // 0 for active users, 1 for archived users

  const { showToast, hideToast } = useToast();
  const role = useMemo(() => currentUserRole.authority, [currentUserRole]);
  // Fetch Clients
  const fetchData = () => {
    if (title === t('users')) {
      setIsLoading(true);
      fetchUsers()
        .then((res) => {
          const usersWithFullname = res.map((user) => ({
            ...user,
            fullName: `${user.givenName} ${user.familyName}`,
            status: user.status ? 'true' : 'false',
          }));
          setUsersData(usersWithFullname);
        })
        .catch(() => {
          showToast({
            message: t('errorMessages.couldntLoadUser'),
            severity: 'error',
            props: { hideToast },
          });
        })
        .finally(() => setIsLoading(false));
    } else if (title === t('agents')) {
      setIsLoading(true);
      getUsersByDepartment(departmentId)
        .then((res) => {
          const agentsWithFullname = res
            .filter((user) => user.appUserRole === 'ROLE_AGENT')
            .map((user) => ({
              ...user,
              fullName: `${user.givenName} ${user.familyName}`,
              status: user.status ? 'true' : 'false',
            }));
          setUsersData(agentsWithFullname);
        })
        .catch(() => {
          showToast({
            message: t('errorMessages.couldntLoadDep'),
            severity: 'error',
            props: { hideToast },
          });
        })
        .finally(() => setIsLoading(false));
    }
  };
  // fetch Departments
  const fetchDepartmentsData = () => {
    fetchDepartments()
      .then((res) => {
        setDepartmentData(res);
      })
      .catch(() => {
        showToast({
          message: t('errorMessages.couldntLoadDep'),
          severity: 'error',
          props: { hideToast },
        });
      });
  };

  const handleDownloadClick = () => {
    if (!isDownloadDebouncing) {
      setIsDownloadDebouncing(true);
      setTimeout(() => {
        setIsDownloadDebouncing(false);
      }, 1500);
    }
  };

  useEffect(() => {
    fetchDepartmentsData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredUsers = useMemo(() => {
    const filteredData = applySortFilter(
      usersData,
      getComparator(order, orderBy),
      filterName,
      filterBy
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setNoArhivedUsers(!filteredData.filter((user) => user.archived).length);
    setNoActiveUsers(!filteredData.filter((user) => !user.archived).length);
    setSearchIsNotFound(!filteredData.length && !!filterName);

    return filteredData;
  }, [filterName, order, orderBy, filterBy, usersData]);

  useEffect(() => {
    setCurrentPageUsers(
      filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [filteredUsers, page, rowsPerPage]);

  useEffect(() => {
    const csvData = Papa.unparse(filteredUsers);
    setDataInCSV(csvData);
  }, [filteredUsers]);

  // Add a new User
  const addNewUser = async (newUser) => {
    if (role === 'ROLE_ADMIN') {
      handleBackdropOpen();
      genericApiToaster(
        createUser,
        {
          givenName: newUser.givenName,
          familyName: newUser.familyName,
          email: newUser.email,
          phoneNumber: newUser.phoneNumber,
          appUserRole: newUser.appUserRole,
          departmentId: newUser.departmentId,
        },
        t('successMessages.successUserAdded'),
        t('errorMessages.couldntCreateUser'),
        showToast,
        hideToast,
        () => {
          fetchData();
          handleBackdropClose();
        }
      );
    }
  };

  // Modify user
  const modifyUser = async (newUserData) => {
    genericApiToaster(
      updateUser,
      {
        id: newUserData.id,
        givenName: newUserData.givenName,
        familyName: newUserData.familyName,
        email: newUserData.email,
        departmentId:
          newUserData.appUserRole === 'ROLE_CUSTOMER' ||
          newUserData.appUserRole === 'ROLE_ADMIN'
            ? 0
            : newUserData.departmentId,

        appUserRole: newUserData.appUserRole,
        phoneNumber: newUserData.phoneNumber,
      },
      t('successMessages.successUserUpdate'),
      t('errorMessages.couldntUpdateUser'),
      showToast,
      hideToast,
      () => {
        const newUsersList = usersData.map((user) =>
          user.id === newUserData.id
            ? {
                ...user,
                givenName: newUserData.givenName,
                familyName: newUserData.familyName,
                fullName: `${newUserData.givenName} ${newUserData.familyName}`,
                phoneNumber: newUserData.phoneNumber,
                departmentId:
                  newUserData.appUserRole === 'ROLE_CUSTOMER' ||
                  newUserData.appUserRole === 'ROLE_ADMIN'
                    ? 0
                    : newUserData.departmentId,
                departmentName:
                  newUserData.appUserRole === 'ROLE_CUSTOMER' ||
                  newUserData.appUserRole === 'ROLE_ADMIN'
                    ? ''
                    : newUserData.departmentName,
                email: newUserData.email,
                appUserRole: newUserData.appUserRole,
              }
            : user
        );
        setUsersData(newUsersList);
      }
    );
  };

  const csvUpload = (formData) => {
    handleBackdropOpen();
    uploadUsers(formData)
      .then(() => {
        handleBackdropClose();
        showToast({
          message: t('successMessages.successUsersUpload'),
          severity: 'success',
          props: { hideToast },
        });
        fetchData();
      })
      .catch(() => {
        handleBackdropClose();
        showToast({
          message: t('errorMessages.errorFileUpload'),
          severity: 'error',
          props: { hideToast },
        });
      });
  };
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };
  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    setPage(0);
  };

  return (
    <>
      <Helmet>
        <title> {t(`nav.${title}`)} </title>
      </Helmet>
      {selectedRow && (
        <ModifyUserModal
          open={openModifyUserModal}
          setOpen={setModifyUserModal}
          row={selectedRow}
          modifyUser={modifyUser}
          departmentsData={departmentsData}
          currentRole={authService.verifyUserRole()}
        />
      )}

      {/* Tabs for Active and Archived Users */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab
            label={`${t('userStatus.active')} ${t(`nav.${title}`)}`}
            sx={{
              fontSize: isSmallScreen && '0.65rem',
            }}
          />
          <Tab
            label={`${t('userStatus.archived')} ${t(`nav.${title}`)}`}
            sx={{
              fontSize: isSmallScreen && '0.65rem',
            }}
          />
        </Tabs>
      </Box>
      <Box px={3} mt={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={isSmallScreen ? 2 : 5}
        >
          <Typography
            style={isSmallScreen ? titleHeadingStyleSmole : titleHeadingStyle}
            mx={1}
          >
            {activeTab === 0
              ? `${t('userStatus.active')} ${t(`nav.${title}`)}`
              : `${t('userStatus.archived')} ${t(`nav.${title}`)}`}
          </Typography>
          <Grid>
            {role === 'ROLE_ADMIN' && (
              <ActionButton
                id="add-user-btn"
                onClick={() =>
                  showModal({
                    title: t('pages.usersPage.form.newUser'),
                    Component: AddUserForm,
                    props: {
                      addUser: addNewUser,
                      hideModal,
                      departmentsData,
                      currentRole: authService.verifyUserRole(),
                    },
                  })
                }
                text={t('buttons.newUser')}
                title={t('buttons.newUser')}
                icon={isSmallScreen ? 'octicon:plus-circle-16' : 'eva:plus-fill'}
                sx={{ mr: 1 }}
              />
            )}
            <ActionButton
              id="download-users-btn"
              href={`data:text/csv;charset=utf-8,${dataInCSV}`}
              download="users.csv"
              disabled={isDownloadDebouncing}
              onClick={handleDownloadClick}
              text={t('buttons.download')}
              title={t('buttons.download')}
              icon={isSmallScreen ? 'material-symbols:download' : 'eva:download-fill'}
              sx={{ mx: 1 }}
            />
            {role === 'ROLE_ADMIN' && (
              <ActionButton
                id="upload-users-btn"
                text={t('buttons.uploadUsers')}
                title={t('buttons.uploadUsers')}
                icon={isSmallScreen ? 'material-symbols:upload' : 'eva:upload-fill'}
                onClick={() =>
                  showModal({
                    title: t('inputs.CSV.uploadCSV'),
                    Component: CSVInput,
                    props: {
                      hideModal,
                      csvUpload,
                      maxWidth: true,
                    },
                  })
                }
                sx={{ ml: 1 }}
              />
            )}
          </Grid>
        </Stack>
        <Backdrop
          sx={{
            bgcolor: 'rgb(121 121 121 / 32%)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={backdropOpen}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Loader size={175} hideText />
          </Box>
        </Backdrop>
        <UsersTable
          currentPageData={currentPageUsers}
          filterName={filterName}
          fullFilteredData={filteredUsers}
          role={role}
          setRowsPerPage={setRowsPerPage}
          selected={selected}
          setSelected={setSelected}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          rowsPerPage={rowsPerPage}
          noActiveUsers={noActiveUsers}
          noArchivedUsers={noArchivedUsers}
          // isNotFound={isNotFound}
          searchIsNotFound={searchIsNotFound}
          page={page}
          setPage={setPage}
          fetchData={fetchData}
          title={title}
          departmentsData={departmentsData}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          setFilterName={setFilterName}
          setModifyUserModal={setModifyUserModal}
          setFilterBy={setFilterBy}
          isLoading={isLoading}
          activeTab={activeTab}
          usersData={usersData}
        />
      </Box>
    </>
  );
}
