import styled from '@emotion/styled';

import { alpha, OutlinedInput } from '@mui/material';

export const toolbarStyle = (theme, numSelected) => ({
  height: '96px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: numSelected > 0 ? theme.palette.primary.lighter : 'transparent',
  color: numSelected > 0 ? theme.palette.primary.main : 'inherit',
  '@media (max-width: 600px)': {
    height: '120px',
    flexDirection: 'column',
  },
});

export const boxSelectedStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  minHeight: 40,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
